import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import url from "../../utils/routeUrls";
import { goFullScreen, userProfileFind } from "../../actions";
import requireAuth from "../auth/requireAuth";
import Fullscreen from "react-full-screen";
import RobotNameForm from "./RobotNameForm";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class RobotName extends Component {
  state = { toggleGoStudy: false };

  componentDidMount() {
    this.props.userProfileFind({ user_id: this.props.userId });
  }

  componentWillUnmount() {}

  robotSelected(name) {
    this.props.history.push(url.ROBOT_SELECT);
  }

  displayGoStudy() {
    if (
      this.props.preferences &&
      this.props.preferences.robot_name &&
      this.props.preferences.robot.length > 0
    ) {
      return (
        <div>
          <div
            className="btn btn-full"
            onClick={() => this.props.history.push(url.EXERCISE_LANDING)}
          >
            <a>Play and Learn!</a>
          </div>
        </div>
      );
    }
  }

  render() {
    const first =
      this.props.userProfile.first_name || this.props.userProfile.email;
    const robot = this.props.preferences.robot
      ? this.props.preferences.robot
      : "Robot0";
    return (
      <Fullscreen enabled={this.props.fullScreen}>
        <div className="section-robotstory">
          <div className="robotstory-frame robotstory-einstein-smile">
            <Row className="question-header">
              <Col className="question-preferences" />
              <Col xs={6} className="question-category">
                Name your Robot:
              </Col>
              <Col className="question-exit">
                <ul className="question-right-nav">
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col className="robotDisplay">
                <div onClick={() => this.robotSelected({ robot })}>
                  <img src={require("../../assets/img/" + robot + ".png")} />
                </div>
              </Col>
              <Col className="robotSelectionSpacer " />
              <Col className="robotDisplay ">
                <div className="robotNameForm">
                  <RobotNameForm />
                </div>
                <div className="learnAndPlay">
                  <div>{this.displayGoStudy()}</div>
                </div>
              </Col>
            </Row>
            <Row className="storyNavigation">
              <Col
                className="storyPrev"
                onClick={() => this.props.history.push(url.ROBOT_SELECT)}
              >
                {this.props.preferences.robot && (
                  <i className="ion-android-arrow-dropleft storyNavIcon" />
                )}
              </Col>
              <Col xs={8}>
                <div className="storyBubble" style={{ marginTop: "0px" }}>
                  <p>
                    <span>"Now it is time to give your robot a name"</span>
                  </p>
                </div>
              </Col>
              <Col
                className="storyNext"
                onClick={() => this.props.history.push(url.EXERCISE_LANDING)}
              >
                {this.props.preferences.robot_name && (
                  <i className="ion-android-arrow-dropright storyNavIcon" />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];
  console.log("whoe enchilada", state);
  return {
    userProfile: userData || {}, // no user logged in, userdata is empty object
    fullScreen: state.userProfile.fullScreen,
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    authenticated: state.auth.authenticated
  };
}

export default connect(
  mapStateToProps,
  {
    goFullScreen,
    userProfileFind
  }
)(requireAuth(RobotName));
