import React, { Component } from 'react';

import NavigationHeader from "./common/NavigationHeader";

// cd C:\Users\xmobl\Documents\Smilefish\Smilestein\SmileStein-Client
// cd C:\Users\xmobl\Documents\Smilefish\Smilestein\SmileStein-Server

class App extends Component {

  render() {
    return (
      <div>
        <NavigationHeader />
        {this.props.children}
      </div>
    );
  }
}

export default App;
