/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { Component } from "react";
import Fade from "react-reveal/Fade";

export default class Cities extends Component {
  render() {
    return (
      <div>
        <div>
          <section className="section-cities" id="cities">
            <div className="row">
              <h2>We're currently in these cities</h2>
            </div>
            <Fade>
              <div className="row">
                <div className="col span-1-of-4 box">
                  <img
                    src={require("../../assets/img/lisbon-3.jpg")}
                    alt="Lisbon"
                  />
                  <h3>Lisbon</h3>
                  <div className="city-feature">
                    <i className="ion-ios-person icon-small" />
                    1600+ happy students
                  </div>
                  <div className="city-feature">
                    <i className="ion-ios-star icon-small" />
                    60+ top tutors
                  </div>
                  <div className="city-feature">
                    <i className="ion-social-twitter icon-small" />
                    <a href="#">@smilestein_lx</a>
                  </div>
                </div>
                <div className="col span-1-of-4 box">
                  <img
                    src={require("../../assets/img/san-francisco.jpg")}
                    alt="San Francisco"
                  />
                  <h3>San Francisco</h3>
                  <div className="city-feature">
                    <i className="ion-ios-person icon-small" />
                    3700+ happy students
                  </div>
                  <div className="city-feature">
                    <i className="ion-ios-star icon-small" />
                    160+ top tutors
                  </div>
                  <div className="city-feature">
                    <i className="ion-social-twitter icon-small" />
                    <a href="#">@smilestein_sf</a>
                  </div>
                </div>
                <div className="col span-1-of-4 box">
                  <img
                    src={require("../../assets/img/berlin.jpg")}
                    alt="Berlin"
                  />
                  <h3>Berlin</h3>
                  <div className="city-feature">
                    <i className="ion-ios-person icon-small" />
                    2300+ happy students
                  </div>
                  <div className="city-feature">
                    <i className="ion-ios-star icon-small" />
                    110+ top tutors
                  </div>
                  <div className="city-feature">
                    <i className="ion-social-twitter icon-small" />
                    <a href="#">@smilestein_berlin</a>
                  </div>
                </div>
                <div className="col span-1-of-4 box">
                  <img
                    src={require("../../assets/img/london.jpg")}
                    alt="London"
                  />
                  <h3>London</h3>
                  <div className="city-feature">
                    <i className="ion-ios-person icon-small" />
                    1200+ happy students
                  </div>
                  <div className="city-feature">
                    <i className="ion-ios-star icon-small" />
                    50+ top tutors
                  </div>
                  <div className="city-feature">
                    <i className="ion-social-twitter icon-small" />
                    <a href="#">@smilestein_london</a>
                  </div>
                </div>
              </div>
            </Fade>
          </section>
        </div>
      </div>
    );
  }
}
