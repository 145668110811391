import axios from "axios";
import apiUrls from "../config/apiUrls";

import { SUB_CREATE, SUB_ERROR } from "./types";

export const subCreate = (
  subProps,
  callback,
  callbackErr
) => async dispatch => {
  // creates subscription for user given user_id, product_id, subscription_type, term, amount, currency, and payment onject
  // payment object contains payment_type, cvv, card_number, and expiration
  // callbackErr takes the exception message and should update state with it.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.SUB_URL}/create`,
      subProps,
      config
    );
    dispatch({ type: SUB_CREATE, payload: response.data });
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: SUB_ERROR, payload: {} });
    console.log("Error:", e);
    if (callbackErr) {
      // callbackErr(e.message);
      callbackErr("Something went wrong. Invalid payment credentials.");
    }
  }
};
