import React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

import url from "../../utils/routeUrls";

export default () => {
  return (
    <Menu vertical >
      <Menu.Item>
        <Menu.Header>Settings</Menu.Header>
      </Menu.Item>
      <Menu.Item>
        <Menu.Menu>
          <Menu.Item><Link to={url.USER_PROFILE}>My Profile</Link></Menu.Item>
          <Menu.Item><Link to={url.USER_PREFERENCES}>Preferences</Link></Menu.Item>
          <Menu.Item><Link to={url.USER_CHANGE_PASSWORD}>Change Password</Link></Menu.Item>
        </Menu.Menu>
      </Menu.Item>
    </Menu>
  );
}