import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Progress } from "semantic-ui-react";

import requireAuth from "../auth/requireAuth";

class ProgressBar extends Component {
  progressPercent(exercise) {
    if (
      exercise.count_questions ||
      exercise.count_correct ||
      exercise.count_wrong
    ) {
      return Math.floor(
        ((Number(exercise.count_correct) + Number(exercise.count_wrong)) /
          Number(exercise.count_questions)) *
          100
      );
    }
    return 0;
  }

  render() {
    if (_.isEmpty(this.props.exerciseData)) {
      return <div>Loading...</div>;
    }
    return (
      <div className="row question-progress">
        <Progress
          size="small"
          percent={this.progressPercent(this.props.exerciseData)}
          color="olive"
          progress
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const active = state.exerciseActive;
  console.log(state);
  if (_.isEmpty(active.activeExercise))
    return { questionsToDo: [], exerciseData: {} };

  return {
    exerciseData: active.activeExercise,
    activeIndex: active.activeIndex
  };
}

export default connect(mapStateToProps)(requireAuth(ProgressBar));
