import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import requireAuth from "../auth/requireAuth";
import url from "../../utils/routeUrls";
import { exerciseActiveReset } from "../../actions";
import ExerciseSummary from "./ExerciseSummary";
import QuestionFrame from "../question/QuestionFrame"

class ExerciseQuestions extends Component {

  componentWillMount() {
    // if the user attempts to visit this url directly, redirects them to exercise list page
    if (!this.props.questionsToDo || this.props.questionsToDo.length === 0) {
      this.props.history.push(`${url.EXERCISE_LANDING}`);
    }
  }

  componentWillUnmount() {
    // resets active exercise upon unmounting, so attempting to load another exercise will not result in incorrect state
    this.props.exerciseActiveReset();
  }

  render() {
    // console.log(this.props.questionsToDo);
    console.log("current question", this.props.currentQuestion);
    console.log("exercise", this.props.exerciseData);
    
    if (_.isEmpty(this.props.exerciseData)) {
      return (
        <div>Loading...</div>
      );
    }
    return (
    <div>
      {this.props.questionsToDo.length-1 >= this.props.activeIndex && <QuestionFrame />}
      {this.props.questionsToDo.length-1 < this.props.activeIndex && <ExerciseSummary />}
    </div>
    );
  }
}


function mapStateToProps(state) {
  const active = state.exerciseActive;
  if (_.isEmpty(active.activeExercise))
    return { questionsToDo: [], exerciseData: {} };

  return { 
    questionsToDo: active.activeExercise.questions, 
    exerciseData: active.activeExercise,
    activeIndex: active.activeIndex, 
    currentQuestion: active.activeExercise.questions[active.activeIndex] || null
  };
}

export default connect(mapStateToProps, { exerciseActiveReset })(requireAuth(ExerciseQuestions));