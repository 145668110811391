//import _ from "lodash";
import {
  ANALYTICS_ANSWERS_PER_USER_PER_DAY,
  ANALYTICS_ERROR
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case ANALYTICS_ANSWERS_PER_USER_PER_DAY:
      //console.log("reducer",action.payload);
      return {
        ...state,
        answersPerUserPerDay: action.payload
      };

    case ANALYTICS_ERROR:
      return state; // return the last correct state
    default:
      return state;
  }
};
