import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  Header,
  Icon,
  Button,
  Segment,
  Form as SemForm
} from "semantic-ui-react";

import { userProfileUpdate } from "../../../actions";

import SemField from "../../helpers/SemField";
import { CustomMessage } from "../../common/messageComponents";

class SignupPrefs extends Component {
  state = { disableButton: false, errorMsg: "" };

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Preferences
      </Header>
    );
  }

  onSubmit = (formProps, actions) => {
    this.setState({ disableButton: true });
    formProps.user_id = this.props.userId;

    if (formProps.email_daily_progress) {
      formProps.email_daily_progress = 1;
    } else {
      formProps.email_daily_progress = 0;
    }
    // console.log(formProps);

    this.props.userProfileUpdate(formProps, () => {
      this.props.updateProgress(); // passed in as a prop
    });
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      parent_code: Yup.string().required("Required")
    });

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <div
        style={{
          minWidth: "450px",
          maxWidth: "40%",
          margin: "auto",
          padding: "70px 0"
        }}
      >
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <div style={{ marginBottom: "10px" }}>
              <SemField
                name="email_daily_progress"
                component={SemForm.Checkbox}
                label="Enable emails tracking daily progress"
              />
            </div>
            <label>Parent passcode</label>
            <SemField
              name="parent_code"
              type="password"
              component={SemForm.Input}
              icon="lock"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="parent_code" component={this.renderError} />

            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Next
            </Button>
            {this.state.errorMsg && (
              <CustomMessage
                style={{ marginTop: "10px" }}
                message={this.state.errorMsg}
                color="red"
              />
            )}
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          initialValues={{
            parent_code: "",
            email_daily_progress: true
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { userId: state.auth.userId };
}

export default connect(
  mapStateToProps,
  { userProfileUpdate }
)(SignupPrefs);
