export const validateCvv = cvv => {
  const re_cvv = /^[0-9]{3,4}$/;
  return re_cvv.test(cvv);
};

function normalizeYear(year) {
  // Century fix
  var YEARS_AHEAD = 20;
  if (year < 100) {
    var nowYear = new Date().getFullYear();
    year += Math.floor(nowYear / 100) * 100;
    if (year > nowYear + YEARS_AHEAD) {
      year -= 100;
    } else if (year <= nowYear - 100 + YEARS_AHEAD) {
      year += 100;
    }
  }
  return year;
}

// https://jsfiddle.net/ArtyomShegeda/rFLX3/
export const validateExp = date => {
  let match = date.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
  if (!match) {
    return false;
  }
  let exp = new Date(
    normalizeYear(1 * match[2]),
    1 * match[1] - 1,
    1
  ).valueOf();
  let now = new Date();
  let currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
  return exp > currMonth;
};

export const validateCC = (card_type, cc_number) => {
  switch (card_type) {
    case 2: // visa
      const re_visa = /^4[0-9]{12}(?:[0-9]{3})?$/;
      return re_visa.test(cc_number);
    case 3: // mastercard
      const re_mas = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
      return re_mas.test(cc_number);
    case 4: // amex
      const re_amex = /^3[47][0-9]{13}$/;
      return re_amex.test(cc_number);
    default:
      return false;
  }
};
