import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

class DndOption extends Component {
  render() {
    // required props for draggable = draggableId and index
    // snapshot parameter in child contains an obj with a number of properties to style a component during drag. egghead.io lecture 6

    return (
      <Draggable 
        draggableId={this.props.option.id} 
        index={this.props.index} 
        // isDragDisabled={boolean}
      >
        {(provided, snapshot) => (
          <div  
            {...provided.draggableProps}
            // {...provided.dragHandleProps} // this spread determines what element in the draggable can be dragged.
            ref={provided.innerRef}
            style={{ 
              ...provided.draggableProps.style,  
              padding: 3, margin: "3px 3px 3px 3px" 
            }}
          >
            <div className="question-button" {...provided.dragHandleProps}  >
                {this.props.option.content}
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default DndOption;