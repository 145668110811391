// Skill Types
export const SKILL_FIND = "skill_find";
export const SKILL_CREATE = "skill_create";
export const SKILL_UPDATE = "skill_update";
export const SKILL_DELETE = "skill_delete";
export const SKILL_ERROR = "skill_error";
export const SKILL_STATE_CLEAR = "skill_state_clear";

// Question Types
export const QUESTION_FIND = "question_find";
export const QUESTION_CREATE = "question_create";
// export const QUESTION_UPDATE = "question_update";
export const QUESTION_DELETE = "question_delete";
export const QUESTION_ERROR = "question_error";

// Exercise Types
export const EXERCISE_FIND = "exercise_find"; // only finds exercises given params
export const EXERCISE_FIND_SKILLS = "exercise_find_skills"; // finds exercises AND skills. if skill was started, it is an exercise and has a user and ex id
export const EXERCISE_RESET = "exercise_reset";
export const EXERCISE_DELETE = "exercise_delete";
export const EXERCISE_ERROR = "exercise_error";

// Lead Types
export const LEAD_FIND = "lead_find";
export const LEAD_ERROR = "lead_error";
export const LEAD_RESET = "lead_reset";

// Exercise Active Question Types
export const EXERCISE_ACTIVE_FIND = "exercise_active_find";
export const EXERCISE_ACTIVE_INDEX = "exercise_active_index";
export const EXERCISE_ACTIVE_RESET = "exercise_active_reset";
export const EXERCISE_ACTIVE_ERROR = "exercise_active_error";
export const EXERCISE_ACTIVE_WORLDY = "exercise_active_world";

// Answer Types
export const ANSWER_CREATE = "answer_create";
export const ANSWER_ERROR = "answer_error";

// Auth Types
export const AUTH_USER = "auth_user";
export const AUTH_UPDATE = "auth_update";
export const AUTH_ERROR = "auth_error";
export const AUTH_FORGOT_PASS = "auth_forgot_password";
export const AUTH_RESET_PASS = "auth_reset_password";
export const AUTH_CHANGE_PASS = "auth_change_password";
export const AUTH_CLEAR_MSG = "auth_clear_message";

// Subscription Types
export const SUB_CREATE = "sub_create";
export const SUB_FIND = "sub_find";
export const SUB_UPDATE = "sub_update";
export const SUB_ERROR = "sub_error";

// User Profile Types
export const USERPROFILE_FIND = "userprofile_find";
export const USERPROFILE_UPDATE = "userprofile_update";
export const USERPROFILE_ERROR = "userprofile_error";
export const USERPROFILE_CLEAR = "userprofile_clear"; // clears all data in userprofile global state, used for logouts
export const USERPROFILE_FULLSCREEN = "userprofile_fullscreen";
export const USERPROFILE_POINTS_FIND = "userprofile_points_find";
export const USERPROFILE_POINTS_UPDATE = "userprofile_points_update";

// Analytics Types
export const ANALYTICS_ANSWERS_PER_USER_PER_DAY =
  "analytics_ansers_per_user_per_day";
export const ANALYTICS_ERROR = "analytics_error";

// Scroll Types
export const PAGE_SCROLLED = "page_scrolled";

// Gamification Types
export const GAMIFICATION_SCORE_LOAD = "gamification_score_load";
export const GAMIFICATION_SCORE_SET = "gamification_score_set";
export const GAMIFICATION_SET_STORY_INDEX = "gamification_set_story_index";
export const GAMIFICATION_ERROR = "gamification_error";

// Form
export const FORM = "form"; // global form state persistence
