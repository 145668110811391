import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import requireAuth from "../auth/requireAuth";

class Score extends Component {
  componentWillMount() {}

  componentWillUnmount() {}

  render() {
    return <div className="question-points">Points: {this.props.points}</div>;
  }
}

function mapStateToProps(state) {
  return {
    points: state.gamification.points
  };
}

export default connect(mapStateToProps)(requireAuth(Score));
