import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { sendLead } from "../../utils/api";

class MailingListForm extends Component {
  state = { disableButton: false, buttonText: "Send it!" };

  onSubmit = (values, actions) => {
    console.log(values);
    this.setState({ disableButton: true }, () =>
      sendLead(values, () =>
        this.setState({ disableButton: false, buttonText: "Sent!" })
      )
    );
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      first_name: Yup.string()
        .min(1, "Too short!")
        .required("Please enter your first name"),
      last_name: Yup.string()
        .min(1, "Too short!")
        .required("Please enter your last name"),
      email: Yup.string()
        .email("Not a valid email.")
        .required("Please enter your email"),
      comment: Yup.string()
        .min(1, "Too short!")
        .required("Please enter a comment.")
    });

  renderError = props => {
    console.log(props);
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ errors, status, touched, isSubmitting }) => {
    return (
      <div>
        <section className="section-form">
          <div className="row">
            <h2>We're happy to hear from you</h2>
          </div>
          <div className="row">
            <Form className="contact-form">
              <div className="row">
                <div className="col span-1-of-3">
                  <label htmlFor="name">First Name</label>
                </div>
                <div className="col span-2-of-3">
                  <Field
                    type="text"
                    component="input"
                    name="first_name"
                    id="name"
                    placeholder="Your first name"
                  />
                  <ErrorMessage
                    name="first_name"
                    component={this.renderError}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col span-1-of-3">
                  <label htmlFor="name">Last Name</label>
                </div>
                <div className="col span-2-of-3">
                  <Field
                    type="text"
                    component="input"
                    name="last_name"
                    id="name"
                    placeholder="Your last name"
                  />
                  <ErrorMessage name="last_name" component={this.renderError} />
                </div>
              </div>

              <div className="row">
                <div className="col span-1-of-3">
                  <label htmlFor="email">Email</label>
                </div>
                <div className="col span-2-of-3">
                  <Field
                    type="email"
                    component="input"
                    name="email"
                    id="email"
                    placeholder="Your email"
                  />
                  <ErrorMessage name="email" component={this.renderError} />
                </div>
              </div>

              <div className="row">
                <div className="col span-1-of-3">
                  <label htmlFor="find-us">How did you find us?</label>
                </div>
                <div className="col span-2-of-3">
                  <Field name="source" id="find-us" component="select">
                    <option value="friends">Friends</option>
                    <option value="search">Search engine</option>
                    <option value="ad">Advertisement</option>
                    <option value="other">Other</option>
                  </Field>
                  <ErrorMessage name="source" component={this.renderError} />
                </div>
              </div>

              <div className="row">
                <div className="col span-1-of-3">
                  <label>Newsletter?</label>
                </div>
                <div className="col span-2-of-3">
                  <Field
                    type="checkbox"
                    name="news"
                    id="news"
                    component="input"
                  />
                  Yes, Please
                </div>
              </div>

              <div className="row">
                <div className="col span-1-of-3">
                  <label>Drop us a line</label>
                </div>
                <div className="col span-2-of-3">
                  <Field
                    name="comment"
                    placeholder="Your message"
                    component="textarea"
                  />
                  <ErrorMessage name="comment" component={this.renderError} />
                </div>
              </div>

              <div className="row">
                <div className="col span-1-of-3">
                  <label>&nbsp;</label>
                </div>
                <div className="col span-2-of-3">
                  <input
                    type="submit"
                    value={this.state.buttonText}
                    disabled={isSubmitting || this.state.disableButton}
                  />
                </div>
              </div>
            </Form>
          </div>
        </section>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Formik
          validationSchema={this.validateSchema()}
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            source: "friends",
            news: true,
            comment: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </div>
    );
  }
}

export default MailingListForm;
