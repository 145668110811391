import axios from "axios";

import apiUrls from "../config/apiUrls";
import { 
  EXERCISE_ACTIVE_FIND,
  EXERCISE_ACTIVE_ERROR,
  EXERCISE_ACTIVE_INDEX,
  EXERCISE_ACTIVE_RESET,
  EXERCISE_ACTIVE_WORLDY,
} from "./types";

export const exerciseActiveFind = (exercise_id, callback, callbackError, callbackRes) => async dispatch => {
  // with an exercise Id, sends the active exercise
  // found as a payload. payload an exercise details object.
  
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };

    const response = await axios.post(`${apiUrls.EXERCISE_URL}/retrieve-exercise-details`, { exercise_id }, config);
    dispatch({ type: EXERCISE_ACTIVE_FIND, payload: response.data["0"] });
    if (callback) { // optional callback for redirects
      callback();
    }
    if (callbackRes) {
      // initiates a callback depending on the response from the API
      callbackRes(response.data);
    }
  } catch (e) {
    dispatch({ type: EXERCISE_ACTIVE_ERROR, payload: {} });
    console.log("Error:", e);
    if (callbackError) {
      callbackError();
    }
  }
}

export const exerciseActiveWorldy = (subCategory,subCategoryName,callback) => async dispatch => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    dispatch({ type: EXERCISE_ACTIVE_WORLDY, payload: {"subcategory": subCategory, "subCategoryName": subCategoryName}});
    if (callback) { // optional callback for redirects
      callback();
    }

  } catch (e) {
    dispatch({ type: EXERCISE_ACTIVE_ERROR, payload: {} });
    console.log("Error:", e);
    //if (callbackError) {
    //  callbackError();
    //}
  }
  
}



export const exerciseActiveIndex = newIndex => {
  // sets a new active index to be accessed in the active exercise question array
  return { type: EXERCISE_ACTIVE_INDEX, payload: newIndex };
}

export const exerciseActiveReset = () => {
  // resets active exercise to initial state
  return { type: EXERCISE_ACTIVE_RESET, payload: {} };
}

