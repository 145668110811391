import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { userProfileFind } from "../../actions/";
import { Menu, Icon, Dropdown } from "semantic-ui-react";

import url from "../../utils/routeUrls";

class NavigationHeader extends Component {
  state = { onHome: false };

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.userProfileFind({ user_id: this.props.auth.userId });
    }

    if (
      this.props.location.pathname === "/" ||
      this.props.location.pathname === "/privacy/home" ||
      this.props.location.pathname === "/blog/home" ||
      this.props.location.pathname === "/exercises/questions" ||
      this.props.location.pathname === "/exercises/worlds" ||
      this.props.location.pathname === "/exercises/world"
    ) {
      this.setState({ onHome: true });
    }
    this.props.history.listen((location, action) => {
      if (
        location.pathname === "/" ||
        location.pathname === "/privacy/home" ||
        location.pathname === "/blog/home" ||
        location.pathname === "/exercises/questions" ||
        location.pathname === "/exercises/worlds" ||
        location.pathname === "/exercises/world"
      ) {
        this.setState({ onHome: true });
      } else {
        this.setState({ onHome: false });
      }
    });
  }

  renderLinks() {
    // const first = !this.props.auth.firstName ||
    // this.props.auth.firstName === "undefined" ||
    // this.props.auth.firstName === "null" ? this.props.auth.email : this.props.auth.firstName;

    const first = this.props.userData.first_name || this.props.userData.email;

    const roles = !this.props.auth.roles ? [] : this.props.auth.roles;

    if (this.props.authenticated) {
      // "" string evalauates to false
      return (
        <Fragment>
          {roles.includes(1) && (
            <Menu.Item style={{ paddingBottom: "2px", paddingTop: "2px" }}>
              {" "}
              <Link to={url.SKILL_LIST}>Skills</Link>
            </Menu.Item>
          )}
          {/* {roles.includes(1) && <Menu.Item> <Link to={url.EXERCISE_LIST}>Exercises</Link></Menu.Item>}   
          {roles.includes(1) && <Menu.Item> <Link to={url.EXERCISE_WORLDS}>Game Worlds</Link></Menu.Item>}   */}
          <Menu.Item style={{ paddingBottom: "2px", paddingTop: "2px" }}>
            {" "}
            <Link to={url.ROBOT_STORY}>Story</Link>
          </Menu.Item>
          <Menu.Item style={{ paddingBottom: "2px", paddingTop: "2px" }}>
            {" "}
            <Link to={url.EXERCISE_LANDING}>Play and Learn</Link>
          </Menu.Item>
          <Menu.Item style={{ paddingBottom: "2px", paddingTop: "2px" }}>
            <Link to={url.ANALYTICS_DASHBOARD}>Analytics</Link>
          </Menu.Item>
          <Menu.Item style={{ paddingBottom: "2px", paddingTop: "2px" }}>
            <Link to={url.LEAD_SEARCH}>Leads</Link>
          </Menu.Item>
          <Menu.Item
            position="right"
            style={{ paddingBottom: "2px", paddingTop: "2px" }}
          >
            <Icon name="user circle" color="teal" />
            <Menu.Menu position="right">
              <Dropdown text={first} pointing>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="My Profile"
                    onClick={() => this.props.history.push(url.USER_PROFILE)}
                  />
                  <Dropdown.Item
                    text="Sign Out"
                    onClick={() => this.props.history.push(url.SIGNOUT)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu.Item>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Menu.Item position="right">
            <Icon name="sign in" color="teal" />
            <Menu.Menu position="right">
              <Dropdown text="Sign in or Sign up" pointing>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Sign In"
                    onClick={() => this.props.history.push(url.SIGNIN)}
                  />
                  <Dropdown.Item
                    text="Sign Up"
                    onClick={() => this.props.history.push(url.SIGNUP)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu.Item>
        </Fragment>
      );
    }
  }

  render() {
    // console.log("this.props.location.pathname",this.props.location.pathname );
    if (this.state.onHome) return null;
    return (
      <Menu
        borderless
        stackable
        style={{
          paddingBottom: "0px",
          marginBottom: "0px",
          borderRadius: "0px"
        }}
      >
        <Menu.Item header style={{ paddingBottom: "2px", paddingTop: "2px" }}>
          <div onClick={() => this.props.history.push(url.HOME)}>
            <img
              className="smilestein-header-icon"
              src={require("../../assets/img/smilestein-logo.png")}
            />
          </div>
        </Menu.Item>
        {this.renderLinks()}
      </Menu>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];
  return {
    userData: userData || {}, // no user logged in, userdata is empty object
    authenticated: state.auth.authenticated,
    auth: state.auth
  };
}

export default connect(
  mapStateToProps,
  { userProfileFind }
)(withRouter(NavigationHeader));
