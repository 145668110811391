/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { Component } from "react";
import { connect } from "react-redux";  
import { withRouter } from "react-router-dom";
import urls from "../../utils/routeUrls";

class SubscriptionPlans extends Component {
  render() {
    return (
      <div>
        <section className="section-plans js--section-plans" id="plans">
            <div className="row">
                <h2>Start learning today</h2>
            </div>
            <div className="row">
                <div className="col span-1-of-3">
                    <div className="plan-box js--wp-4">
                        <div>
                            <h3>Family pack</h3>
                            <p className="plan-price">$99 <span>/ year</span></p>
                            <p className="plan-price-meal">That's only 0.30$ per skill</p>
                        </div>
                        <div>
                            <ul>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Cover the whole family</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Fun Math 24/7</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Access to newest exercises</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Totaly gamified</li>
                            </ul>
                        </div>
                        <div>
                            {!this.props.authenticated && 
                            <a onClick={() => this.props.history.push(urls.SIGNUP)} className="btn btn-full">Sign up now</a>
                            }
                            {this.props.authenticated && 
                            <a onClick={() => this.props.history.push(urls.EXERCISE_LANDING)} className="btn btn-full">Sign up now</a>
                            }
                        </div>
                    </div>
                </div>
                <div className="col span-1-of-3">
                    <div className="plan-box">
                        <div>
                            <h3>2 kids</h3>
                            <p className="plan-price">$7.88 <span>/ month</span></p>
                            <p className="plan-price-meal">That's a great value</p>
                        </div>
                        <div>
                            <ul>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Up to two children</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Math 24/7</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Access to newest creations</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Transparent Analytics</li>
                            </ul>
                        </div>
                        <div>
                            {!this.props.authenticated && 
                            <a onClick={() => this.props.history.push(urls.SIGNUP)} className="btn btn-ghost">Sign up now</a>
                            }
                            {this.props.authenticated && 
                            <a onClick={() => this.props.history.push(urls.EXERCISE_LANDING)} className="btn btn-ghost">Sign up now</a>
                            }
                        </div>
                    </div>
                </div>
                <div className="col span-1-of-3">
                    <div className="plan-box">
                        <div>
                            <h3>Starter</h3>
                            <p className="plan-price">4.99$ <span>/ month</span></p>
                            <p className="plan-price-meal">A happy kid for the price of a coffee</p>
                        </div>
                        <div>
                            <ul>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>1 child</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Unlimited math</li>
                                <li><i className="ion-ios-close-empty icon-small"></i>Tears not included</li>
                                <li><i className="ion-ios-checkmark-empty icon-small"></i>Free delivery</li>
                            </ul>
                        </div>
                        <div>
                            {!this.props.authenticated && 
                            <a onClick={() => this.props.history.push(urls.SIGNUP)} className="btn btn-ghost">Sign up now</a>
                            }
                            {this.props.authenticated && 
                            <a onClick={() => this.props.history.push(urls.EXERCISE_LANDING)} className="btn btn-ghost">Sign up now</a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state)  { 
    return { authenticated: state.auth.authenticated };
}
export default connect(mapStateToProps)(withRouter(SubscriptionPlans));
