import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Header,
  Icon,
  Button,
  Segment,
  Message,
  Form as SemForm
} from "semantic-ui-react";
import * as Yup from "yup";
import SemField from "../helpers/SemField";

import { signin, clearAuthErrorMsg, userProfileFind } from "../../actions";
import url from "../../utils/routeUrls";
import redirectOnAuth from "./redirectOnAuth";
import { CustomMessage } from "../common/messageComponents";

class Signin extends Component {
  state = { disableButton: false, errorMsg: "" };

  componentWillUnmount() {
    this.props.clearAuthErrorMsg(); // clears this.props.authError
  }

  componentDidUpdate() {
    if (this.props.authError) {
      this.setState({ errorMsg: "Your email or password was incorrect." });
    }

    if (this.state.disableButton && this.props.authError) {
      this.setState({ disableButton: false });
      this.props.clearAuthErrorMsg();
    }
  }

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Sign In to your account
      </Header>
    );
  }

  onSubmit = (formProps, actions) => {
    this.setState({ disableButton: true });
    this.props.signin(
      formProps,
      () => this.props.history.push(url.EXERCISE_LANDING),
      ({ user_id }) => this.props.userProfileFind({ user_id })
    );
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Invalid email.")
        .required("Email required."),
      password: Yup.string().required("Password required.")
    });

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <div style={{ width: "40%", margin: "auto", padding: "70px 0" }}>
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <SemField
              name="email"
              type="text"
              component={SemForm.Input}
              placeholder="johndoe@email.com"
              icon="user"
              fluid
              iconPosition="left"
            />
            <ErrorMessage name="email" component={this.renderError} />
            <SemField
              name="password"
              type="password"
              component={SemForm.Input}
              fluid
              placeholder="password"
              icon="lock"
              iconPosition="left"
            />
            <ErrorMessage name="password" component={this.renderError} />
            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Sign In
            </Button>
            {this.state.errorMsg && (
              <CustomMessage
                style={{ marginTop: "10px" }}
                message={this.state.errorMsg}
                color="red"
              />
            )}
          </Form>
        </Segment>
        <Message>
          <p>
            New to SmileStein? <Link to={url.SIGNUP}>Sign Up</Link>.
          </p>
          <p>
            <Link to={url.FORGOT_PASSWORD}>Forgot your password?</Link>
          </p>
        </Message>
      </div>
    );
  };

  render() {
    return (
      <Formik
        validationSchema={this.validateSchema()}
        initialValues={{
          email: "",
          password: ""
        }}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

function mapStateToProps(state) {
  return { authError: state.auth.errorMessage };
}

export default connect(
  mapStateToProps,
  { signin, clearAuthErrorMsg, userProfileFind }
)(redirectOnAuth(Signin));
