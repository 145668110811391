import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import { Button, Icon, Select, Form as SemForm } from "semantic-ui-react";
import SemField from "../helpers/SemField";
// import _ from "lodash";

import { skillTypes, skillLevels } from "../skill/skillUtils";
import { exerciseFind, persistForm } from "../../actions";

class ExerciseFilter extends Component {
  onSubmit = (values, actions) => {
    console.log({ user_id: this.props.userId, ...values });
    this.props.exerciseFind({ user_id: this.props.userId, ...values }, true);
    // save form in global state
    this.props.persistForm(this.props.form, values);
    actions.setSubmitting(false);
  };

  renderForm = ({ isSubmitting, resetForm }) => {
    const resetWithPreferences = {};
    if (this.props.preferences) {
      resetWithPreferences.skill_type = this.props.preferences.skill_type;
      resetWithPreferences.level = this.props.preferences.level;
    }
    return (
      <Form>
        <SemForm.Group>
          <SemField
            component={Select}
            name="skill_type"
            floated="right"
            placeholder="Filter by Type"
            options={skillTypes}
          />

          <SemField
            component={Select}
            name="level"
            floated="right"
            placeholder="Filter by Grade Level"
            options={skillLevels}
            style={{ marginLeft: "7px" }}
          />

          <Button
            icon
            type="submit"
            color="blue"
            floated="right"
            disabled={isSubmitting}
          >
            <Icon name="search" />
          </Button>

          <Button
            type="button"
            onClick={() => {
              resetForm(this.props.initialValues);
              this.props.exerciseFind(
                { user_id: this.props.userId, ...resetWithPreferences },
                true
              );
            }}
            floated="right"
          >
            Your Defaults
          </Button>
        </SemForm.Group>
      </Form>
    );
  };

  render() {
    const { persistedForm, initialValues } = this.props;
    return (
      <React.Fragment>
        <Formik
          initialValues={persistedForm || initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const initialValues = {};
  if (state.auth.preferences) {
    initialValues.skill_type = state.auth.preferences.skill_type;
    initialValues.level = state.auth.preferences.level;
  }

  return {
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    initialValues,
    persistedForm: state.formik[ownProps.form] || null
  };
}

// export default compose(
//   connect(mapStateToProps, { exerciseFind }),
//   reduxForm({ destroyOnUnmount: false, form: "exercisefilter" })
// )(ExerciseFilter);

const connectedForm = connect(
  mapStateToProps,
  { exerciseFind, persistForm }
)(ExerciseFilter);

connectedForm.defaultProps = {
  form: "exercisefilter"
};

export default connectedForm;
