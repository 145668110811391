import axios from "axios";
import apiUrls from "../config/apiUrls";

import { SKILL_ERROR, SKILL_FIND, SKILL_DELETE, SKILL_CREATE, SKILL_UPDATE, SKILL_STATE_CLEAR } from "./types";

export const skillFind = (searchParams, callback, callbackError, callbackRes) => async dispatch => {
  // finds all skills in accordance to params listed in searchParams obj.
  // if searchParams is an empty object, will find every skill.
  // sometimes, a user may search for a skill that does not exist. If the user should be redirected
  // upon this occurence, use callbackRes.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    if (!searchParams) {
      searchParams = {};
    }
    const response = await axios.post(`${apiUrls.SKILL_URL}/find`, searchParams, config);
    dispatch({ type: SKILL_FIND, payload: response.data });
    if (callback) {
      callback();
    }
    if (callbackRes) {
      // initiates a callback depending on the response from the API
      callbackRes(response.data);
    }
  } catch (e) {
    dispatch({ type: SKILL_ERROR, payload: {} });
    console.log("Error:", e);
    if (callbackError && e.response.status === 500) {
      callbackError();
    }
  }
}

export const skillDelete = deleteParams => async dispatch => {
  // given an object with params, deletes any skill matching those in the param
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(`${apiUrls.SKILL_URL}/delete`, deleteParams, config);
    // response contains the object just deleted. (note: in reducer, find object matching response exactly via skill_id)
    dispatch({ type: SKILL_DELETE, payload: response.data });
  } catch (e) {
    dispatch({ type: SKILL_ERROR, payload: "" });
    console.log("Error:", e);
  }
}

export const skillCreate = (formProps, callback) => async (dispatch) => {
  // takes body required to create a new skill and a callback to redirect user
  // connects to the server and creates a new skill
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(`${apiUrls.SKILL_URL}/create`, formProps, config);
    dispatch({ type: SKILL_CREATE, payload: response.data });
    callback();
  } catch (e) {
    dispatch({ type: SKILL_ERROR, payload: {} });
    console.log("Error:", e);
  }
}

export const skillUpdate = (updatedInfo, callback) => async dispatch => {
  // takes updatedInfo object to send new contents into server to update skill
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    await axios.post(`${apiUrls.SKILL_URL}/update`, updatedInfo, config);
    const response = await axios.post(`${apiUrls.SKILL_URL}/find`, {}, config); // get all skills again (no better alternative right now)
    dispatch({ type: SKILL_UPDATE, payload: response.data });
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: SKILL_ERROR, payload: {} });
    console.log("Error:", e);
  }
}

export const skillStateClear = () => {
  // clears the skill global state, and turns it into an empty object.
  return { type: SKILL_STATE_CLEAR, payload: {} };
}