import axios from "axios";

import apiUrls from "../config/apiUrls";
import {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_CLEAR_MSG,
  AUTH_UPDATE,
  AUTH_FORGOT_PASS,
  AUTH_RESET_PASS,
  AUTH_CHANGE_PASS,
  USERPROFILE_CLEAR
} from "./types";

export const signup = ({ email, password }, callback, callbackRes) => {
  return async dispatch => {
    try {
      const response = await axios.post(`${apiUrls.AUTH_URL}/signup`, {
        email,
        password
      });
      console.log("signup data", response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user_id);
      // localStorage.setItem("firstName", response.data.first_name);
      // localStorage.setItem("lastName", response.data.last_name);
      // localStorage.setItem("email", response.data.email);
      localStorage.setItem("roles", JSON.stringify(response.data.roles));
      localStorage.setItem(
        "preferences",
        JSON.stringify(response.data.preferences)
      );
      dispatch({ type: AUTH_USER, payload: response.data });

      if (callback) {
        callback();
      }
      if (callbackRes) {
        callbackRes(response.data);
      }
    } catch (e) {
      dispatch({ type: AUTH_ERROR, payload: "Email is in use" });
    }
  };
};

export const signin = ({ email, password }, callback, callbackRes) => {
  return async dispatch => {
    try {
      const response = await axios.post(`${apiUrls.AUTH_URL}/signin`, {
        email,
        password
      });
      console.log("signin data", response.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user_id);
      // localStorage.setItem("firstName", response.data.first_name);
      // localStorage.setItem("lastName", response.data.last_name);
      // localStorage.setItem("email", response.data.email);
      localStorage.setItem("roles", JSON.stringify(response.data.roles));
      localStorage.setItem(
        "preferences",
        JSON.stringify(response.data.preferences)
      );
      dispatch({ type: AUTH_USER, payload: response.data });
      if (callback) {
        callback(); // callback for redirecting user
      }
      if (callbackRes) {
        callbackRes(response.data);
      }
    } catch (e) {
      dispatch({
        type: AUTH_ERROR,
        payload: "Email or password was incorrect."
      });
    }
  };
};

export const signout = () => dispatch => {
  localStorage.clear();
  dispatch({
    type: AUTH_USER,
    payload: { token: "", user_id: "", roles: [], preferences: {} }
  });
  dispatch({ type: USERPROFILE_CLEAR, payload: {} });
};

export const authUpdate = ({ roles, preferences }, callback) => {
  // updates the first_name, last_name, email in local storage and global state
  // should be called after profile is edited.
  const newPayload = {};
  // if (first_name) {
  //   localStorage.setItem("firstName", first_name);
  //   newPayload.firstName = first_name;
  // }
  // if (last_name) {
  //   localStorage.setItem("lastName", last_name);
  //   newPayload.lastName = last_name;
  // }
  // if (email) {
  //   localStorage.setItem("email", email);
  //   newPayload.email = email;
  // }
  if (roles) {
    localStorage.setItem("roles", JSON.stringify(roles));
    newPayload.roles = roles;
  }
  if (preferences) {
    localStorage.setItem("preferences", JSON.stringify(preferences));
    newPayload.preferences = preferences;
  } // preferences is an object so must be stringified.

  console.log(newPayload);
  if (callback) callback();
  return {
    type: AUTH_UPDATE,
    payload: newPayload
  };
};

export const authForgotPass = (email, callback) => async dispatch => {
  try {
    const response = await axios.post(`${apiUrls.AUTH_URL}/forgot-password`, {
      email
    });
    dispatch({ type: AUTH_FORGOT_PASS, payload: response.data.message });
    if (callback) callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: "Email not found on the server." });
  }
};

export const authResetPass = (hash, password, callback) => async dispatch => {
  try {
    const response = await axios.post(`${apiUrls.AUTH_URL}/reset-password`, {
      forgot_password_hash: hash,
      password
    });
    dispatch({ type: AUTH_RESET_PASS, payload: response.data.message });
    if (callback) callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: "Error: link expired or invalid" });
  }
};

export const authChangePass = (
  user_id,
  old_password,
  new_password,
  callback
) => async dispatch => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.AUTH_URL}/change-password`,
      { user_id, old_password, new_password },
      config
    );
    dispatch({ type: AUTH_CHANGE_PASS, payload: response.data.message });
    if (callback) callback();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: "Old password incorrect" });
    if (e.response.status === 404) {
      dispatch({
        type: AUTH_CHANGE_PASS,
        payload: "Old password incorrect. Password not changed."
      });
      if (callback) callback();
    }
  }
};

export const clearAuthErrorMsg = () => {
  return {
    type: AUTH_CLEAR_MSG,
    payload: ""
  };
};
