import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import _ from "lodash";
import { Segment, Label, Button } from "semantic-ui-react";

import url from "../../utils/routeUrls";
import { exerciseActiveFind } from "../../actions";

class ExerciseSummary extends Component {
  componentDidMount() {
    console.log("Exercise Id", this.props.exerciseId);
    this.props.exerciseActiveFind(this.props.exerciseId);
  }

  generateSummary() {
    return this.props.questionArray.map(question => {
      return (
        <Segment key={question.question_id}>
          <div>
            {question.is_correct ? (
              <Label color="green" ribbon>
                Correct
              </Label>
            ) : (
              <Label color="red" ribbon>
                Incorrect
              </Label>
            )}
          </div>
          <p>{question.text}</p>
          <p>
            {question.selected_options
              ? `You answered ${question.selected_options.toString()}`
              : "This was a newly added question."}
          </p>
          <p>
            {!question.is_correct &&
              question.correct_answers &&
              `The correct answer was ${question.correct_answers.toString()}`}
          </p>
        </Segment>
      );
    });
  }

  render() {
    console.log("Question Summary", this.props.questionArray);
    return (
      <div className="section-exercise-summary">
        <div className="exercise-summary-frame" id="exercise-summary-frame">
          <div className="row">
            <div className="col span-1-of-4 question-preferences">
              <div onClick={() => this.props.history.push(url.USER_PROFILE)}>
                <img
                  className="question-navigation-icons"
                  src={require("../../assets/img/setting.png")}
                />
              </div>
            </div>
            <div className="col span-2-of-4 question-category">
              <h2>Summary</h2>
            </div>
            <div className="col span-1-of-4 question-exit">
              <div onClick={() => this.props.history.push(url.EXERCISE_WORLDS)}>
                <img
                  className="question-navigation-icons"
                  src={require("../../assets/img/x.png")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {this.generateSummary()}
            <Button
              floated="right"
              onClick={() => this.props.history.push(url.EXERCISE_WORLDS)}
            >
              Continue to Play and Learn
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    questionArray: state.exerciseActive.activeExercise.questions,
    exerciseId: state.exerciseActive.activeExercise.exercise_id
  };
}

export default connect(
  mapStateToProps,
  { exerciseActiveFind }
)(withRouter(ExerciseSummary));
