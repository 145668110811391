import React, { Component } from "react";
import Header from "./Header";
import SiteFeatures from "./SiteFeatures";
import Preview from "./Preview";
import Steps from "./Steps";
import Cities from "./Cities";
import Testimonials from "./Testimonials";
import SubscriptionPlans from "./SubscriptionPlans";
import Team from "./Team";
import MailingListForm from "./MailingListForm";
import Footer from "./Footer";
import StickyHeader from "./StickyHeader";

export default class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <StickyHeader />
        <SiteFeatures />
        <Preview />
        <Steps />
        <Cities />
        <Testimonials />
        <SubscriptionPlans />
        <Team />
        <MailingListForm />
        <Footer />
      </div>
    );
  }
}
