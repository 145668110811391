export default {
  HOME: "/",
  SIGNUP: "/signup",
  SIGNIN: "/signin",
  SIGNOUT: "/signout",
  SKILL_LIST: "/skills/list",
  SKILL_CREATE: "/skills/create",
  SKILL_EDIT: "/skills/edit",
  QUESTION_LIST: "/questions/list",
  QUESTION_CREATE: "/questions/create",
  QUESTION_EDIT: "/questions/edit",
  EXERCISE_LIST: "/exercises/list",
  EXERCISE_WORLDS: "/exercises/worlds",
  EXERCISE_WORLD: "/exercises/world",
  EXERCISE_LANDING: "/exercises/landing",
  EXERCISE_QUESTIONS: "/exercises/questions",
  EXERCISE_TEMPLATE_LIST: "/exercise/template/list",
  EXERCISE_TEMPLATE_CREATE: "/exercise/template/create",
  EXERCISE_TEMPLATE_EDIT: "/exercise/template/edit",
  USER_PROFILE: "/user/profile",
  USER_PREFERENCES: "/user/preferences",
  RESET_PASSWORD: "/user/reset-password",
  FORGOT_PASSWORD: "/user/forgot-password",
  USER_CHANGE_PASSWORD: "/user/change-password",
  ANALYTICS_DASHBOARD: "/stats/dashboard",
  PRIVACY_HOME: "/privacy/home",
  BLOG_HOME: "/blog/home",
  ROBOT_STORY: "/robot/robotstory",
  ROBOT_SELECT: "/robot/robotselect",
  ROBOT_NAME: "/robot/robotname",
  LEAD_SEARCH: "/lead/search"
};
