
export const questionTypes = [
  // {value: 0, text: "Uncategorized"},
  {value: 1, text: "One-of-multiple-choices"},
  {value: 2, text: "Multiple-choice"},
  {value: 3, text: "One-of-multiple-choices-dnd"},
  {value: 4, text: "Multiple-choice-dnd"},
  {value: 5, text: "Free Response"},
];

export const formatQuestionType = num => {
  switch(num) {
    case 1: return "One-of-multiple-choices";
    case 2: return "Multiple-choice";
    case 3: return "One-of-multiple-choices-dnd";
    case 4: return "Multiple-choice-dnd";
    case 5: return "Free Response";
    default: return "Uncategorized";
  }
}