import React, { Component } from "react";

//https://www.ibm.com/privacy/us/en/#your_rights
export default class PrivacyList extends Component {
  render() {
    return (
      <div>
        <div className="privacy-header">
          Welcome to the Smilestein Blog
        </div>
        <div className="privacy-paragraph">
          We are preparing educational and technology articles. Stay tuned.
        </div>
        <div className="privacy-header">
          Article 1
        </div>
        <div className="privacy-paragraph">
          some text
        </div>
        <div className="privacy-header">Article 2</div>
        <div className="privacy-paragraph">
          more text
        </div>
      </div>
    );
  }
}