import { PAGE_SCROLLED } from "../actions/types";

export default (height = 0, action) => {
  switch (action.type) {
    case PAGE_SCROLLED:
      return action.payload;
    default:
      return height;
  }
};
