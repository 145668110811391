import React, { Component } from "react";
import { compose } from "redux";

import { connect } from "react-redux";
import { signout, persistForm } from "../../actions";
import url from "../../utils/routeUrls";

// const DestroyForm = compose(reduxForm({ destroyOnUnmount: true, form: "exercisefilter" }))(DestroyExerciseFilterForm);

class Signout extends Component {
  componentDidMount() {
    this.props.signout(); // upon render of this component, sign out
    this.props.persistForm("skillListfilter", null);
    this.props.persistForm("answerfilter", null);
    this.props.history.push(url.HOME);
    this.props.persistForm("exercisefilter", null);
  }
  render() {
    return <div />;
  }
}

export default compose(
  connect(
    null,
    { signout, persistForm }
  )
)(Signout);
