import React, { Component } from "react";
import "../../css/HomePage.css"
import { connect } from "react-redux";
import { Link } from "react-scroll";
import { withRouter } from "react-router-dom";
import urls from "../../utils/routeUrls";
import { Icon } from "semantic-ui-react";

class StickyHeader extends Component {

  state = { opacity: 0 };

  componentDidMount() {
      window.addEventListener( 'scroll', this.handleScroll );
  }
    
  componentWillUnmount() {
      window.removeEventListener( 'scroll', this.handleScroll );
  }

  handleScroll = event => {
      if (window.scrollY >= this.props.headerHeight-70){ 
          this.setState({ opacity: 1 });
      }
      else{
          this.setState({ opacity: 0 }); 
      }
  }
  
  render() {
    const first = this.props.userData.first_name || this.props.userData.email;
    return (
      <div>
            <nav className = "sticky" style = {{ opacity: this.state.opacity }}>
                <div className="row">
                    <Link
                        to= "header"
                        smooth={ true }
                        offset={ -70 }
                        duration={ 500 }
                    >
                    <img src={ require('../../assets/img/smilestein-logo.png') } alt="Smilestein logo" className= {['logo', 'small'].join(' ')}/>
                    </Link>
                    <ul className="main-nav js--main-nav sticky-header">
                        <li>
                            <Link
                                to="features"
                                smooth={ true }
                                offset={ -70 }
                                duration={ 500 }
                            >
                                Math is Fun
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="works"
                                smooth={ true }
                                offset={ -70 }
                                duration={ 500 }
                            >
                                Gamification
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="cities"
                                smooth={ true }
                                offset={ -70 }
                                duration={ 500 }
                            >
                                Our cities
                            </Link>
                        </li>
                        {!this.props.authenticated && 
                    <li>
                    <Link
                        to="plans"
                        smooth={ true }
                        offset={ -70 }
                        duration={ 500 }
                    >
                        Sign Up
                    </Link>
                  </li>}
                  {this.props.authenticated && <li><a onClick = {() => this.props.history.push(urls.USER_PROFILE)} href = "javascript:void(0);" > <Icon name="user circle" color="teal" /> { first } </a></li>}
                    </ul>
                    <a className="mobile-nav-icon js--nav-icon"><i className="ion-navicon-round"></i></a>
                </div>
            </nav>
      </div>
    );
  }
}

const mapStateToProps = state => {
    const userData = state.userProfile["0"];
    return { 
        headerHeight: state.scroll, 
        userData: userData || {},
        authenticated: state.auth.authenticated};
}

export default connect(mapStateToProps)(withRouter(StickyHeader));