import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  Header,
  Icon,
  Button,
  Segment,
  Form as SemForm
} from "semantic-ui-react";

import { authResetPass, clearAuthErrorMsg } from "../../actions";
import SemField from "../helpers/SemField";

import { CustomMessage } from "../common/messageComponents";

class ResetPasswordForm extends Component {
  // this component requires the hash and callback prop on creation to work.

  state = { disableButton: false, errorMsg: "" };

  // componentWillUnmount() {
  //   this.props.clearAuthErrorMsg(); // clears this.props.authError
  // }

  componentDidUpdate() {
    if (this.props.authError) {
      this.setState({ errorMsg: this.props.authError });
    }

    if (this.state.disableButton && this.props.authError) {
      this.setState({ disableButton: false });
      this.props.clearAuthErrorMsg();
    }
  }

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Reset Your Account Password
      </Header>
    );
  }

  onSubmit = ({ password }, actions) => {
    this.setState({ disableButton: true });
    this.props.authResetPass(this.props.hash, password, this.props.callback);
    // this.props.callback();
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      password: Yup.string()
        .min(4, "Too short. Must be at least 4 characters.")
        .required("Password Required"),
      passwordConfirm: Yup.string().required("Password Confirmation Required")
    });

  validateForm = values => {
    const errors = {};

    if (values.passwordConfirm !== values.password) {
      errors.passwordConfirm = "Password confirmation does not match.";
    }
    return errors;
  };

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting, values }) => {
    return (
      <div style={{ width: "40%", margin: "auto", padding: "70px 0" }}>
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <SemField
              name="password"
              type="password"
              component={SemForm.Input}
              placeholder="password"
              icon="lock"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="password" component={this.renderError} />
            <SemField
              name="passwordConfirm"
              type="password"
              component={SemForm.Input}
              placeholder="confirm password"
              icon="lock"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="passwordConfirm" component={this.renderError} />
            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Reset Password
            </Button>
            {this.state.errorMsg && (
              <CustomMessage
                style={{ marginTop: "10px" }}
                message={this.state.errorMsg}
                color="red"
              />
            )}
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          validate={this.validateForm}
          initialValues={{
            password: "",
            passwordConfirm: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { authError: state.auth.errorMessage };
}

export default connect(
  mapStateToProps,
  { authResetPass, clearAuthErrorMsg }
)(ResetPasswordForm);
