import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Button, Image, Icon } from "semantic-ui-react";
import { exerciseActiveIndex,gamificationScoreSet, exerciseActiveFind } from "../../actions";
import { answerCreate } from "../../utils/api";

class QuestionOneMult extends Component {

  state = { 
    disableButtons: false, 
    button1: { correct: "false", incorrect: "false" }, 
    button2: { correct: "false", incorrect: "false" }, 
    button3: { correct: "false", incorrect: "false" }, 
    button4: { correct: "false", incorrect: "false" } 
  };

  answerCallback = (answer, buttonState) => {
    // callback function to be passed into answerCreate. 
    // Updates the current active exercise state after answer endpoint is called by answerCreate.
    // exerciseActiveFind makes a callback after a delay of one second 
    // to reset the state then increment the active exercise index.
    this.props.gamificationScoreSet(answer.points, () =>
    this.props.exerciseActiveFind(this.props.exerciseId, 
      () => _.delay(
        () => this.setState({ disableButtons: "false", [buttonState]: { correct: "false", incorrect: "false" } }, 
          () => this.props.exerciseActiveIndex(this.props.activeIndex+1)), 1000)));
    
  }

  showQuestionResult = (key,disableButtons,correct,incorrect) => {
    // shows correct or wrong ui change when a user answers
    if(!disableButtons){
      return;
    }
    if (correct==="true") {
      //return <Icon name="check" color="green" size="large"></Icon>;
      return <img src={ require('../../assets/img/correct.png')} className="question-button-result"/>
    }
    if (incorrect==="true") {
      //return <Icon name="x" color="blue" size="large"></Icon>;
      return <img src={ require('../../assets/img/wrong.png') } className="question-button-result"/>
    }
  }

  render() {
    const question = this.props.currentQuestion;
    if (!question)
      return <div />;

    let timer = Date.now();
    let buttonNumber = 0;
    // generates an array of buttons (up to 4) based on question data.
    // Button state is individually controlled. 
    console.log("rendered", this.state);
    const optionButtons = question.options.map(opt => {
      const buttonState = `button${++buttonNumber}`;
      return (
        <div className="question-button" key={buttonState} onClick={() => {
          if(this.state.disableButtons){
            return;
          }
          if (question.correct_answers.includes(opt)) {
            this.setState({ disableButtons: true, [buttonState]: { correct: "true", incorrect: "false" } }, console.log("it was correct"));
          } else {
            this.setState({ disableButtons: true, [buttonState]: { correct: "false", incorrect: "true" } }, console.log("it was wrong"));
          }
          let timing = Date.now() - timer;
          answerCreate({ question_id: question.question_id, exercise_id: this.props.exerciseId, selected_options: [opt],timing: timing },  this.answerCallback, buttonState);
        }}
        >
          {opt} <br/>
          {this.showQuestionResult(buttonState,this.state.disableButtons, this.state[buttonState].correct, this.state[buttonState].incorrect)}
        </div>
      );
    });

    return (
      <div key={question.question_id}>
          {optionButtons}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const active = state.exerciseActive;
  return { 
    activeIndex: active.activeIndex, 
    currentQuestion: active.activeExercise.questions[active.activeIndex] || null,
    exerciseId: active.activeExercise.exercise_id
  };
}

export default connect(mapStateToProps, { exerciseActiveIndex, gamificationScoreSet, exerciseActiveFind })(QuestionOneMult);