import axios from "axios";
import apiUrls from "../config/apiUrls";

import {ANALYTICS_ANSWERS_PER_USER_PER_DAY,ANALYTICS_ERROR} from "./types";

export const analyticsAnswersPerUserPerDay = (searchParams, callback, callbackError, callbackRes) => async dispatch => {
  // finds all answerAnalytis in accordance to params listed in searchParams obj.
  // if searchParams is an empty object, will find all AnswersPerUerPerDay Analytis.
  // if there are no analytics or errors, use callbackRes.
  // searchParams could  be date ranges // RALF TBD
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    if (!searchParams) {
      searchParams = {};
    }
    //console.log("SeachParams",searchParams);
    const response = await axios.post(`${apiUrls.ANALYTICS_URL}/answers-per-user-per-day`, searchParams, config);
    //console.log("response", response.data);
    dispatch({ type: ANALYTICS_ANSWERS_PER_USER_PER_DAY, payload: response.data });
    if (callback) {
      callback();
    }
    if (callbackRes) {
      // initiates a callback depending on the response from the API
      callbackRes(response.data);
    }
  } catch (e) {
    dispatch({ type: ANALYTICS_ERROR, payload: {} });
    console.log("Error:", e);
    if (callbackError && e.response.status === 500) {
      callbackError();
    }
  }
}