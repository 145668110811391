import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import {
  Button,
  Icon,
  Table,
  Menu,
  Progress,
  Header,
  Divider
} from "semantic-ui-react";

import url from "../../utils/routeUrls";
import {
  skillFind,
  gamificationScoreLoad,
  goFullScreen,
  exerciseFind,
  exerciseCreate,
  exerciseActiveFind,
  exerciseReset,
  exerciseActiveIndex
} from "../../actions";
import ExerciseFilter from "./ExerciseFilter";
import { formatSkillType, formatLevel } from "../skill/skillUtils";
import { CustomMessage } from "../common/messageComponents";
import requireAuth from "../auth/requireAuth";
import Fullscreen from "react-full-screen";

import { Container } from "../../styles";

import Score from "../question/Score";

const headerNames = ["Skill", "Questions", "Progress", ""];

class ExerciseWorld extends Component {
  state = {
    disableButtons: false
  };

  componentDidMount() {
    this.props.gamificationScoreLoad({ user_id: this.props.userId });
  }

  componentWillUnmount() {}

  generateTableHeader = () => {
    return headerNames.map(name => {
      return (
        <Table.HeaderCell key={name} width={name === "Progress" ? 3 : null}>
          {name}
        </Table.HeaderCell>
      );
    });
  };

  beginButtonText(exercise) {
    // console.log(Number(exercise.count_questions), Number(exercise.count_correct) + Number(exercise.count_wrong));
    if (
      (exercise.count_questions ||
        exercise.count_correct ||
        exercise.count_wrong) &&
      Number(exercise.count_questions) ===
        Number(exercise.count_correct) + Number(exercise.count_wrong)
    ) {
      return "Restart";
    } else {
      return !exercise.exercise_id ? "Begin" : "Continue";
    }
  }

  progressPercent(exercise) {
    if (
      exercise.count_questions ||
      exercise.count_correct ||
      exercise.count_wrong
    ) {
      return Math.floor(
        ((Number(exercise.count_correct) + Number(exercise.count_wrong)) /
          Number(exercise.count_questions)) *
          100
      );
    }
    return 0;
  }

  beginExerciseButton(exercise) {
    // sends user to the specified skill's question list page when clicked
    return (
      <Button
        disabled={this.state.disableButtons}
        loading={this.state.disableButtons}
        onClick={() => {
          if (!exercise.exercise_id && !exercise.user_id)
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseCreate(
                { skill_id: exercise.skill_id, user_id: this.props.userId },
                () => this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          else if (
            Number(exercise.count_questions) ===
            Number(exercise.count_correct) + Number(exercise.count_wrong)
          ) {
            console.log("Restarting");
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseCreate(
                { skill_id: exercise.skill_id, user_id: this.props.userId },
                () => this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          } else if (exercise.user_id === this.props.userId) {
            this.props.exerciseActiveIndex(
              Number(exercise.count_correct) + Number(exercise.count_wrong)
            );
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseActiveFind(exercise.exercise_id, () =>
                this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          } else
            console.log(
              "Critical error: Localstorage UserId did not match UserId for exercise."
            );
        }}
      >
        <Icon name="play" /> {this.beginButtonText(exercise)}
      </Button>
    );
  }

  messageWarning(str, obj) {
    // displays error message str given an object to check if empty or not
    if (_.isEmpty(obj)) {
      return (
        <CustomMessage style={{ marginBottom: "5px" }} message={str} warning />
      );
    }
    return null;
  }

  formatCountQuestions = countQuestions => {
    if (!countQuestions) return 0;
    else return countQuestions;
  };

  generateExerciseTables = exercises => {
    const rows = _.map(exercises, exercise => {
      return (
        <Table.Row key={exercise.exercise_id || exercise.skill_id}>
          <Table.Cell>{exercise.name}</Table.Cell>
          <Table.Cell>
            {this.formatCountQuestions(exercise.count_questions)}
          </Table.Cell>
          <Table.Cell>
            <Progress
              size="small"
              percent={this.progressPercent(exercise)}
              color="olive"
              progress
            />
          </Table.Cell>
          <Table.Cell>{this.beginExerciseButton(exercise)}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped fixed>
        <Table.Header>
          <Table.Row>{this.generateTableHeader()}</Table.Row>
        </Table.Header>

        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  render() {
    return (
      <Fullscreen enabled={this.props.fullScreentruetrue}>
        <div className="section-excercise-world">
          <div className="subcategory-frame">
            <div className="row" className="question-header">
              <div className="col span-1-of-4 question-preferences">
                <div onClick={() => this.props.history.push(url.USER_PROFILE)}>
                  <img
                    className="question-navigation-icons"
                    src={require("../../assets/img/setting.png")}
                  />
                </div>
              </div>
              <div className="col span-2-of-4 question-category">
                {this.props.activeWorld}
              </div>
              <div className="col span-1-of-4 question-exit">
                <ul className="question-right-nav">
                  <li>
                    <Score />
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.history.push(url.EXERCISE_WORLDS)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/x.png")}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="subcategory-table">
              {this.generateExerciseTables(this.props.abc)}
            </div>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("exercisefilter");
  return {
    activeWorld: state.exerciseActive.activeWorld.subCategoryName,
    abc: state.exerciseActive.activeWorld.subcategory,
    fullScreen: state.userProfile.fullScreen,
    exercisesFound: state.exercise,
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    name: selector(state, "name"),
    skill_type: selector(state, "skill_type"),
    level: selector(state, "level")
  };
}

export default connect(
  mapStateToProps,
  {
    skillFind,
    exerciseFind,
    goFullScreen,
    exerciseCreate,
    exerciseActiveFind,
    exerciseActiveIndex,
    exerciseReset,
    gamificationScoreLoad
  }
)(requireAuth(ExerciseWorld));
