import axios from "axios";
import apiUrls from "../config/apiUrls";

import { QUESTION_FIND, QUESTION_CREATE, QUESTION_ERROR, QUESTION_DELETE } from "./types";

export const questionFind = (searchProps, callback, callbackError, callbackRes) => async dispatch => {
  // with an object searchProps containing the required params for the search, sends all
  // the skills found as a payload. payload is an array of objects
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(`${apiUrls.QUESTION_URL}/find`, searchProps, config);
    dispatch({ type: QUESTION_FIND, payload: response.data });
    if (callback) { // optional callback for redirects
      callback();
    }
    if (callbackRes) {
      // initiates a callback depending on the response from the API
      callbackRes(response.data);
    }
  } catch (e) {
    dispatch({ type: QUESTION_ERROR, payload: {} });
    console.log("Error:", e);
    if (callbackError) {
      callbackError();
    }
  }
}

export const questionCreate = (postObject, callback) => async dispatch => {
  // given an object to post and optional callback for redirect, creates a new question
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(`${apiUrls.QUESTION_URL}/create`, postObject, config);
    dispatch({ type: QUESTION_CREATE, payload: response.data });
    if (callback) { // optional callback for redirects
      callback();
    }
  } catch (e) {
    dispatch({ type: QUESTION_ERROR, payload: {} });
    console.log("Error:", e);
  }
}

export const questionDelete = deleteParams => async dispatch => {
  // based on deleteParams object, deletes all questions matching its properties.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(`${apiUrls.QUESTION_URL}/delete`, deleteParams, config);
    // response returns an array of deleted question objects
    dispatch({ type: QUESTION_DELETE, payload: response.data });
  } catch (e) {
    dispatch({ type: QUESTION_ERROR, payload: {} });
    console.log("Error:", e);
  }
}

export const questionUpdate = (postObject, skill_id, callback) => async dispatch => {
  // given an object containing the updated question information, update that question.
  // payload is the array of question objects found given the skill id related to the question.
  // since the payload is essentially the same as finding a list of questions normally, the type is
  // QUESTION_FIND, and not QUESTION_CREATE.
  // skill_id required to fetch all questions related to skill_id again.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    // update post returns an array of updated question objects
    await axios.post(`${apiUrls.QUESTION_URL}/update`, postObject, config);
    const response = await axios.post(`${apiUrls.QUESTION_URL}/find`, { skill_id }, config);

    dispatch({ type: QUESTION_FIND, payload: response.data });

    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: QUESTION_ERROR, payload: {} });
    console.log("Error:", e);
  }
}