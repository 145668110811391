import React, { Component } from 'react';
import { connect } from 'react-redux';
import url from "../../utils/routeUrls";


// HOC to prevent access of component iif user is authenticated (eg. signup page)

export default ChildComponent => {
  class ComposedComponent extends Component {
    // Our component just got rendered
    componentDidMount() {
      this.shouldNavigateAway();
    }
    // Our component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }
    shouldNavigateAway() {
      if (this.props.auth) {
        this.props.history.push(url.HOME);
      }
    }
    render() {
      return <ChildComponent {...this.props} />;
    }
  }
  function mapStateToProps(state) {
    return { auth: state.auth.authenticated };
  }
  return connect(mapStateToProps)(ComposedComponent);
};