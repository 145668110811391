import { FORM } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FORM:
      return { ...state, [action.payload.form]: action.payload.values };
    default:
      return state;
  }
};
