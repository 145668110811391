/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { Component } from "react";
import Bounce from "react-reveal/Bounce";

export default class Steps extends Component {
  render() {
    console.log(window.innerWidth);
    return (
      <div>
        <section className="section-steps" id="works">
          <div className="row">
            <h2>How it works &mdash; Simple as 1, 2, 3</h2>
          </div>
          <div className="row">
            <div className="col span-1-of-2 steps-box">
              <Bounce bottom>
                <img
                  src={require("../../assets/img/app-iPhone.png")}
                  alt="Smilestein app on iPhone"
                  width="200"
                  height="400"
                />
              </Bounce>
            </div>
            <div className="col span-1-of-2 steps-box">
              <div className="works-step clearfix">
                <div>1</div>
                <p>
                  Choose the subscription plan that best fits your needs and
                  sign up today.
                </p>
              </div>
              <div className="works-step clearfix">
                <div>2</div>
                <p>
                  Add your child's grade level, play the assessment games, and
                  personalize the goals
                </p>
              </div>
              <div className="works-step clearfix">
                <div>3</div>
                <p>
                  Our AI algorithms will determine the best options and you can
                  review the reports and personalize the plan.{" "}
                </p>
              </div>

              <a href="#" className="btn-app">
                <img
                  src={require("../../assets/img/download-app.svg")}
                  alt="App Store Button"
                />
              </a>
              <a href="#" className="btn-app">
                <img
                  src={require("../../assets/img/download-app-android.png")}
                  alt="Play Store Button"
                />
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
