import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  Header,
  Icon,
  Button,
  Segment,
  Form as SemForm
} from "semantic-ui-react";

import SemField from "../../helpers/SemField";
import { userProfileUpdate, authUpdate } from "../../../actions";

class SignupBasic extends Component {
  state = { disableButton: false, errorMsg: "" };

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Sign Up
      </Header>
    );
  }

  onSubmit = (formProps, actions) => {
    this.setState({ disableButton: true });
    formProps.user_id = this.props.userId;
    this.props.userProfileUpdate(formProps, () => this.props.updateProgress());
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      street: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      zip: Yup.string().required("Required"),
      country: Yup.string().required("Required")
    });

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <div
        style={{
          minWidth: "450px",
          maxWidth: "40%",
          margin: "auto",
          padding: "70px 0"
        }}
      >
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <label>First Name</label>
            <SemField
              name="first_name"
              type="text"
              component={SemForm.Input}
              placeholder="John"
              icon="user"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="first_name" component={this.renderError} />
            <label>Last Name</label>
            <SemField
              name="last_name"
              type="text"
              component={SemForm.Input}
              placeholder="Doe"
              icon="user"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="last_name" component={this.renderError} />
            <label>Street</label>
            <SemField
              name="street"
              type="text"
              component={SemForm.Input}
              placeholder="123 Main St."
              icon="road"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="street" component={this.renderError} />
            <label>City</label>
            <SemField
              name="city"
              type="text"
              component={SemForm.Input}
              placeholder="San Diego"
              icon="marker"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="city" component={this.renderError} />
            <label>State</label>
            <SemField
              name="state"
              type="text"
              component={SemForm.Input}
              placeholder="California"
              icon="marker"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="state" component={this.renderError} />
            <label>Zip</label>
            <SemField
              name="zip"
              type="text"
              component={SemForm.Input}
              placeholder="12345"
              icon="marker"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="zip" component={this.renderError} />
            <label>Country</label>
            <SemField
              name="country"
              type="text"
              component={SemForm.Input}
              placeholder="USA"
              icon="map"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="country" component={this.renderError} />
            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Next
            </Button>
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          initialValues={{
            first_name: "",
            last_name: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            country: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { userId: state.auth.userId };
}

export default connect(
  mapStateToProps,
  { userProfileUpdate, authUpdate }
)(SignupBasic);
