import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import {
  Button,
  Icon,
  Table,
  Menu,
  Progress,
  Header,
  Divider
} from "semantic-ui-react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ProgressBar from "react-bootstrap/ProgressBar";

import url from "../../utils/routeUrls";
import {
  skillFind,
  goFullScreen,
  exerciseFind,
  exerciseCreate,
  exerciseActiveFind
} from "../../actions";
import {
  exerciseActiveWorldy,
  exerciseReset,
  gamificationScoreLoad,
  exerciseActiveIndex
} from "../../actions";
import ExerciseFilter from "./ExerciseFilter";
import { formatSkillType, formatLevel } from "../skill/skillUtils";
import { CustomMessage } from "../common/messageComponents";
import requireAuth from "../auth/requireAuth";
import Fullscreen from "react-full-screen";

import Score from "../question/Score";

import { Container } from "../../styles";

const headerNames = ["Skill", "Type", "Level", "Questions", "Progress", ""];

class ExerciseWorlds extends Component {
  state = {
    disableButtons: false
  };

  componentDidMount() {
    const searchParams = { user_id: this.props.userId };
    if (this.props.name) searchParams.name = this.props.name;
    // if (this.props.skill_type) searchParams.skill_type = this.props.skill_type;
    // if (this.props.level) searchParams.level = this.props.level;
    if (this.props.skill_type) {
      searchParams.skill_type = this.props.skill_type;
    } else if (this.props.preferences && this.props.preferences.skill_type) {
      searchParams.skill_type = this.props.preferences.skill_type;
    }
    if (this.props.level) {
      searchParams.level = this.props.level;
    } else if (this.props.preferences && this.props.preferences.level) {
      searchParams.level = this.props.preferences.level;
    }
    console.log("props.preferences: ", this.props.preferences);
    console.log("searchParams: ", searchParams);
    this.props.exerciseFind(searchParams, true);
    this.props.gamificationScoreLoad({ user_id: this.props.userId });
  }

  componentWillUnmount() {
    //this.props.exerciseReset();
  }

  beginButtonText(exercise) {
    // console.log(Number(exercise.count_questions), Number(exercise.count_correct) + Number(exercise.count_wrong));
    if (
      (exercise.count_questions ||
        exercise.count_correct ||
        exercise.count_wrong) &&
      Number(exercise.count_questions) ===
        Number(exercise.count_correct) + Number(exercise.count_wrong)
    ) {
      return "Restart";
    } else {
      return !exercise.exercise_id ? "Begin" : "Continue";
    }
  }

  progressPercent(exercise) {
    if (
      exercise.count_questions ||
      exercise.count_correct ||
      exercise.count_wrong
    ) {
      const percent = Math.floor(
        ((Number(exercise.count_correct) + Number(exercise.count_wrong)) /
          Number(exercise.count_questions)) *
          100
      );
      const y = Math.round(percent);
      return percent;
    }
    return 0;
  }

  beginExerciseButton(exercise) {
    // sends user to the specified skill's question list page when clicked
    return (
      <Button
        disabled={this.state.disableButtons}
        loading={this.state.disableButtons}
        onClick={() => {
          if (!exercise.exercise_id && !exercise.user_id)
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseCreate(
                { skill_id: exercise.skill_id, user_id: this.props.userId },
                () => this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          else if (
            Number(exercise.count_questions) ===
            Number(exercise.count_correct) + Number(exercise.count_wrong)
          ) {
            console.log("Restarting");
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseCreate(
                { skill_id: exercise.skill_id, user_id: this.props.userId },
                () => this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          } else if (exercise.user_id === this.props.userId) {
            this.props.exerciseActiveIndex(
              Number(exercise.count_correct) + Number(exercise.count_wrong)
            );
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseActiveFind(exercise.exercise_id, () =>
                this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          } else
            console.log(
              "Critical error: Localstorage UserId did not match UserId for exercise."
            );
        }}
      >
        <Icon name="play" /> {this.beginButtonText(exercise)}
      </Button>
    );
  }

  messageWarning(str, obj) {
    // displays error message str given an object to check if empty or not
    if (_.isEmpty(obj)) {
      return (
        <CustomMessage style={{ marginBottom: "5px" }} message={str} warning />
      );
    }
    return null;
  }

  renderExercises() {
    // renders all the exercises sorted into categories and subcategories.
    return this.generateCatHeaders(this.sortExercisesFound());
  }

  images = [
    "category-tile category-tile-background1",
    "category-tile category-tile-background2",
    "category-tile category-tile-background3",
    "category-tile category-tile-background4",
    "category-tile category-tile-background5",
    "category-tile category-tile-background6",
    "category-tile category-tile-background7"
  ];

  generateCatHeaders = renderObj => {
    // given an object with format { categoryNumber: { subcategoryNumber: [ exerciseObject ] } }
    // renders the sorted exercises under their respective category and subcategory names
    let i = 0;
    return _.map(renderObj, (catObj, catName) => {
      i++;
      return (
        <div key={catName} className={this.images[i % 7]}>
          <h2 style={{ marginTop: "15px" }}>
            {catName === "0" ? "Uncategorized" : catName}
          </h2>
          <div className="subcategory-box">
            {this.generateSubCatHeaders(catObj)}
          </div>
        </div>
      );
    });
  };

  clickSubcategroy = (subCat, subCatName) => {
    this.props.exerciseActiveWorldy(subCat, subCatName, () =>
      this.props.history.push(url.EXERCISE_WORLD)
    );
  };

  subcategoryImages = [
    "subcategory-tile subcategory-tile-background1",
    "subcategory-tile subcategory-tile-background2",
    "subcategory-tile subcategory-tile-background3",
    "subcategory-tile subcategory-tile-background4",
    "subcategory-tile subcategory-tile-background5",
    "subcategory-tile subcategory-tile-background6",
    "subcategory-tile subcategory-tile-background7",
    "subcategory-tile subcategory-tile-background8",
    "subcategory-tile subcategory-tile-background9"
  ];

  sci = [
    "Asset1.png",
    "Asset2.png",
    "Asset3.png",
    "Asset4.png",
    "Asset5.png",
    "Asset6.png",
    "Asset7.png",
    "Asset8.png",
    "Asset9.png",
    "Asset10.png",
    "Asset11.png",
    "Asset12.png"
  ];

  generateSubCatHeaders = catObj => {
    let key = 0;
    let i = 0;
    return _.map(catObj, (subCat, subCatName) => {
      i = Math.floor(Math.random() * 11) + 1;
      return (
        <div className="subcategory-tile">
          <div
            key={key++}
            onClick={() => this.clickSubcategroy(subCat, subCatName)}
          >
            <div className="sci">
              <img src={require("../../assets/img/" + this.sci[i])} />{" "}
            </div>
            <div>{subCatName === "0" ? "Uncategorized" : subCatName}</div>
            {this.generateProgressBar(subCat)}
          </div>
        </div>
      );
    });
  };

  generateProgressBar = newStateSubCat => {
    let count = 0;
    let percent = 0;
    let average = 0;
    const rows = _.map(newStateSubCat, exercise => {
      count++;
      percent += this.progressPercent(exercise);
    });
    if (count > 0) {
      average = Math.round(percent / count);
    }
    return (
      <div style={{ position: "relative" }}>
        <Row>
          <Col>{count} Skills</Col>
        </Row>
        <Row>
          <Col className="subcategory-progress">
            <ProgressBar size="sm" now={average} label={`${average}%`} />
          </Col>
        </Row>
      </div>
    );
  };

  sortExercisesFound = () => {
    // sorts the exercises found from redux state into an object corresponding to subcategories and categories.
    // returns the object.

    const renderObj = {};
    const { exercisesFound } = this.props;

    // first, generate an object with category keys (represented as numbers).
    // Their vals are empty objects
    // key "0" is for any skill that lacks a category, just in case.

    _.forEach(exercisesFound, exercise => {
      if (exercise.category_name) renderObj[exercise.category_name] = {};
      else renderObj["0"] = {};
    });

    // second, generate all subcategory keys (represented as numbers) one layer down its parent category.
    // Their vals are arrays.

    _.forEach(exercisesFound, exercise => {
      if (exercise.category_name)
        // if an exercise has a valid category, assume it also has a valid subcategory
        renderObj[exercise.category_name][exercise.sub_category_name] = [];
      else renderObj["0"]["0"] = []; // the second ["0"] is to simulate an undefined subcategory to simplify render logic later
    });

    // now, assign all exercises to their relevant arrays.
    // Note: each array is never mutated.

    _.forEach(exercisesFound, exercise => {
      if (exercise.category_name)
        renderObj[exercise.category_name][
          exercise.sub_category_name
        ] = renderObj[exercise.category_name][
          exercise.sub_category_name
        ].concat([exercise]);
      else renderObj["0"]["0"] = renderObj["0"]["0"].concat([exercise]);
    });

    return renderObj;
  };

  render() {
    return (
      <Fullscreen enabled={this.props.fullScreen}>
        <div className="section-excercise-worlds">
          <div className="subject-frame">
            <Row className="question-header">
              <Col className="question-preferences">
                <div onClick={() => this.props.history.push(url.USER_PROFILE)}>
                  <img
                    className="question-navigation-icons"
                    src={require("../../assets/img/setting.png")}
                  />
                </div>
              </Col>
              <Col xs={5} className="question-category">
                {"Math Worlds"}
              </Col>
              <Col className="question-exit">
                <ul className="question-right-nav">
                  <li>
                    <Score />
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.history.push(url.EXERCISE_LANDING)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/x.png")}
                      />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
            <div className="subject-scroll-frame">
              <div>{this.renderExercises()}</div>
            </div>
            <div style={Container}>
              {this.messageWarning(
                "No exercises found with current filters!",
                this.props.exercisesFound
              )}
              <Divider />
            </div>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("exercisefilter");
  return {
    exercisesFound: state.exercise,
    fullScreen: state.userProfile.fullScreen,
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    name: selector(state, "name"),
    skill_type: selector(state, "skill_type"),
    level: selector(state, "level")
  };
}

export default connect(
  mapStateToProps,
  {
    skillFind,
    goFullScreen,
    exerciseFind,
    exerciseCreate,
    exerciseActiveFind,
    exerciseActiveWorldy,
    exerciseActiveIndex,
    exerciseReset,
    gamificationScoreLoad
  }
)(requireAuth(ExerciseWorlds));
