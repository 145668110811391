/* eslint-disable no-unused-vars*/
import React, { Component } from "react";
import { connect } from "react-redux";
import url from "../../utils/routeUrls";
import {
  goFullScreen,
  gamificationScoreLoad,
  userProfileFind
} from "../../actions";
import requireAuth from "../auth/requireAuth";
import Fullscreen from "react-full-screen";
import Bounce from "react-reveal/Bounce";
import Roll from "react-reveal/Roll";
import Flip from "react-reveal/Flip";

class ExerciseLanding extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.userProfileFind({ user_id: this.props.userId }, () =>
        this.props.gamificationScoreLoad({ user_id: this.props.userId })
      );
    }
  }

  componentWillUnmount() {}

  render() {
    const first =
      this.props.userProfile.first_name || this.props.userProfile.email;
    const robot = this.props.preferences.robot
      ? this.props.preferences.robot
      : "Robot0";
    const robotName = this.props.preferences.robot_name
      ? this.props.preferences.robot_name
      : "";
    const robotClickAction =
      robot != "Robot0"
        ? () => this.props.history.push(url.EXERCISE_WORLDS)
        : () => this.props.history.push(url.ROBOT_SELECT);
    return (
      <Fullscreen enabled={this.props.fullScreen}>
        <div className="section-excercise-landing">
          <div className="exercise-landing-frame">
            <div className="row" className="question-header">
              <div className="col span-1-of-4 question-preferences">
                <div onClick={() => this.props.history.push(url.ROBOT_SELECT)}>
                  <img
                    className="question-navigation-icons"
                    src={require("../../assets/img/setting.png")}
                  />
                </div>
              </div>
              <div className="col span-2-of-4 question-category">
                {this.props.activeWorld}
              </div>
              <div className="col span-1-of-4 question-exit">
                <ul className="question-right-nav">
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="robot-letters">
              <h1>Hi {first}</h1>
            </div>
            <Flip left>
              <div className="robot-letters">
                <h1>Welcome Back!!</h1>
              </div>
              <div className="robot-letters">
                Click your robot to play and learn.
              </div>
              <div className="robot-letters">
                You have {this.props.points} points.
              </div>
            </Flip>

            <Roll top>
              <div className="robot-landing-earth">
                <img src={require("../../assets/img/earth.png")} />
              </div>
            </Roll>
            <Bounce top>
              <div className="robot-landing-ufo" onClick={robotClickAction}>
                <img
                  className="img1"
                  src={require("../../assets/img/" + robot + ".png")}
                />
                <img
                  className="img2"
                  src={require("../../assets/img/UFO.png")}
                />
              </div>
            </Bounce>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];
  return {
    userProfile: userData || {}, // no user logged in, userdata is empty object
    fullScreen: state.userProfile.fullScreen,
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    points: state.gamification.points,
    authenticated: state.auth.authenticated
  };
}

export default connect(
  mapStateToProps,
  {
    goFullScreen,
    userProfileFind,
    gamificationScoreLoad
  }
)(requireAuth(ExerciseLanding));
