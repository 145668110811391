import React, { Component } from "react";
import { Formik, ErrorMessage, Form } from "formik";
import SemField from "../helpers/SemField";
import * as Yup from "yup";

import { connect } from "react-redux";
import {
  Button,
  Form as SemForm,
  Header,
  Divider,
  Image
} from "semantic-ui-react";

import { userProfileFind, userProfileUpdate, authUpdate } from "../../actions";

class UserProfileEdit extends Component {
  state = { disableButton: false };

  componentDidMount() {
    this.props.userProfileFind({ user_id: this.props.userId });
  }

  profileImage() {
    // note: empty string evaluates as false
    if (
      this.props.userProfile &&
      this.props.userProfile["0"] &&
      this.props.userProfile["0"].image_url
    )
      return <Image src={this.props.userProfile["0"].image_url} centered />;
    return null;
  }

  onSubmit = (values, actions) => {
    values.user_id = this.props.userId;
    console.log("Updating profile with these values:", values);

    this.setState({ disableButton: true }, () =>
      this.props.userProfileUpdate(values, this.props.toggleEdit)
    );
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Email is invalid.")
        .required("Email required."),
      first_name: Yup.string().required("First name required."),
      last_name: Yup.string().required("Last name required.")
    });

  validateForm = values => {
    const re = /^(?:ht{2}ps?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'\\+,;=.]+(?:png|jpg|jpeg|gif|svg)+$/;
    const errors = {};
    if (values.image_url && !re.test(values.image_url))
      errors.image_url = "Invalid image URL.";

    return errors;
  };

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ errors, status, touched, isSubmitting }) => {
    return (
      <Form>
        <Header content="Avatar" />
        <Header content="Current Profile Image" as="h4" />
        {this.profileImage() || "No profile image set"}
        <Divider />
        <SemField
          component={SemForm.Input}
          type="text"
          name="image_url"
          placeholder="image.jpg"
        />
        <ErrorMessage name="image_url" component={this.renderError} />
        <Divider />

        <Header content="Email" />
        <SemField
          component={SemForm.Input}
          type="email"
          name="email"
          placeholder="newjohndoe@email.com"
        />
        <ErrorMessage name="email" component={this.renderError} />
        <Divider />

        <Header content="First" />
        <SemField
          component={SemForm.Input}
          type="text"
          name="first_name"
          placeholder="John"
        />
        <ErrorMessage name="first_name" component={this.renderError} />
        <Divider />

        <Header content="Last" />
        <SemField
          component={SemForm.Input}
          type="text"
          name="last_name"
          placeholder="Doe"
        />
        <ErrorMessage name="last_name" component={this.renderError} />
        <Divider />

        <Button
          loading={this.state.disableButton}
          disabled={isSubmitting || this.state.disableButton}
          floated="right"
          type="submit"
          positive
        >
          Save Changes
        </Button>
      </Form>
    );
  };

  render() {
    const { userToEdit } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          validate={this.validateForm}
          initialValues={{
            image_url: userToEdit.image_url || "",
            email: userToEdit.email || "",
            first_name: userToEdit.first_name || "",
            last_name: userToEdit.last_name || ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
        <Button onClick={this.props.toggleEdit}>Cancel</Button>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const userToEdit = state.userProfile["0"];
  return {
    userId: state.auth.userId,
    userProfile: state.userProfile,
    userToEdit: userToEdit || {}
  };
}

export default connect(
  mapStateToProps,
  { userProfileFind, userProfileUpdate, authUpdate }
)(UserProfileEdit);
