import React, { Component } from "react";
import { Droppable } from "react-beautiful-dnd";

import DndOption from "./DndOption";

class DndArea extends Component {
  render() {
    // reorder a list lecture.
    // innerRef is required prop, along with spreading the droppable props
    return (
      <div className={this.props.className}>
        <Droppable 
          droppableId={this.props.area.id}
          direction="horizontal"
          // isDropDisabled={boolean}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef} 
              {...provided.droppableProps}
              
              style={{ minHeight: "120px", display: "flex" }}
            >
              {this.props.options.map((opt, index) => <DndOption key={opt.id} option={opt} index={index} />)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

      </div>
    );
  }
}

export default DndArea;