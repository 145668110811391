import {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_CLEAR_MSG,
  AUTH_UPDATE,
  AUTH_FORGOT_PASS,
  AUTH_RESET_PASS,
  AUTH_CHANGE_PASS
} from "../actions/types";

const INITIAL_STATE = {
  authenticated: "",
  userId: "",
  roles: [],
  preferences: {},
  errorMessage: "",
  miscMessage: ""
};

// to clear error messages, create react lifecycle componentWillUnmount in auth components that
// calls a brand new action that will clear the state for errorMessage

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: action.payload.token,
        userId: action.payload.user_id,
        // firstName: action.payload.first_name,
        // lastName: action.payload.last_name,
        // email: action.payload.email,
        roles: action.payload.roles,
        preferences: action.payload.preferences,
        errorMessage: ""
      };
    case AUTH_UPDATE:
      const { roles, preferences } = action.payload;
      const overwriteState = {};
      // if (firstName) overwriteState.firstName = firstName;
      // if (lastName) overwriteState.lastName = lastName;
      // if (email) overwriteState.email = email;
      if (roles) overwriteState.roles = roles;
      if (preferences) overwriteState.preferences = preferences;

      // console.log({ ...state, ...overwriteState });
      return {
        ...state,
        ...overwriteState
      };
    case AUTH_ERROR:
      return { ...state, errorMessage: action.payload };
    case AUTH_FORGOT_PASS:
      return { ...state, miscMessage: action.payload };
    case AUTH_RESET_PASS:
      return { ...state, miscMessage: action.payload };
    case AUTH_CHANGE_PASS:
      return { ...state, miscMessage: action.payload };
    case AUTH_CLEAR_MSG:
      return { ...state, errorMessage: "", miscMessage: "" };
    default:
      return state;
  }
};
