import _ from "lodash";
import {
  SKILL_CREATE,
  SKILL_DELETE,
  SKILL_UPDATE,
  SKILL_ERROR,
  SKILL_FIND,
  SKILL_STATE_CLEAR
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case SKILL_FIND:
      // State format: { 0: { name: ...}, 1: { name: ...} }
      return { ...action.payload };

    case SKILL_CREATE:
      const key = Object.values(state).length;
      return { ...state, [key]: action.payload };
    case SKILL_DELETE:
      const deletedSkillIdArr = action.payload.map(skill => {
        return skill.skill_id;
      });
      const newState = _.filter(state, skill => {
        // return object of all skills exluding skill with id of what was removed
        return !deletedSkillIdArr.includes(skill.skill_id);
      });
      return { ...newState };
    case SKILL_UPDATE:
      return { ...action.payload };
    case SKILL_STATE_CLEAR:
      return {};
    case SKILL_ERROR:
      return state; // return the last correct state
    default:
      return state;
  }
};
