import React, { Component } from "react";
import { connect } from "react-redux";
import { Step, Icon } from "semantic-ui-react";

import SignupCredentials from "./SignupCredentials";
import SignupDetail from "./SignupDetail";
import SignupPlan from "./SignupPlan";
import SignupPayment from "./SignupPayment";
import SignupPrefs from "./SignupPrefs";
import SignupChildren from "./SignupChildren";

import url from "../../../utils/routeUrls";

// TODO: disable step component on small screens. Also disable 50% width style on small screens. how?
class SignupWizard extends Component {
  // component that re-renders depending on progress

  componentDidMount() {
    // check if logged in ON MOUNT ONLY. If using HOC to ensure not logged in, will redirect as soon as user is signed up!
    if (this.props.isLoggedIn) {
      this.props.history.push(url.HOME);
    }
  }

  state = { progress: 0, subscriptionPlan: "" }; // progress 1 for testing, change back to 0 for prod

  updateProgress = () => this.setState({ progress: this.state.progress + 1 });

  updateSubscriptionPlan = ({ plan }) =>
    this.setState({ subscriptionPlan: plan });

  getSubscriptionPlan = () => {
    // helper for payment screen
    const temp = this.state.subscriptionPlan.concat().split(" ");
    return {
      term: Number(temp[0]),
      subscription_type: Number(temp[1]),
      amount: Number(temp[2])
    };
  };

  registrationProgress = currentProgress => (
    <Step.Group stackable="tablet" size="small" attached="top" widths={6}>
      <Step active={currentProgress === 1} completed={currentProgress > 1}>
        <Icon name="address card outline" />
        <Step.Content>
          <Step.Title>User Details</Step.Title>
          <Step.Description>Customize your profile</Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={currentProgress === 2}
        disabled={currentProgress < 2}
        completed={currentProgress > 2}
      >
        <Icon name="child" />
        <Step.Content>
          <Step.Title>Plan</Step.Title>
          <Step.Description>
            Select number of children to manage
          </Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={currentProgress === 3}
        disabled={currentProgress < 3}
        completed={currentProgress > 3}
      >
        <Icon name="payment" />
        <Step.Content>
          <Step.Title>Payment</Step.Title>
          <Step.Description>{"Select & enter payment method"}</Step.Description>
        </Step.Content>
      </Step>
      <Step
        active={currentProgress === 4}
        disabled={currentProgress < 4}
        completed={currentProgress > 4}
      >
        <Icon name="mail" />
        <Step.Content>
          <Step.Title>Preferences</Step.Title>
          <Step.Description>
            Enter a passcode and email preferences
          </Step.Description>
        </Step.Content>
      </Step>
      <Step active={currentProgress === 5} disabled={currentProgress < 5}>
        <Icon name="add user" />
        <Step.Content>
          <Step.Title>Add Children</Step.Title>
          <Step.Description>Link children to your account!</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );

  chooseStep() {
    // rerenders components as the user advances through the signup flow
    switch (this.state.progress) {
      case 0:
        return <SignupCredentials updateProgress={this.updateProgress} />;
      case 1:
        return <SignupDetail updateProgress={this.updateProgress} />;
      case 2:
        return (
          <SignupPlan
            updateProgress={this.updateProgress}
            updateSubPlan={this.updateSubscriptionPlan}
          />
        );
      case 3:
        return (
          <SignupPayment
            updateProgress={this.updateProgress}
            getSubPlan={this.getSubscriptionPlan}
          />
        );
      case 4:
        return <SignupPrefs updateProgress={this.updateProgress} />;
      case 5:
        return (
          <SignupChildren
            updateProgress={this.updateProgress}
            getSubPlan={this.getSubscriptionPlan}
          />
        );
      default:
        return <div>Finished wizard</div>; // redirect to exercise page when flow is complete. job for last component in flow, default case never used
    }
  }

  render() {
    return (
      <div>
        {this.state.progress !== 0 &&
          this.registrationProgress(this.state.progress)}
        {this.chooseStep()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { isLoggedIn: state.auth.authenticated };
}

export default connect(mapStateToProps)(SignupWizard);
