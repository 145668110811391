import React, { Component } from "react";
import { connect } from "react-redux";
import { Header, Divider } from "semantic-ui-react";
import { ErrorMessage, Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { userProfileFind, userProfileUpdate, authUpdate } from "../../actions";

class RobotNameForm extends Component {
  state = { disableButton: false, buttonText: "Send it!" };

  componentDidMount() {}

  onSubmit = (values, actions) => {
    this.setState({ disableButton: true });

    this.props.preferences.robot_name = values.robot_name;

    console.log("Updating preferences with these values:", {
      user_id: this.props.userId,
      preferences: this.props.preferences
    });
    // updates user profile with form values. then, runs a callback which updates the global auth state,
    // then runs a callback to toggle the profile edit view back to profile view
    this.props.userProfileUpdate(
      { user_id: this.props.userId, preferences: this.props.preferences },
      () =>
        this.props.authUpdate({ preferences: this.props.preferences }, () =>
          this.setState({ disableButton: false, buttonText: "Sent!" })
        )
    );
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      robot_name: Yup.string()
        .min(1, "Too short!")
        .required("Please enter your robot's name")
    });

  renderError = props => {
    console.log(props);
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ errors, status, touched, isSubmitting }) => {
    // console.log(this.props.userProfile);
    return (
      <Form>
        <Field
          className="inputField"
          type="text"
          component="input"
          name="robot_name"
          id="name"
          placeholder="Name your robot"
        />
        <ErrorMessage name="robot_name" component={this.renderError} />
        <Divider />
        <div>
          <input
            type="submit"
            value={this.state.buttonText}
            disabled={isSubmitting || this.state.disableButton}
          />
        </div>
        <Divider />
      </Form>
    );
  };

  render() {
    const { initialValues } = this.props;
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const preferences = state.auth.preferences;
  const initialValues = {};

  if (preferences) {
    initialValues.robot_name = preferences.robot_name || null;
  }

  return {
    userId: state.auth.userId,
    userProfile: state.userProfile,
    initialValues,
    preferences: preferences
  };
}

export default connect(
  mapStateToProps,
  { userProfileFind, userProfileUpdate, authUpdate }
)(RobotNameForm);
