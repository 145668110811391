import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, ErrorMessage, Form } from "formik";
import SemField from "../helpers/SemField";
import * as Yup from "yup";

import {
  Segment,
  Divider,
  Header,
  Grid,
  Button,
  Form as SemForm,
  Message,
  Container
} from "semantic-ui-react";

import {
  userProfileFind,
  authChangePass,
  clearAuthErrorMsg
} from "../../actions";
import requireAuth from "../auth/requireAuth";
import Sidebar from "./Sidebar";

class UserChangePass extends Component {
  state = { disableButton: false };

  componentDidMount() {
    this.props.userProfileFind({ user_id: this.props.userId });
  }

  componentWillUnmount() {
    this.props.clearAuthErrorMsg();
  }

  onSubmit = (values, actions) => {
    this.setState({ disableButton: true }, () =>
      this.props.authChangePass(
        this.props.userId,
        values.old_password,
        values.new_password,
        () => this.setState({ disableButton: false })
      )
    );

    actions.resetForm({
      old_password: "",
      new_password: "",
      new_password_confirm: ""
    });

    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      old_password: Yup.string().required("Password required."),
      new_password: Yup.string()
        .min(4, "Password is too short!")
        .required("Enter a new password."),
      new_password_confirm: Yup.string().required(
        "Please confirm new password."
      )
    });

  validateForm = values => {
    const errors = {};

    if (values.new_password_confirm !== values.new_password) {
      errors.new_password_confirm = "New Password confirmation does not match.";
    }

    return errors;
  };

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ errors, status, touched, isSubmitting }) => {
    return (
      <Container>
        <Grid columns="equal" stackable container>
          <Grid.Column>
            <Sidebar />
          </Grid.Column>
          <Grid.Column computer={12} tablet={10}>
            <Segment>
              <Header as="h4" content="Change Password" />
              <Divider />
              <Form>
                <SemField
                  name="old_password"
                  type="password"
                  component={SemForm.Input}
                  placeholder="Current Password"
                  icon="lock"
                  iconPosition="left"
                />
                <ErrorMessage
                  name="old_password"
                  component={this.renderError}
                />
                <SemField
                  name="new_password"
                  type="password"
                  component={SemForm.Input}
                  placeholder="New Password"
                  icon="lock"
                  iconPosition="left"
                />
                <ErrorMessage
                  name="new_password"
                  component={this.renderError}
                />
                <SemField
                  name="new_password_confirm"
                  type="password"
                  component={SemForm.Input}
                  placeholder="Confirm New Password"
                  icon="lock"
                  iconPosition="left"
                />
                <ErrorMessage
                  name="new_password_confirm"
                  component={this.renderError}
                />
                <Button
                  loading={this.state.disableButton}
                  disabled={isSubmitting || this.state.disableButton}
                >
                  Change Password
                </Button>
              </Form>
              {this.props.message && <Message>{this.props.message}</Message>}
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          validate={this.validateForm}
          initialValues={{
            old_password: "",
            new_password: "",
            new_password_confirm: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.auth.userId,
    message: state.auth.miscMessage
  };
}

export default connect(
  mapStateToProps,
  { userProfileFind, authChangePass, clearAuthErrorMsg }
)(requireAuth(UserChangePass));
