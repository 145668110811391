import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import {
  Header,
  Icon,
  Button,
  Segment,
  Form as SemForm
} from "semantic-ui-react";

import { subCreate } from "../../../actions";

import SemField from "../../helpers/SemField";
import { CustomMessage } from "../../common/messageComponents";
import { paymentTypes } from "./signupUtils";
import {
  validateCvv,
  validateExp,
  validateCC
} from "../../../utils/validateCCDetails";

// todo: validate expiration date (and everything else), hook up sub create action creator
class SignupPayment extends Component {
  state = { disableButton: false, errorMsg: "" };

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="credit card outline" />
        Credit Card Info
      </Header>
    );
  }

  onSubmit = (formProps, actions) => {
    this.setState({ disableButton: true });

    const { term, subscription_type, amount } = this.props.getSubPlan();

    const formPropsCopy = { ...formProps };
    formPropsCopy.expiration = formProps.expiration.replace(/\//g, "");

    const subProps = {
      user_id: this.props.userId,
      product_id: "12345678-aaaa-bbbb-abcd-abcdefdefabc",
      subscription_type,
      term,
      amount,
      currency: "USD",
      payment: formPropsCopy
    };

    this.props.subCreate(
      subProps,
      () => this.props.updateProgress(),
      errorMsg => this.setState({ errorMsg, disableButton: false })
    );
    console.log(subProps);
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      payment_type: Yup.string()
        .min(1, "Enter a valid card type")
        .required("Enter a valid card type."),
      card_number: Yup.string().required("Enter a valid cc."),
      expiration: Yup.string().required(
        "Enter a valid expiry date in the format of MM/YY."
      ),
      cvv: Yup.string().required("Emter a valid 3 or 4 digit cvv.")
    });

  validateForm = values => {
    const errors = {};

    if (
      values.card_number &&
      values.payment_type &&
      !validateCC(values.payment_type, values.card_number)
    ) {
      errors.card_number = "Enter a valid cc for your card type.";
    }

    if (!validateExp(values.expiration)) {
      errors.expiration = "Enter a valid expiry date in the format of MM/YY.";
    }

    if (!validateCvv(values.cvv)) {
      errors.cvv = "Enter a valid 3 or 4 digit cvv.";
    }
    return errors;
  };

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <div
        style={{
          minWidth: "450px",
          maxWidth: "40%",
          margin: "auto",
          padding: "70px 0"
        }}
      >
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form autoComplete="off">
            <SemField
              component={SemForm.Select}
              name="payment_type" // visa, amex, etc
              placeholder="Select Card Type"
              options={paymentTypes}
              fluid
            />
            <ErrorMessage name="payment_type" component={this.renderError} />
            <label>cc</label>
            <SemField
              name="card_number"
              type="text"
              component={SemForm.Input}
              placeholder="0000000000000000"
              icon="credit card"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="card_number" component={this.renderError} />
            <label>Expiry Date</label>
            <SemField
              name="expiration"
              type="text"
              component={SemForm.Input}
              placeholder="07/20"
              icon="calendar"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="expiration" component={this.renderError} />
            <label>cvv</label>
            <SemField
              name="cvv"
              type="text"
              component={SemForm.Input}
              placeholder="999"
              icon="key"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="cvv" component={this.renderError} />

            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Next
            </Button>
            {this.state.errorMsg && (
              <CustomMessage
                style={{ marginTop: "10px" }}
                message={this.state.errorMsg}
                color="red"
              />
            )}
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          validate={this.validateForm}
          initialValues={{
            payment_type: "",
            card_number: "",
            expiration: "",
            cvv: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { userId: state.auth.userId };
}

export default connect(
  mapStateToProps,
  { subCreate }
)(SignupPayment);
