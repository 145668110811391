import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Button, Image } from "semantic-ui-react";

import { exerciseActiveIndex, gamificationScoreSet, exerciseActiveFind } from "../../actions";
import { answerCreate } from "../../utils/api";

class QuestionMult extends Component {

  // question: should answer checking be exact array or partial.
  timer = Date.now();

  state = { 
    selectedChoices: [],
    disableButtons: false, 
    disableSubmitButton: false,
    button1: { correct: false, incorrect: false, selected: false }, 
    button2: { correct: false, incorrect: false, selected: false }, 
    button3: { correct: false, incorrect: false, selected: false }, 
    button4: { correct: false, incorrect: false, selected: false }
  };

  removeChoice = (choice) => {
    // returns a new array without the choice specified in the parameter
    return _.filter(this.state.selectedChoices, choiceToCheck => {
      return choiceToCheck !== choice;
    });
    
  }

  addChoice = (choice) => {
    // returns a new choice array to be set as state. If choice exists in array, return same array.
    if (!this.state.selectedChoices.includes(choice))
      return this.state.selectedChoices.concat([choice]);
    return this.state.selectedChoices;
  }

  answerCallback = (answer) => {
    // callback function to be passed into answerCreate. 
    // Updates the current active exercise state after answer endpoint is called by answerCreate.
    // exerciseActiveFind makes a callback after a delay of one second 
    // to reset the state then increment the active exercise index.
    this.props.gamificationScoreSet(answer.points, () => this.props.exerciseActiveFind(this.props.exerciseId, 
      () => _.delay(() => this.setState({ 
        disableButtons: false, 
        disableSubmitButton: false, 
        selectedChoices: [],
        button1: { correct: false, incorrect: false, selected: false }, 
        button2: { correct: false, incorrect: false, selected: false }, 
        button3: { correct: false, incorrect: false, selected: false }, 
        button4: { correct: false, incorrect: false, selected: false } 
      }, () => this.props.exerciseActiveIndex(this.props.activeIndex+1)), 1000)));
    
  }

  submitAnswers = () => {
    // After clicking the submit button, checks the answers via client and server.
    // Every selected choice will change color depending on correctness.
    const buttonNames = [ "button1", "button2", "button3", "button4" ];

    this.setState({ disableButtons: true, disableSubmitButton: true });
    console.log(this.props.currentQuestion.correct_answers, this.state.selectedChoices);
    if (this.props.currentQuestion.correct_answers.concat().sort().toString() === this.state.selectedChoices.concat().sort().toString()) {
      console.log("Was correct");
      buttonNames.forEach(name => {
        if (this.state[name].selected) {
          this.setState({ [name]: { correct: true, incorrect: false, selected: false } });
        }
      });
    } else {
      console.log("Was incorrect");
      buttonNames.forEach(name => {
        if (this.state[name].selected) {
          this.setState({ [name]: { correct: false, incorrect: true, selected: false } });
        }
      });
    }
    var timing = Date.now() - this.timer;
    answerCreate({ question_id: this.props.currentQuestion.question_id, exercise_id: this.props.exerciseId, selected_options: this.state.selectedChoices,timing:timing }, this.answerCallback);
  }

  showQuestionResult = (selected,disabled, correct, incorrect) => {
    // shows correct or wrong ui change when a user answers

    if (!disabled){
      if (selected) {
        //return <Icon name="check" color="green" size="large"></Icon>;
        return <img src={ require('../../assets/img/selected.png')} className="question-button-result"/>
      }
    } else {
      if (correct) {
        return <img src={ require('../../assets/img/correct.png')} className="question-button-result"/>
      }
      if (incorrect) {
        return <img src={ require('../../assets/img/wrong.png') } className="question-button-result"/>
      }
  
    }
  }


  render() {
    console.log(this.state);
    this.timer = Date.now();
    const question = this.props.currentQuestion;
    if (!question)
      return <div />;

    let buttonNumber = 0;
    const optionButtons = question.options.map(opt => {
      const buttonState = `button${++buttonNumber}`;
      return (
        <div className="question-button" key={opt} onClick={() => { 
          this.setState({ 
            [buttonState]: { correct: false, incorrect: false, selected: !this.state[buttonState].selected }, 
            selectedChoices: this.state[buttonState].selected ? this.removeChoice(opt) : this.addChoice(opt),
          }, console.log("select toggled"));
        }}
        disabled={this.state.disableButtons}
        positive={this.state[buttonState].correct}
        negative={this.state[buttonState].incorrect}
        color={this.state[buttonState].selected ? "blue" : null }
        >
          {opt} <br/>
          {this.showQuestionResult(this.state[buttonState].selected,this.state.disableButtons,this.state[buttonState].correct,this.state[buttonState].incorrect)}
        </div>
      );
    });

    return (
      <div key={question.question_id}>
         <div>
          {optionButtons}
        </div>
        <Button 
            disabled={this.state.disableSubmitButton}
            loading={this.state.disableSubmitButton}
            onClick={this.submitAnswers}
          >
            Submit Answer
          </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const active = state.exerciseActive;
  return { 
    activeIndex: active.activeIndex, 
    currentQuestion: active.activeExercise.questions[active.activeIndex] || null,
    exerciseId: active.activeExercise.exercise_id,
  };
}

export default connect(mapStateToProps, { exerciseActiveIndex, gamificationScoreSet, exerciseActiveFind })(QuestionMult);