import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import { Button, Form as SemForm, Select } from "semantic-ui-react";
import _ from "lodash";

import SemField from "../helpers/SemField";
import { skillTypes, skillLevels } from "./skillUtils";
import { skillFind, persistForm } from "../../actions";
import { skillCatFind, skillSubCatFind } from "../../utils/api";

class SkillListFilter extends Component {
  state = {
    pendingCatResolve: false,
    pendingSubCatResolve: false,
    prevCat: null,
    prevLevel: null,
    prevSkillType: null,
    categories: [],
    subcategories: []
  };

  checkCatValid = (category, setFieldValue) => {
    // ensures that the category exists for a given level and skill_type. If not, resets field to null
    const checkValid = _.filter(
      this.state.categories,
      cat => cat.category === category
    ).length;
    if (checkValid === 0) {
      setFieldValue("category", null);
    }
  };

  checkSubCatValid = (sub_category, setFieldValue) => {
    // ensures that subcategory exists for a given category. If not, resets field to null
    const checkValid = _.filter(
      this.state.subcategories,
      subCat => subCat.sub_category === sub_category
    ).length;
    if (checkValid === 0) {
      setFieldValue("sub_category", null);
    }
  };

  updateAllCategories = (
    { skill_type, level, category, sub_category },
    setFieldValue
  ) => {
    // fetches category and subcategory and updates local state.
    // Should fetch every time skill_type, level, or category changes in componentDidUpdate().

    // To prevent early submission of form (eg. when connection is slow) the flags pendingCatResolve and pendingSubCatResolve are used.
    // Before the request is sent, the pending states are set to true, and on complete, becomes false.
    // When the pending state is true, the submit button is disabled.

    const {
      prevLevel,
      prevSkillType,
      prevCat,
      pendingCatResolve,
      pendingSubCatResolve
    } = this.state;

    if (
      (skill_type || skill_type === 0) &&
      (level || level === 0) &&
      !pendingCatResolve &&
      (prevLevel !== level || prevSkillType !== skill_type)
    ) {
      this.setState({ pendingCatResolve: true }, () =>
        skillCatFind(
          { skill_type, level },
          () =>
            this.setState(
              {
                categories: [],
                prevCat: category,
                prevLevel: level,
                prevSkillType: skill_type,
                pendingCatResolve: false
              },
              () => this.checkCatValid(category, setFieldValue)
            ),
          res => {
            this.setState(
              {
                categories: res.data,
                prevCat: category,
                prevLevel: level,
                prevSkillType: skill_type,
                pendingCatResolve: false
              },
              () => this.checkCatValid(category, setFieldValue)
            );
          }
        )
      );
    }
    if (
      (skill_type || skill_type === 0) &&
      (level || level === 0) &&
      category &&
      !pendingSubCatResolve &&
      (prevLevel !== level ||
        prevSkillType !== skill_type ||
        prevCat !== category)
    ) {
      this.setState({ pendingSubCatResolve: true }, () =>
        skillSubCatFind(
          { skill_type, level, category },
          () =>
            this.setState(
              {
                subcategories: [],
                prevCat: category,
                prevLevel: level,
                prevSkillType: skill_type,
                pendingSubCatResolve: false
              },
              () => this.checkSubCatValid(sub_category, setFieldValue)
            ),
          res => {
            this.setState(
              {
                subcategories: res.data,
                prevCat: category,
                prevLevel: level,
                prevSkillType: skill_type,
                pendingSubCatResolve: false
              },
              () => this.checkSubCatValid(sub_category, setFieldValue)
            );
          }
        )
      );
    }
  };

  componentDidMount() {
    // when navigating away from page after selecting a dropdown, ensures the subcat is still generated when returning to the page
    const { persistedForm } = this.props;
    if (persistedForm && persistedForm.category)
      skillSubCatFind(
        {
          skill_type: persistedForm.skill_type,
          level: persistedForm.level,
          category: persistedForm.category
        },
        () =>
          this.setState({ subcategories: [], prevCat: persistedForm.category }),
        res =>
          this.setState({
            subcategories: res.data,
            prevCat: persistedForm.category
          })
      );
  }

  // componentDidUpdate() {
  //   // when a user selects a new category option, attempts to find all subcats for the parent cat, along with any other more complicated params.
  //   this.updateAllCategories();
  // }

  generateCatOptions() {
    return this.state.categories.map(cat => ({
      value: cat.category,
      text: cat.category_name
    }));
  }

  generateSubCatOptions() {
    // using the local state with all subcategory options, generates an array of objects to be used for the subcategory dropdown
    return this.state.subcategories.map(subcat => ({
      value: subcat.sub_category,
      text: subcat.sub_category_name
    }));
  }

  onSubmit = (values, actions) => {
    console.log(values);
    this.props.skillFind(values);
    this.props.persistForm("skillListfilter", values);
    actions.setSubmitting(false);
  };

  renderForm = ({ values, isSubmitting, resetForm, setFieldValue }) => {
    this.updateAllCategories(values, (fieldName, value) =>
      setFieldValue(fieldName, value)
    );
    return (
      <Form>
        <SemForm.Group inline>
          <SemField
            component={Select}
            name="skill_type"
            placeholder="Filter by Type"
            options={skillTypes}
          />
          <SemField
            component={Select}
            name="level"
            placeholder="Filter by Grade Level"
            options={skillLevels}
          />
        </SemForm.Group>
        <SemForm.Group inline>
          <SemField
            component={Select}
            name="category"
            placeholder="Filter by Cat"
            options={this.generateCatOptions()}
            disabled={this.state.categories.length === 0}
          />

          <SemField
            component={Select}
            name="sub_category"
            placeholder="Filter by SubCat"
            disabled={this.state.subcategories.length === 0}
            options={this.generateSubCatOptions()}
          />

          <Button
            type="submit"
            color="blue"
            floated="right"
            loading={
              this.state.pendingCatResolve || this.state.pendingSubCatResolve
            }
            disabled={
              this.state.pendingCatResolve ||
              this.state.pendingSubCatResolve ||
              isSubmitting
            }
          >
            Filter Skills
          </Button>

          <Button
            type="button"
            loading={
              this.state.pendingCatResolve || this.state.pendingSubCatResolve
            }
            disabled={
              this.state.pendingCatResolve || this.state.pendingSubCatResolve
            }
            onClick={() => {
              resetForm(this.props.initialValues);
              this.setState({
                categories: [],
                subcategories: [],
                prevCat: null,
                prevLevel: null,
                prevSkillType: null
              });
              this.props.skillFind({});
            }}
            floated="right"
          >
            Reset Filters
          </Button>
        </SemForm.Group>
      </Form>
    );
  };

  render() {
    const { persistedForm, initialValues } = this.props;
    return (
      <React.Fragment>
        <Formik
          initialValues={persistedForm || initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const initialValues = {
    skill_type: 0,
    level: 0,
    category: null,
    sub_category: null
  };
  return {
    initialValues,
    persistedForm: state.formik[ownProps.form] || null
  };
}

const connectedForm = connect(
  mapStateToProps,
  { skillFind, persistForm }
)(SkillListFilter);

connectedForm.defaultProps = {
  form: "skillListfilter"
};

export default connectedForm;
