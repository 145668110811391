// import _ from "lodash";

import {
  // EXERCISE_CREATE,
  EXERCISE_FIND,
  EXERCISE_ERROR,
  EXERCISE_RESET
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case EXERCISE_FIND:
      return { ...action.payload }; // returns an object of exercise objects

    // case EXERCISE_CREATE:
    //   return { ...action.payload };

    // case EXERCISE_TEMPLATE_DELETE:
    //   const deletedTemplateIdArr = action.payload.map(template => {
    //     return template.template_id;
    //   });
    //   const newState = _.filter(state, template => {
    //     return !deletedTemplateIdArr.includes(template.template_id);
    //   });
    //   return { ...newState };
    case EXERCISE_RESET:
      return {};

    case EXERCISE_ERROR:
      return state;
    default:
      return state;
  }
};
