import React, { Component } from "react";
import { connect } from "react-redux";
import { Segment, Divider, Header, Grid, Menu, Button, Icon } from "semantic-ui-react";

import { userProfileFind } from "../../actions";
import requireAuth from "../auth/requireAuth";
import Sidebar from "./Sidebar";
import UserPreferencesEdit from "./UserPreferencesEdit";
import { formatSkillType, formatLevel } from "../skill/skillUtils";

class UserPreferences extends Component {

  state = { toggleEdit: false };

  componentDidMount() {
    this.props.userProfileFind({ user_id: this.props.userId });
  }

  profileViewOrEdit() {
    // shows the profile view or profile edit depending on current state
    if (this.state.toggleEdit) {
      // edit
      return (
        <Segment>
          <UserPreferencesEdit toggleEdit={() => this.setState({ toggleEdit: false })}/>
        </Segment>
      );
    } else {
      return (
        <Segment>
          <Header content="Search Preferences" />
          <Divider />
          <Header as="h4" content="Default Skill Type" />
          {this.props.userProfile && this.props.userProfile["0"] && this.props.userProfile["0"].preferences && formatSkillType(this.props.userProfile["0"].preferences.skill_type) }
          <Header as="h4" content="Default Level" />
          {this.props.userProfile && this.props.userProfile["0"] && this.props.userProfile["0"].preferences && formatLevel(this.props.userProfile["0"].preferences.level) }
          <Divider />
        </Segment>
      );
    }
  }

  render() {
    console.log(this.props.userProfile);
    return (
      <div style={{ width: "80%", margin: "auto", marginTop: "15px" }}>
        <Grid columns="equal" stackable container>
          <Grid.Column>
            <Sidebar />
          </Grid.Column>
          <Grid.Column computer={12} tablet={10} >
            <Menu>
              <Menu.Item position="right" >
                <Button icon disabled={this.state.toggleEdit} onClick={() => this.setState({ toggleEdit: true })}>
                  <Icon name="edit" />
                </Button>
              </Menu.Item>
            </Menu>
            {this.profileViewOrEdit()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    userProfile: state.userProfile, 
    userId: state.auth.userId, 
    userPrefs: state.auth.preferences 
  };
}

export default connect(mapStateToProps, { userProfileFind })(requireAuth(UserPreferences));