import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import urls from "../../utils/routeUrls";

class PrivacyHeader extends Component {
  headerTag = React.createRef()
  render() {
    return (
      <div className="row">
        <a onClick = {() => this.props.history.push(urls.HOME)} > <img src={ require('../../assets/img/smilestein-logo.png') } alt="Smilestein logo" className="logo"/>  </a>
      </div>
    );
  }
}
export default (withRouter(PrivacyHeader));