// this file contains variables and functions that are shared across the different skill pages.

export const skillLevels = [
  // {value: 0, text: "Uncategorized" },
  {value: 20, text: "Pre-K"},
  {value: 21, text: "K"},
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"}
];

export const skillTypes = [
  // {value: 0, text: "Uncategorized" },
  {value: 1, text: "Math"},
  {value: 2, text: "Science"},
];

export const formatLevel = num => {
  switch(num) {
    case 20: return "Pre-Kinder";
    case 21: return "Kindergarten";
    case 1: return "1st Grade";
    case 2: return "2nd Grade";
    case 3: return "3rd Grade";
    case 4: return "4th Grade";
    case 5: return "5th Grade";
    case 6: return "6th Grade";
    default: return "Uncategorized";
  }
}

export const formatSkillType = num => {
  switch(num) {
    case 1: return "Math";
    case 2: return "Science";
    default: return "Uncategorized";
  }
}
