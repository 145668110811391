import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

import { Button, Icon, Form as SemForm, Select } from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

import SemField from "../helpers/SemField";

import { analyticsAnswersPerUserPerDay, persistForm } from "../../actions";

class AnswerFilter extends Component {
  onSubmit = (values, actions) => {
    // console.log({...values, startDate: this.state.startDate.format().slice(0, 10), endDate: this.state.endDate.format().slice(0, 10) });
    const searchParams = { user_id: this.props.userId };
    if (values.preset_date === -2) {
    } else if (values.preset_date === -1) {
      searchParams.end_date = values.end_date;
      searchParams.start_date = values.start_date;
    } else {
      searchParams.start_date = values.preset_date;
      searchParams.end_date = new Date().toISOString().slice(0, 10);
    }
    console.log(searchParams);
    this.props.analyticsAnswersPerUserPerDay(searchParams);
    this.props.persistForm(this.props.form, values);
    actions.setSubmitting(false);
  };

  renderForm = ({ isSubmitting, resetForm, values, setFieldValue }) => {
    let options = [
      {
        key: 1,
        value: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
          .toISOString()
          .slice(0, 10),
        text: "This Month"
      },
      {
        key: 2,
        value: new Date(new Date().setDate(new Date().getDate() - 30))
          .toISOString()
          .slice(0, 10),
        text: "Last 30 Days"
      },
      {
        key: 3,
        value: new Date(new Date().setDate(new Date().getDate() - 60))
          .toISOString()
          .slice(0, 10),
        text: "Last 60 Days"
      },
      { value: -2, text: "All Time" },
      { value: -1, text: "Custom Date" }
    ];
    return (
      <Form autoComplete="off">
        <SemForm.Group inline>
          <SemField
            component={Select}
            name="preset_date"
            placeholder="Filter by time period"
            options={options}
          />
          {values.preset_date === -1 && (
            <React.Fragment>
              Start
              <DatePicker
                style={{ verticalAlign: "middle" }}
                name="start_date"
                inputValueFormat="YYYY-MM-DD"
                fixedHeight
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={e => setFieldValue("start_date", e)}
                selected={
                  values.start_date !== ""
                    ? moment(values.start_date, "YYYY-MM-DD")
                    : null
                }
                value={
                  values.start_date !== ""
                    ? values.start_date.format("YYYY-MM-DD")
                    : ""
                }
              />
            </React.Fragment>
          )}
          {values.preset_date === -1 && (
            <React.Fragment>
              End
              <DatePicker
                style={{ verticalAlign: "middle" }}
                name="end_date"
                inputValueFormat="YYYY-MM-DD"
                fixedHeight
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={e => setFieldValue("end_date", e)}
                selected={
                  values.end_date !== ""
                    ? moment(values.end_date, "YYYY-MM-DD")
                    : null
                }
                value={
                  values.end_date !== ""
                    ? values.end_date.format("YYYY-MM-DD")
                    : ""
                }
              />
            </React.Fragment>
          )}

          <Button
            icon
            type="submit"
            color="blue"
            floated="right"
            disabled={
              isSubmitting ||
              (values.preset_date === -1 &&
                (!values.end_date || !values.start_date))
            }
          >
            <Icon name="search" />
          </Button>

          <Button
            type="button"
            onClick={() => {
              resetForm(this.props.initialValues);
              this.props.analyticsAnswersPerUserPerDay({
                user_id: this.props.userId
              });
            }}
            floated="right"
          >
            Reset Filters
          </Button>
        </SemForm.Group>
      </Form>
    );
  };

  render() {
    const { persistedForm, initialValues } = this.props;
    return (
      <React.Fragment>
        <Formik
          initialValues={persistedForm || initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const initialValues = {
    preset_date: -1,
    end_date: moment(),
    start_date: moment()
  };
  return {
    initialValues,
    persistedForm: state.formik[ownProps.form] || null,
    userId: state.auth.userId
  };
}

const connectedForm = connect(
  mapStateToProps,
  { analyticsAnswersPerUserPerDay, persistForm }
)(AnswerFilter);

connectedForm.defaultProps = {
  form: "answerfilter"
};

export default connectedForm;

// reduxForm({ destroyOnUnmount: false, form: "answerfilter"})
// )(AnswerFilter);
