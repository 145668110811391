import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Header, Divider, Select } from "semantic-ui-react";
import { Formik, Form } from "formik";
import SemField from "../helpers/SemField";

import { userProfileFind, userProfileUpdate, authUpdate } from "../../actions";
import { skillLevels, skillTypes } from "../skill/skillUtils";

class DestroyExerciseFilterForm extends Component {
  // destroys filter component on exercise list page on logout,
  // so if another user logs back in again the initial values for the previous user will not override
  // new user's settings.
  render() {
    return <div />;
  }
}

class UserProfileEdit extends Component {
  state = { disableButton: false };

  componentDidMount() {
    this.props.userProfileFind({ user_id: this.props.userId });
  }

  onSubmit = (values, actions) => {
    this.setState({ disableButton: true });

    if (values.skill_type)
      this.props.preferences.skill_type = values.skill_type;
    if (values.level) this.props.preferences.level = values.level;

    console.log("Updating preferences with these values:", {
      user_id: this.props.userId,
      preferences: this.props.preferences
    });

    // updates user profile with form values. then, runs a callback which updates the global auth state,
    // then runs a callback to toggle the profile edit view back to profile view
    this.props.userProfileUpdate(
      { user_id: this.props.userId, preferences: this.props.preferences },
      () =>
        this.props.authUpdate(
          { preferences: this.props.preferences },
          this.props.toggleEdit
        )
    );
    actions.setSubmitting(false);
  };

  renderForm = ({ errors, status, touched, isSubmitting }) => {
    // console.log(this.props.userProfile);
    return (
      <Form>
        <Header as="h4" content="Default Skill Type" />
        <SemField
          component={Select}
          name="skill_type"
          placeholder="Select type..."
          options={skillTypes}
        />

        <Header as="h4" content="Default Level" />
        <SemField
          component={Select}
          label="Skill Level"
          name="level"
          placeholder="Select level..."
          options={skillLevels}
        />
        <Divider />

        <Button
          loading={this.state.disableButton}
          disabled={isSubmitting || this.state.disableButton}
          floated="right"
          type="submit"
          positive
        >
          Save Changes
        </Button>
      </Form>
    );
  };

  render() {
    const { initialValues } = this.props;
    return (
      <React.Fragment>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
        <Button onClick={this.props.toggleEdit}>Cancel</Button>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const userToEdit = state.userProfile["0"];
  const initialValues = {};

  if (userToEdit && userToEdit.preferences) {
    initialValues.skill_type = userToEdit.preferences.skill_type || null;
    initialValues.level = userToEdit.preferences.level || null;
  }

  return {
    userId: state.auth.userId,
    userProfile: state.userProfile,
    preferences: userToEdit.preferences,
    initialValues
  };
}

export default connect(
  mapStateToProps,
  { userProfileFind, userProfileUpdate, authUpdate }
)(UserProfileEdit);
