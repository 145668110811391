import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import skill from "./skill";
import question from "./question";
import exerciseActive from "./exerciseActive";
import exercise from "./exercise";
import auth from "./auth";
import userProfile from "./userProfile";
import subscription from "./subscription";
import analytics from "./analytics";
import scroll from "./scroll";
import gamification from "./gamification";
import lead from "./lead";
import formik from "./form";

export default combineReducers({
  skill,
  question,
  exerciseActive,
  exercise,
  analytics,
  auth,
  userProfile,
  subscription,
  form: formReducer,
  scroll,
  gamification,
  lead,
  formik // should really be form but was taken b/c of redux form. should refactor later
});
