import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ResetPasswordForm from "./ResetPasswordForm";
import redirectOnAuth from "./redirectOnAuth";
import url from "../../utils/routeUrls";
import { clearAuthErrorMsg } from "../../actions";

class ResetPassword extends Component {

  state = { resetted: false };

  componentDidMount() {
    const hash = this.props.match.params.hash;
    if (!hash) {
      this.props.history.push(url.SIGNIN);
    }
  }

  componentWillUnmount() {
    this.props.clearAuthErrorMsg(); // clears any error or misc messages still lingering in global state
  }

  chooseView() {
    if (!this.state.resetted)
      return <ResetPasswordForm hash={this.props.match.params.hash} callback={() => this.setState({ resetted: true })} />;
    else
      return <div>{this.props.message} <Link to={url.SIGNIN}>Sign In</Link></div>;
  }

  render() {
    return (
      <div>
        {this.chooseView()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { message: state.auth.miscMessage };
}

export default connect(mapStateToProps, { clearAuthErrorMsg })(redirectOnAuth(ResetPassword));