// export default {
//   AUTH_URL: "https://smilestein-server.herokuapp.com/api/auth",
//   SKILL_URL: "https://smilestein-server.herokuapp.com/api/skills",
//   QUESTION_URL: "https://smilestein-server.herokuapp.com/api/questions"
// }

//const BASE_URL = process.env.BASE_URL || "http://localhost:5001";
//const BASE_URL = process.env.BASE_URL || "https://api-staging.smilestein.com";
const BASE_URL = process.env.BASE_URL || "https://api-lrg.optimize100x.com";

export default {
  AUTH_URL: `${BASE_URL}/api/auth`,
  SKILL_URL: `${BASE_URL}/api/skills`,
  QUESTION_URL: `${BASE_URL}/api/questions`,
  ET_URL: `${BASE_URL}/api/exercise-templates`,
  EXERCISE_URL: `${BASE_URL}/api/exercises`,
  ANSWER_URL: `${BASE_URL}/api/answers`,
  USER_PROFILE_URL: `${BASE_URL}/api/user-profile`,
  SUB_URL: `${BASE_URL}/api/subscriptions`,
  ANALYTICS_URL: `${BASE_URL}/api/stats`,
  LEADS_URL: `${BASE_URL}/api`,
  LRG_LEADS_URL: `${BASE_URL}/api/lrg-leads`
};
