import React, { Component } from "react";
import Fade from "react-reveal/Fade";

export default class SiteFeatures extends Component {
  render() {
    return (
      <div>
        <section
          className="section-features js--section-features"
          id="features"
        >
          <div className="row">
            <h2>Enjoy Math &mdash; no more tears</h2>
            <p className="long-copy">
              Hello, we're Smilestein, your gamified math tutor. We know you
              love your child. No time to fight about math. So let us gamify it,
              we're really good at it, we promise!
            </p>
          </div>
          <Fade>
            <div className="row">
              <div className="col span-1-of-4 box">
                <i className="ion-ios-infinite-outline icon-big" />
                <h3>All math skills</h3>
                <p>
                  Never worry again! We cover the common core and all math
                  skills your school will test. Our subscription plans include
                  365 days/year updates, full analytics, and daily or weekly
                  reporting. We want to provide you and your child with
                  confidence from an early start or help to catch up and keep
                  up.
                </p>
              </div>
              <div className="col span-1-of-4 box">
                <i className="ion-ios-stopwatch-outline icon-big" />
                <h3>Personalized</h3>
                <p>
                  Every child thinks differently and learns differently. Our
                  machine learning algorithms support you in customizing the
                  learning experience. You're only twenty minutes away from a
                  learning experience that meets your and your child's needs. We
                  work with the best tutors in town to ensure that you're 100%
                  happy.
                </p>
              </div>
              <div className="col span-1-of-4 box">
                <i className="ion-ios-nutrition-outline icon-big" />
                <h3>Gamified</h3>
                <p>
                  Why should kids love video games and hate math? Meaningful
                  gamification can be personalized self-motivating for your
                  child. Once you can stop pushing, your child will love it even
                  more and you can just paise your little Einstein. Good for
                  your health, the environment, and life feels better!
                </p>
              </div>
              <div className="col span-1-of-4 box">
                <i className="ion-ios-cart-outline icon-big" />
                <h3>Transparent</h3>
                <p>
                  We don't limit your creativity. We guide with detailed
                  analytics and calculate effective learning options. You can
                  easily customize the plan, set your goals, and verify how the
                  system tracks results towards your learning objectives. It's
                  all up to you!
                </p>
              </div>
            </div>
          </Fade>
        </section>
      </div>
    );
  }
}
