import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Image, Icon } from "semantic-ui-react";
import { DragDropContext } from "react-beautiful-dnd";

import { exerciseActiveIndex, gamificationScoreSet, exerciseActiveFind } from "../../actions";
import DndArea from "./DndArea";
import { answerCreate } from "../../utils/api";

class QuestionOneMultDnd extends Component {

  state = { 
    questionIdCache: this.props.currentQuestion.question_id,
    optionDndObj: {}, // contains an object of options that are to be draggable.
    dndAreas: {
      "options": { id:"options", dndIds: [] },
      "answer": { id: "answer", dndIds: [] }
    },
    timer: null,
    wasCorrect: 0 // -1 = wrong, 0 = did not answer yet, 1 = right

  
  };

  updateDndState = () => {
    const optionDndObj = {};
    console.log(this.props.currentQuestion.options);
    _.forEach(this.props.currentQuestion.options, (opt, index) => {
      optionDndObj[`opt-${index}`] = { id: `opt-${index}`, content: opt };
      return;
    }) 
    
    // get ids from optionDndObj and create an array of them to initially populate the draggable option row
    let dndIds = [];
    _.forEach(optionDndObj, val => dndIds.push(val.id));

    
    const copy_dndAreas = { ...this.state.dndAreas, "options": { id: "options", dndIds }, "answer": { id: "answer", dndIds: []} };

    this.setState({ optionDndObj, dndAreas: copy_dndAreas, questionIdCache: this.props.currentQuestion.question_id, timer: Date.now() });
  }


  componentDidMount() {
    this.updateDndState();
  }

  componentDidUpdate() {
    if (this.state.questionIdCache === this.props.currentQuestion.question_id) 
      return;
    this.updateDndState();
  }

  answerCallback = (answer) => {
    // callback function to be passed into answerCreate. 
    // Updates the current active exercise state after answer endpoint is called by answerCreate.
    // exerciseActiveFind makes a callback after a delay of one second 
    // to reset the state then increment the active exercise index.
    this.props.gamificationScoreSet(answer.points, () =>
    this.props.exerciseActiveFind(this.props.exerciseId,
      () => _.delay(
        () =>  this.setState({ wasCorrect: 0 }, () => this.props.exerciseActiveIndex(this.props.activeIndex+1)), 1000)));
    
  }

  checkCorrect = draggableId => {
    // after user drags an item into the array, checks if is correct or not on client side
    _.forEach(this.state.optionDndObj, opt => {
      if (opt.id === draggableId) {
        if (this.props.currentQuestion.correct_answers.includes(opt.content)) {
          this.setState({ wasCorrect: 1 }, console.log("It was correct"));
        } else {
          this.setState({ wasCorrect: -1 }, console.log("It was wrong"));
        }
        return false; // equivalent of break
      }
      return;
    });
  }

  showQuestionResult = () => {
    // shows correct or wrong ui change when a user answers
    switch(this.state.wasCorrect) {
      case 1:
        return <img src={ require('../../assets/img/correct.png')} className="question-button-result"/>
      case -1:
        return <img src={ require('../../assets/img/wrong.png') } className="question-button-result"/>
      default:
        return null;
    }
  }

  onDragEnd = result => {

    document.body.style.color = "inherit";
    // see video 5 on egghead.io
    // after drag click is released, passes in a specific result object.
    const { destination, source, draggableId } = result;

    // no destination, terminate callback
    if (!destination) {
      return;
    }
    // if the index or the source was the same, terminate callback since no state change
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state.dndAreas[source.droppableId];
    const finish = this.state.dndAreas[destination.droppableId]; // necessary for multi column support (egghead 9)

    if (start === finish) { // same list
      const newDndIds = Array.from(start.dndIds); // clone array so original is not mutated
      newDndIds.splice(source.index, 1); // splice mutates the array. from this index, remove 1
      newDndIds.splice(destination.index, 0, draggableId); // remove nothing from destination, but insert draggable id

      const newDndArea = { ...start, dndIds: newDndIds };

      const newState = {
        ...this.state, dndAreas: {...this.state.dndAreas, [newDndArea.id]: newDndArea} // [newColumn.id] overrides the old column id with new data
      };

      this.setState(newState);
      return;
    } else { // from one list to another

      const startDndIds = Array.from(start.dndIds);
      startDndIds.splice(source.index, 1);
      const newStart = {
        ...start, dndIds: startDndIds
      };

      const finishDndIds = Array.from(finish.dndIds);
      finishDndIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish, dndIds: finishDndIds
      };

      const newState = {
        ...this.state, dndAreas: { ...this.state.dndAreas, [newStart.id]: newStart, [newFinish.id]: newFinish }
      };
      this.setState(newState);

      let timing = Date.now() - this.state.timer;

      this.checkCorrect(draggableId);

      _.forEach(this.state.optionDndObj, opt => {
        if (opt.id === draggableId) {
          answerCreate({ 
            question_id: this.props.currentQuestion.question_id, 
            exercise_id: this.props.exerciseId, 
            selected_options: [opt.content], 
            timing 
          },  this.answerCallback);
          return false; // equivalent of break
        }
        return;
      });

      
    }
  }

  render() {
    const question = this.props.currentQuestion;
    if (!question)
      return <div />;


    // console.log(this.state.dndAreas.answer);
    return (
      <div key={question.question_id}>
        <div className="question-drop-context">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div>
              <DndArea className="question-drop-target" area={this.state.dndAreas.answer} options={this.state.dndAreas.answer.dndIds.map(optId => this.state.optionDndObj[optId])} />
              <DndArea className="question-drop-source" area={this.state.dndAreas.options} options={this.state.dndAreas.options.dndIds.map(optId => this.state.optionDndObj[optId])} />
            </div>
          </DragDropContext>
        </div>
        <div>
          {this.showQuestionResult()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const active = state.exerciseActive;
  return { 
    activeIndex: active.activeIndex, 
    currentQuestion: active.activeExercise.questions[active.activeIndex] || null,
    exerciseId: active.activeExercise.exercise_id
  };
}

export default connect(mapStateToProps, { gamificationScoreSet, exerciseActiveIndex, exerciseActiveFind })(QuestionOneMultDnd);