/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import ProgressBar from "../question/ProgressBar";
import Score from "../question/Score";
import QuestionOneMult from "../question/QuestionOneMult";
import QuestionOneMultDnd from "../question/QuestionOneMultDnd";
import QuestionMult from "../question/QuestionMult";
import QuestionMultDnd from "../question/QuestionMultDnd";
import Fullscreen from "react-full-screen";
import {
  exerciseActiveIndex,
  exerciseActiveFind,
  goFullScreen
} from "../../actions";

import url from "../../utils/routeUrls";
import VerticalProgressBar from "./VerticalProgressBar";
import "../../css/question.css";

class QuestionFrame extends Component {
  selectQuestionComponent(questionType) {
    // selects correct component to display question
    switch (questionType) {
      case 1:
        return <QuestionOneMult />;
      case 2:
        return <QuestionMult />;
      case 3:
        return <QuestionOneMultDnd />;
      case 4:
        return <QuestionMultDnd />;
      default:
        return <div>Invalid Question Type</div>;
    }
  }

  //<div onClick={() => this.props.history.push(url.EXERCISE_LIST)} ><img className="question-navigation-icons" src={ require('../../assets/img/full.png') } /></div>

  render() {
    const question = this.props.currentQuestion;
    const exercise = this.props.currentExercise;

    if (!question) return <div />;

    return (
      <Fullscreen enabled={this.props.fullScreen}>
        <div className="section-question" key={question.question_id}>
          <div className="question-frame" id="question-frame">
            <div className="row" className="question-header">
              <div className="col span-1-of-4 question-preferences">
                <div onClick={() => this.props.history.push(url.USER_PROFILE)}>
                  <img
                    className="question-navigation-icons"
                    src={require("../../assets/img/setting.png")}
                  />
                </div>
              </div>
              <div className="col span-2-of-4 question-category">
                {exercise.name}
              </div>
              <div className="col span-1-of-4 question-exit">
                <ul className="question-right-nav">
                  <li>
                    <Score />
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() =>
                        this.props.history.push(url.EXERCISE_WORLDS)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/x.png")}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row question-what-to-do" />
            <div className="row question-text">
              <p>{question.text}</p>
              <p>
                {question.image_url && (
                  <Image
                    src={question.image_url}
                    size="medium"
                    centered
                    bordered
                  />
                )}
              </p>
            </div>
            <div className="row question-buttons">
              {this.selectQuestionComponent(
                this.props.currentQuestion.question_type
              )}
            </div>
            {/*<div className="row question-progress-bar">
                    <ProgressBar />
                  </div>
                */}
            <div className="vertical-bar-frame">
              <VerticalProgressBar />
            </div>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];

  const active = state.exerciseActive;
  return {
    points: state.userProfile.points,
    fullScreen: state.userProfile.fullScreen,
    activeIndex: active.activeIndex,
    currentQuestion:
      active.activeExercise.questions[active.activeIndex] || null,
    exerciseId: active.activeExercise.exercise_id,
    currentExercise: active.activeExercise
  };
}

export default connect(
  mapStateToProps,
  { goFullScreen, exerciseActiveIndex, exerciseActiveFind }
)(withRouter(QuestionFrame));
