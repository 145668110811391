import React, { Component } from "react";

//https://www.ibm.com/privacy/us/en/#your_rights
export default class PrivacyList extends Component {
  render() {
    return (
      <div>
        <div className="privacy-header">
          This Privacy Statement is effective as of October 2018.
        </div>
        <div className="privacy-paragraph">
          At Smilestein we recognize the importance of protecting your personal
          information and are committed to processing it responsibly and in
          compliance with applicable data protection laws in all countries in
          which we operate. This Privacy Statement describes our general privacy
          practices that apply to personal information we collect, use and share
          about consumers and other individuals within our clients, business
          partners, supplier and other organizations with which we have or
          contemplate a business relationship. This Privacy Statement is
          supplemented by the our +++Online Privacy Statement+++, which provides
          more information in the online context, including recruitment. We may
          provide additional or more specific information on the collection or
          use of personal information on websites or related to a specific
          product or service.
        </div>
        <div className="privacy-header">
          Why and how we collect and use your personal information
        </div>
        <div className="privacy-paragraph">
          We may collect your personal information as an individual for various
          purposes, such as the following:
          <br />• Access and use of websites or other online services: When
          entering one of our websites, or using an online service, we will
          record information necessary to provide you with access, for the
          operation of the website and for us to comply with security and legal
          requirements in relation to operating our site, such as passwords, IP
          address and browser settings. We also collect information about your
          activities during your visit in order to personalize your website
          experience, such as recording your preferences and settings, and to
          collect statistics to help us improve and further develop our
          websites, products and services.
          <br />• Responding to your request for information, order, or support:
          When you contact us (online or offline) in connection with a request
          for information, to order a product or service, to provide you with
          support, or to participate in a forum or other social computing tool,
          we collect information necessary to fulfill your request, to grant you
          access to the product or service, to provide you with support and to
          be able to contact you. For instance, we collect your name and contact
          information, details about your request and your agreement with us and
          the fulfillment, delivery and invoicing of your order and we may
          include client satisfaction survey information. We retain such
          information for administrative purposes, defending our rights, and in
          connection with our relationship with you. When you provide your name
          and contact information to register in connection with such a request,
          the registration may serve to identify you when you visit our
          websites. For ordering of most services and products we require you to
          have registered. Registration may also allow you to customize and
          control your privacy settings.
          <br />• Your use of our cloud services: We collect information about
          your use of our cloud services to enable product features to operate,
          improve your user experience, tailor our interactions with you, inform
          our clients on the overall use of the services, provide support and
          improve and develop our products and services. For details regarding
          the technologies we employ, the personal information we collect, as
          well as how to control or block tracking or to delete cookies, please
          refer to the Online Privacy Statement.
          <br />• Contacting employees of our clients, prospects, partners and
          suppliers: In our relationship with clients or prospects, partners and
          suppliers, they also provide us with business contact information
          (such as name, business contact details, position or title of their
          employees, contractors, advisors and authorized users) for purposes
          such as contract management, fulfillment, delivery of products and
          services, provision of support, invoicing and management of the
          services or the relationship.
          <br />• Visitor information: We register individuals visiting our
          sites and locations (name, identification and business contact
          information) and use camera supervision for reasons of security and
          safety of persons and belongings, as well as for regulatory purposes.
          <br />• Marketing: Most information we collect about you comes from
          our direct interactions with you. When you register for an event we
          may collect information (online or offline) in relation to the event
          organization, and during an event, such as participation in sessions
          and survey results. We combine the personal information we collect to
          develop aggregate analysis and business intelligence for conducting
          our business and for marketing purposes. You can choose to receive
          information by email, telephone or postal mail about our products and
          services, or sign-up for subscriptions. When visiting our websites or
          using our services we may provide you with personalized information.
          You can always opt-out from receiving personalized communication by
          sending an e-mail to privacy@smilefish.com. More information can be
          found in the Online Privacy Statement. Where we reference that we use
          your personal information in connection with a request, order,
          transaction or agreement (or preparing for the same), or to provide
          you with services that you requested (such as a website), we do this
          because it is necessary for the performance of an agreement with you.
          Where we reference that we use your personal information in relation
          to marketing, improvement or development of our products or services,
          for reasons of safety and security, or regulatory requirements other
          than in connection with your agreement or request, we do this on the
          basis of our or a third party’s legitimate interests, or with your
          consent. When we collect and use your personal information subject to
          the EU Privacy Legislation this may have consequences for Your Rights.
        </div>
        <div className="privacy-header">Sharing of Personal Information</div>
        <div className="privacy-paragraph">
          As a global organization offering a wide range of products and
          services, with business processes, management structures and technical
          systems that cross borders, we have implemented global policies, along
          with standards and procedures, for consistent protection of personal
          information. As a global company, we may share information about you
          with our subsidiaries world-wide and transfer it to countries in the
          world where we do business in accordance with this Privacy Statement.
          Between our controlled subsidiaries we only grant access to personal
          information on a need-to-know basis, necessary for the purposes for
          which such access is granted. In some cases, we use suppliers located
          in various countries to collect, use, analyze, and otherwise process
          personal information on its behalf. Where appropriate, we may also
          share your personal information with selected partners to help us
          provide you, or the company you work for, products or services, or to
          fulfill your requests, or with your consent. When selecting our
          suppliers and partners, we take into account their data handling
          processes. If we decide to sell, buy, merge or otherwise reorganize
          businesses in some countries, such a transaction may involve the
          disclosure of personal information to prospective or actual
          purchasers, or the receipt of such information from sellers. It is our
          practice to require appropriate protection for personal information in
          these types of transactions. Please be aware that in certain
          circumstances, personal information may be subject to disclosure to
          government agencies pursuant to judicial proceeding, court order, or
          legal process. We may also share your personal information to protect
          our rights or property, our business partners, suppliers or clients,
          and others when we have reasonable grounds to believe that such rights
          or property have been or could be affected.
        </div>
        <div className="privacy-header">International Transfers</div>
        <div className="privacy-paragraph">
          The international operation involves a large number of transfers of
          personal information between different subsidiaries, as well as to
          third parties located in the countries where we might ndo business.
          Some countries have implemented transfer restrictions for personal
          information, in connection with which we take various measures,
          including:
          <br />• Where required, we implement Standard Contractual Clauses
          approved by the EU Commission, or similar contractual clauses in other
          jurisdictions. This includes transfers to suppliers or other third
          parties. You can request a copy of the EU Standard Contractual Clauses
          here.
          <br />• We adhere to the APEC Cross Border Privacy Rules.
          <br />• We adhere to the EU-U.S. and Swiss-U.S. Privacy Shield
          Frameworks.
        </div>
        <div className="privacy-header">Information Security and Accuracy</div>
        <div className="privacy-paragraph">
          We intend to protect your personal information and to maintain its
          accuracy. We implement reasonable physical, administrative and
          technical safeguards to help us protect your personal information from
          unauthorized access, use and disclosure. For example, we encrypt
          certain sensitive personal information such as credit card information
          when we transmit such information over the Internet. We also require
          that our suppliers protect such information from unauthorized access,
          use and disclosure. Retention Period We will not retain personal
          information longer than necessary to fulfill the purposes for which it
          is processed, including the security of our processing complying with
          legal and regulatory obligations (e.g. audit, accounting and statutory
          retention terms), handling disputes, and for the establishment,
          exercise or defense of legal claims in the countries where we do
          business. Because the circumstances may vary depending on the context
          and the services, the information provided in Online Privacy Statement
          or provided in a specific notice may provide more detailed information
          on applicable retention terms.
        </div>
        <div className="privacy-header">How to contact us</div>
        <div className="privacy-paragraph">
          If you have a question related to this Privacy Statement, please
          contact us by using the contact form. Your message will be forwarded
          to the appropriate member of our Data Privacy Team, such as Data
          Protection Officers or members of their teams. For the purposes of the
          EU General Data Protection Regulation (GDPR), the controller of your
          personal information is Smilefish Corporation, Newport Beach CA 92660,
          unless indicated otherwise.
        </div>
        <div className="privacy-header">Your Rights</div>
        <div className="privacy-paragraph">
          You can request to access, update or correct your personal
          information. You also have the right to object to direct marketing.
          You can access the request process here. You may have additional
          rights pursuant to your local law applicable to the processing. For
          example, if the processing of your personal information is subject to
          the EU General Data Protection Regulation ("GDPR"), and your personal
          information is processed based on legitimate interests, you have the
          right to object to the processing on grounds relating to your specific
          situation. Under GDPR you may also have the right to request to have
          your personal information deleted or restricted and ask for
          portability of your personal information. Right to Lodge a Complaint
        </div>
        <div className="privacy-paragraph">
          In the event you consider our processing of your personal information
          not to be compliant with the applicable data protection laws, you can
          lodge a complaint:
        </div>
        <div className="privacy-paragraph">
          <br />• Directly with Smilestein by using the contact form.
          <br />• With the competent data protection authority. The name and
          contact details of the Data Protection Authorities in the European
          Union can be found here.
        </div>
        <div className="privacy-header">Changes to our Privacy Statements</div>
        <div className="privacy-paragraph">
          From time to time we may update this Privacy Statement, as well as any
          other specific privacy statement. When making changes to this Privacy
          Statement, we will add a new date at the top of this Privacy
          Statement.
        </div>
        <div className="privacy-header">------</div>
        <div className="privacy-header">
          This Online Primary Statement is effective as of May 2018.
        </div>
        <div className="privacy-header">Changes to our Privacy Statements</div>
        <div className="privacy-paragraph">
          Your privacy is important to us; maintaining your trust is paramount
          to us. This Statement discloses the information practices for our and
          our subsidiaries' websites, what type of information about our
          websites' users is gathered and tracked, and how the information is
          used, shared or otherwise processed offline. It also describes how
          cookies, web beacons and other technologies may be used in our cloud
          services and software products. References to our websites, products
          and services in this Privacy Statement include apps, programs, and
          devices.
        </div>
        <div className="privacy-paragraph">
          This Online Privacy Statement supplements the Privacy Statement. This
          Statement applies to our websites that link to this Statement but does
          not apply to those websites that have their own Privacy Statement.
          Additionally, it does not apply to instances where we merely process
          information on behalf of clients for their benefit, such as when we
          act as a web hosting service provider. We may supplement this
          Statement with additional information relating to a particular
          interaction we have with you, such as a transaction document or a
          specific privacy statement when you order or use an online service.
          Our privacy practices as it pertains to information collected through
          our websitge, as described in this Privacy Statement, comply with the
          APEC Cross Border Privacy Rules System. The APEC CBPR system provides
          a framework for organizations to ensure protection of personal
          information transferred among participating APEC economies. More
          information about the APEC framework can be found here (PDF, 87 KB).
          If you have questions or complaints regarding our privacy policy or
          practices, please contact our we site coordinator. If you have an
          unresolved privacy or data use concerns that we have not addressed
          satisfactorily, please contact our U.S.-based third party dispute
          resolution provider (free of charge) here.
        </div>
        <div className="privacy-header">Collection of Personal Information</div>
        <div className="privacy-paragraph">
          Use of Personal Information
          <br />
          Retention
          <br />
          Cookies, Web Beacons and Other Technologies
          <br />
          Online Advertising
          <br />
          Links to external Websites and Third-Party Applications
          <br />
          Notification of Changes
          <br />
          Privacy Questions and Access
        </div>
        <div className="privacy-header">Collection of Personal Information</div>
        <div className="privacy-header">Introduction</div>
        <div className="privacy-paragraph">
          You may choose to give us personal information directly in a variety
          of situations. For example, you may want to give us your name and
          contact information to communicate with you, to order a product, to
          process an order, to register for a service, to provide you with a
          subscription, or to do business with us if you are a supplier or a
          business partner. You may also provide your credit card details to buy
          something from us or may share a description of your education and
          work experience in connection with a job opening for which you wish to
          be considered. If you tell us that you do not want us to use your
          information to make further contact with you beyond fulfilling your
          request, we will respect your wishes.
        </div>
        <div className="privacy-header">Registration</div>
        <div className="privacy-paragraph">
          When you wish to receive information, download publications, enroll
          for a live or virtual event or request a trial, we may ask you to
          provide your name and business contact information, as well as other
          information in connection with your request. We use this information
          in connection with your request and to communicate with you. This
          information may, in most cases, also identify you when you visit our
          websites. We will retain your information for potential future
          interactions with you. We may also ask you to register an AccountID,
          in which case you need to provide us with your name, email address,
          country location and other information if needed for the purpose for
          which you are asked to register. The AccountID serves to uniquely
          identify you when you visit our websites, have a request or order or
          use a product or service. For ordering of most services and products
          we require you to have registered an AccountID. Registration with
          AccountID may allow you to customize and control your privacy
          settings. Certain products and services may require unique
          registration specifically for the purpose of providing these products
          or services. In such cases, registration details provided by you for a
          product or service will only be used for the specific purpose of
          providing you with that product or service.
        </div>
        <div className="privacy-header">Website visits and cloud services</div>
        <div className="privacy-paragraph">
          We may also collect information relating to your use of our websites
          and cloud services through the use of various technologies. For
          example, when you visit our websites or access our cloud services, we
          may log certain information that your browser sends us, such as your
          IP address (including information deriving from your IP address such
          as your geographic location), browser type, version and language,
          access time, duration of access, and referring website addresses; we
          may also collect information about the pages you view within our
          sites, the time you spent on each site and other actions you take
          while visiting our website. When you access our website without an
          AccountID or signing in, we will still collect some of this personal
          information in order to enable the websites to function properly. In
          addition, some of our software products and cloud services include
          technologies that allow us to collect certain information about the
          use of our products and services. We may also use such technologies to
          determine whether you've opened an e-mail or clicked on a link
          contained in an e-mail. For details regarding the technologies we
          employ, see Cookies, Web Beacon and Other Technologies below.
        </div>
        <div className="privacy-header">Marketing information</div>
        <div className="privacy-paragraph">
          Most information we collect about you comes from our direct
          interactions with you. From time to time, we may also collect
          information that pertains to you indirectly through other sources,
          such as list vendors. When we do so, we ask the vendors to confirm
          that the information was legally acquired by the third party and that
          we have the right to obtain it from them and use it. When you register
          for an event we may collect additional information (online or offline)
          in relation to the event organization, and during an event, such as
          participation in sessions and survey results. Events may be recorded
          and group photos taken. We reserve the right to use photos for
          promotional use. When you provide us with your business contact
          information (such as by handing over a business card) we may use this
          to communicate with you. The information that we collect, either
          directly or indirectly, may be combined to help us improve its overall
          accuracy and completeness, and to help us better tailor our
          interactions with you. Back to top
        </div>
        <div className="privacy-header">Use of Personal Information</div>
        <div className="privacy-paragraph">
          The following paragraphs describe in more detail how we may use your
          personal information.
        </div>
        <div className="privacy-header">
          Fulfilling your Transaction Request
        </div>
        <div className="privacy-paragraph">
          If you request something from us, for example, a product or service, a
          callback, or specific marketing materials, we will use the personal
          information you provide to fulfill your request. To help us do this,
          we may share information with others, for instance, our business
          partners, financial institutions, shipping companies, postal or
          government authorities, such as customs authorities, involved in
          fulfillment. We may also contact you as part of our customer
          satisfaction surveys or for market research purposes. Personalizing
          your Experience on our Websites We may use the personal information we
          collect about you to provide you with a personalized experience on our
          websites and of our services, such as providing you with content you
          may be interested in and making navigation on our sites easier. We
          also may use this information to improve our websites. Providing
          Support We may use your personal information to support products or
          services you have obtained from us, such as notifying you of a product
          update or fix. We may combine your information with information from
          other interactions with you to provide you with more valuable
          suggestions in relation to product support. We also provide "Live
          Chat" sessions on our websites to assist you while you're navigating
          through our sites or forums where problems can be raised and solutions
          proposed; we will use personal information you provide in these
          sessions or in such forums in accordance with this Privacy Statement.
          In the course of providing technical support to you, we may sometimes
          have incidental access to data that you have provided to us or data
          that is located on your system. This data may contain information
          about you, your organization's employees, customers, partners, or
          suppliers. This Privacy Statement does not apply to our access to or
          handling of this personal information; the conditions regarding the
          handling and processing of this data is covered by the applicable
          Terms of Use or other agreements between you and us, such as the Terms
          of Use for Exchanging Diagnostic Data. <br />
          Cloud services: We may use information collected on your use of the
          cloud services to allow us to: establish statistics about the usage
          and effectiveness of our software products and our cloud services;
          improve and personalize your use and experience of our products and
          services; tailor our interactions with you; inform our clients on
          overall use of their products and services, and improve and develop
          our products and services.
          <br />
          Marketing: The personal information you provide to us, as well as the
          personal information we have collected about you indirectly, may be
          used by us for marketing purposes, i.e, to keep you informed about
          events, products, services and solutions that we sell and which may
          complement an existing product portfolio. Before we do so, however, we
          will offer you the opportunity to choose whether or not to have your
          personal information used in this way. You may at any time choose not
          to receive marketing materials from us by following the unsubscribe
          instructions included in each e-mail you may receive, by indicating so
          when we call you, or by contacting us directly (please refer to
          "Privacy Questions and Access" below). Some of our offerings or events
          may be co-branded or sponsored by us and third parties, such as
          business partners and solution providers, that use, resell or
          complement our products or services. Offerings and events that are
          co-branded clearly indicate when such partnership exists. If you sign
          up for these offerings or events, be aware that your information may
          also be collected by and shared with those third parties. We encourage
          you to familiarize yourself with those third-party privacy policies to
          gain an understanding of the manner in which they will handle
          information about you. If you choose to "Email This Page" to a friend
          or colleague, we will ask for their name and e-mail address. We will
          automatically send a one-time e-mail sharing the page you indicated,
          but will not use that information for other purposes. Recruitment In
          connection with a job application or inquiry, whether advertised on a
          website or otherwise, you may provide us with information about
          yourself, such as a resume. We may use this information throughout our
          company in order to address your inquiry or consider you for
          employment purposes.
          <br />
          Monitoring or Recording of Calls, Chats and Other Interactions:
          Certain online transactions may involve you calling us or us calling
          you. They may also involve online chats. Please be aware that it is
          our general practice to monitor and, in some cases, record such
          interactions for staff training or quality assurance purposes or to
          retain evidence of a particular transaction or interaction.
          <br />
          Use of Information in the Social Computing Environment: We provide
          social computing tools on some of our websites to enable online
          sharing and collaboration among members who have registered to use
          them. These include forums, wikis, blogs and other social media
          platforms. When downloading and using these applications or
          registering to use these social computing tools, you may be asked to
          provide certain personal information. Registration information will be
          subject to and protected in accordance with this Privacy Statement,
          except for the information that is automatically made available to
          other participants as part of your profile. These applications and
          tools may also include supplemental privacy statements with specific
          information about collection and handling practices. Read those
          supplemental statements to understand what the tools and applications
          may do. Any other content you post, such as pictures, information,
          opinions, or any other type of personal information that you make
          available to other participants on these social platforms or
          applications, is not subject to this Privacy Statement. Rather, such
          content is subject to the Terms of Use of those applications or
          platforms, and any additional guidelines and privacy information
          provided in relation to their use, as well as the process by which you
          can remove your content from such tools or get help to do so. Please
          refer to them to better understand yours, ours, and other parties'
          rights and obligations with regard to such content. You should be
          aware that the content you post on any such social computing platforms
          may be made broadly available to others inside and outside of our
          company.
          <br />
          Protect our Rights and Property the Rights and Property of Others: We
          may also use or share your personal information to protect our rights
          or property, our business partners, suppliers, clients, or others when
          we have reasonable grounds to believe that such rights or property
          have been or could be affected. In addition, we reserve the right to
          disclose your personal information as required by law, and when we
          believe that disclosure is necessary to protect our rights, or the
          rights of others, to comply with a judicial proceeding, court order,
          or legal process served on our websites.
          <br />
          Information for Business Partners: If you represent a business
          partner, you may visit our websites intended specifically for business
          partners. We may use information provided on that site to administer
          and develop our business relationship with you, the business partner
          you represent, and our business partners generally. For instance, this
          may involve using your information to send you details of our business
          partner programs. It may also include sharing certain information with
          other business partners (subject to any confidentiality obligations
          that may exist), or our clients or prospects. In connection with a
          particular transaction or program, we may also contact you as part of
          client satisfaction surveys or for market research purposes.
          <br />
          Information for Suppliers: If you represent a supplier, you may visit
          our websites intended specifically for use by Our suppliers. We may
          use the information provided on that site in connection with entering
          into or performing a transaction with you. For example, this may
          include sharing information with other parts of our comany, our
          business partners, clients, shipping companies, financial institutions
          and postal or government authorities involved in fulfillment. It may
          also be used to administer and develop our relationship with you, the
          supplier you represent, and other suppliers generally.
          <br />
          Children: Unless otherwise indicated, our websites, products and
          services are not intended for use by children under the age of 16.
        </div>
        <div className="privacy-header">Retention</div>
        <div className="privacy-paragraph">
          We will retain your personal information for as long as is required to
          fulfill the purposes for which the information is processed or for
          other valid reasons to retain your personal information (for example
          to comply with our legal obligations, resolve disputes, or enforce our
          agreements). Your registration information will be retained for as
          long as your account or AccountID is active or as needed to provide
          you services. If you wish to request that we no longer use your
          registration information to provide you services, contact us on our
          website. In relation to our cloud services which you are authorized to
          use by the client contracting for that service with us, your
          registration information may be retained to fulfill certain
          requirements of that client.
        </div>
        <div className="privacy-header">
          Cookies, Web Beacon and Other Technologies
        </div>
        <div className="privacy-paragraph">
          As mentioned above, we collect information from your visits to our
          websites and your use of our cloud services and our software products
          to help us gather statistics about usage and effectiveness,
          personalize your experience, tailor our interactions with you, and
          improve our products and services. We do so through the use of various
          technologies, including scripts, tags, Local Shared Objects (Flash
          cookies), Local Storage (HTML5) beacons, and "cookies". What are
          cookies and why are cookies used
        </div>
        <div className="privacy-paragraph">
          A cookie is a piece of data that a website can send to your browser,
          which may then be stored on your computer as a tag that identifies
          your computer. While cookies are often only used to measure website
          usage (such as number of visitors and duration of visit) and
          effectiveness (such as topics visitors are most interested in) and to
          allow for ease of navigation or use and, as such, are not associated
          with any personal information, they are also used at times to
          personalize a known visitor's experience of a website by being
          associated with profile information or user preferences. Over time
          this information provides valuable insight to help improve the user
          experience. Cookies are typically categorized as "session" cookies or
          "persistent" cookies. Session cookies help you navigate through the
          website efficiently, keeping track of your progression from page to
          page so that you are not asked for information you have already
          provided during the current visit, or information needed to be able to
          complete a transaction. Session cookies are stored in temporary memory
          and erased when the web browser is closed. Persistent cookies on the
          other hand, store user preferences for current and successive visits.
          They are written on your device's hard disk, and are still valid when
          you restart your browser. We use persistent cookies, for example, to
          record your choice of language and country location. How to express
          privacy preferences regarding the use of cookies
        </div>
        <div className="privacy-paragraph">
          When visiting our websites or online services, you may have the
          possibility to set your preferences regarding cookies and other
          similar technologies by using the options and tools made available to
          you by either your web browser or us. If a cookie manager has been
          implemented by us, it will be displayed at your first visit to our
          website. By clicking 'cookie preferences' at the footer of an our
          website you can choose and change at any time which types of cookies
          you would like our websites and cloud services to use. You can
          indicate your preference for required, required and functional or
          required, functional and personalization cookies. Here you can also
          find more information on the types of cookies used. Using the cookie
          preferences does not result in deletion of cookies that are already on
          your device. You can delete cookies already on your device via the
          settings of your web browser. While our websites, online and cloud
          services at this time do not recognize automated browser signals
          regarding tracking mechanisms, such as "do not track" instructions,
          you can generally express your privacy preferences regarding the use
          of most cookies and similar technologies through your web browser.
          Look under the heading "Tools" (or similar heading) in your particular
          browser for information about controlling cookies. In most instances
          you can set your browser to notify you before you receive a cookie,
          giving you the option to decide whether to accept it or not. You can
          also generally set your browser to turn off cookies. Cookies in our
          software products can be turned off in the product itself. Since
          cookies allow you to take advantage of some of our websites' features
          or features of our software products and cloud services, we recommend
          that you leave them turned on. If you block, turn off or otherwise
          reject our cookies, some web pages may not display properly or you
          will not be able to, for instance, add items to your shopping cart,
          proceed to checkout, or use any website or cloud services that require
          you to sign in. Web beacons or other technologies
        </div>
        <div className="privacy-paragraph">
          Some of our websites, cloud services and software products may also
          use web beacons or other technologies to better tailor those sites to
          provide better customer service. These technologies may be in use on a
          number of pages across or websites. When a visitor accesses these
          pages, a non-identifiable notice of that visit is generated which may
          be processed by us or by our suppliers. These web beacons usually work
          in conjunction with cookies. If you don't want your cookie information
          to be associated with your visits to these pages or use of these
          products, you can set your browser to turn off cookies or turn off
          cookies in the product itself, respectively. If you turn off cookies,
          web beacon and other technologies will still detect visits to these
          pages; however, they will not be associated with information otherwise
          stored in cookies. For more information about the technologies
          employed by our cloud services and software products, including how to
          turn them off, please consult the user guide for the particular
          software product or cloud services you are using. For information
          about how our clients may use cookies and other technologies in their
          own implementation of our software products and cloud services, click
          here. We use Local Shared Objects, such as Flash cookies, and Local
          Storage, such as HTML5, to store content information and preferences.
          Third parties with whom we partner to provide certain features on our
          website or to display our advertising on others' websites based upon
          your web browsing activity also use Flash cookies or HTML5 to collect
          and store information. Various browsers may offer their own management
          tools for removing HTML5. To manage Flash cookies, please click here .
          We may also include web beacons in marketing e-mail messages or our
          newsletters in order to determine whether messages have been opened
          and links contained within clicked on. Some of our business partners
          set web beacons and cookies on our site. In addition, third-party
          social media buttons may log certain information such as your IP
          address, browser type and language, access time, and referring website
          addresses, and, if you are logged in to those social media sites, they
          may also link such collected information with your profile information
          on that site. We do not control these third party tracking
          technologies.
        </div>
        <div className="privacy-header">Online Advertising</div>
        <div className="privacy-paragraph">
          We do not deliver third party online advertisements on our web sites
          but we advertise our products and services on others' websites. Please
          familiarize yourself with those website operators' or network
          advertisers' privacy policies to understand their practices relating
          to advertising, including what type of information they may collect
          about your Internet usage. Some advertising networks we may use may be
          members of the Network Advertising Initiative (NAI) or the European
          Interactive Digital Advertising Alliance (EDAA). Individuals may
          opt-out of targeted advertising delivered by NAI or EDAA member ad
          networks by using tools provided visiting the following sites:
          <br />
          Digital Advertising Alliance (DAA)
          <br />
          Network Advertising Initiative (NAI)
          <br />
          European Interactive Digital Advertising Alliance (EDAA)
        </div>
        <div className="privacy-paragraph">
          We have also engaged with certain third parties to manage some of our
          advertising on other sites. These third parties may use cookies and
          web beacons to collect information (such as your IP address) about
          your activities on our and others' websites to provide you targeted
          advertisements based upon your interests: In the U.S. and Canada,
          those advertisements that are targeted to you will be identified with
          the AdChoices icon, as we adhere to participate in the Digital
          Advertising Alliance self-regulatory program for online behavioural
          advertising (see youradchoices.com and youradchoices.ca). You can
          click on the icon in the advertisements themselves to manage your
          preferences. Or, if you wish to not have this information used for the
          purpose of serving you targeted ads, you may opt-out by clicking here.
          Please note that this does not opt you out of being served
          non-targeted advertising. You will continue to receive generic,
          non-targeted ads. Back to top
        </div>
        <div className="privacy-header">
          Links to external Websites and Third-Party Applications
        </div>
        <div className="privacy-paragraph">
          To allow you to interact with other websites on which you may have
          accounts (such as Facebook and other social media sites) or join
          communities on such sites, we may provide links or embed third-party
          applications that allow you to login, post content or join communities
          from our websites. We may also provide you with general links to
          external websites.
        </div>
        <div className="privacy-paragraph">
          Your use of these links and applications is subject to the third
          parties' privacy policies, and you should become familiar with the
          third-party sites' privacy policies before using the links or
          applications. We are not responsible for the privacy practices or the
          content of those other websites.
        </div>
        <div className="privacy-header">Notification of Changes</div>
        <div className="privacy-paragraph">
          If we make any material changes to this Privacy Statement, we will
          notify you via notification on our website, and as otherwise required
          by applicable law. Back to top
        </div>
        <div className="privacy-header">Privacy Questions and Access</div>
        <div className="privacy-paragraph">
          If you have a question about this Privacy Statement or our handling of
          your information, you can send an email to our website coordinator.
          You can also contact us via our contact forms.
        </div>
        <div className="privacy-paragraph">
          In certain cases, you may have the ability to view or edit your
          personal information online. In the event your information is not
          accessible online, and you wish to obtain a copy of particular
          information you provided to us, or if you become aware the information
          is incorrect and you would like us to correct it, contact us. More
          information on Your Rights can be found here. If you no longer wish to
          receive marketing e-mails from us, pleas follow the unsubscribe
          instructions included in each marketing email.
        </div>
      </div>
    );
  }
}
