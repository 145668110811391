import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "semantic-ui-react";

import requireAuth from "../auth/requireAuth";
import AnswerList from "./AnswerList";
import { Container } from "../../styles";
import AnswerColumnGraph from "./AnswerColumnGraph";
import AnswerFilter from "./AnswerFilter";

class Dashboard extends Component {

  render () {
    return (
      <div>
        <Menu>
          <Menu.Item header>Dashboard</Menu.Item>
        </Menu>
        <div style={Container}>
          <AnswerFilter />
          <AnswerColumnGraph />
          <AnswerList />
        </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {userId: state.auth.userId };
}

export default connect(mapStateToProps)(requireAuth(Dashboard));