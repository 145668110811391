import React, { Component } from "react";
import { connect } from "react-redux";

import ForgotPasswordForm from "./ForgotPasswordForm";
import redirectOnAuth from "./redirectOnAuth";
// import url from "../../utils/routeUrls";
import { clearAuthErrorMsg } from "../../actions";

class ForgotPassword extends Component {

  state = { sentResetEmail: false };

  componentWillUnmount() {
    this.props.clearAuthErrorMsg(); // clears any error or misc messages still lingering in global state
  }

  chooseView() {
    if (!this.state.sentResetEmail)
      return <ForgotPasswordForm callback={() => this.setState({ sentResetEmail: true })} />;
    else
      return <div>{this.props.message}</div>;
  }

  render() {
    return (
      <div>
        {this.chooseView()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { message: state.auth.miscMessage };
}

export default connect(mapStateToProps, { clearAuthErrorMsg })(redirectOnAuth(ForgotPassword));