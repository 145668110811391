import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import urls from "../../utils/routeUrls";
import { setHeight } from "../../actions";
import { Link } from "react-scroll";
import { Icon } from "semantic-ui-react";

class Header extends Component {
  headerTag = React.createRef();

  componentDidMount() {
    this.props.setHeight(this.headerTag.current.offsetHeight);
  }

  render() {
    const first = this.props.userData.first_name || this.props.userData.email;
    return (
      <div>
        <header ref={this.headerTag} id="header">
          <nav>
            <div className="row">
              <img
                src={require("../../assets/img/smilestein-logo.png")}
                alt="Smilestein logo"
                className="logo"
              />
              <ul className="main-nav js--main-nav">
                <li>
                  <Link to="features" smooth={true} offset={-70} duration={500}>
                    Math is Fun
                  </Link>
                </li>
                <li>
                  <Link to="works" smooth={true} offset={-70} duration={500}>
                    Gamification
                  </Link>
                </li>
                <li>
                  <Link to="cities" smooth={true} offset={-70} duration={500}>
                    Our cities
                  </Link>
                </li>
                {!this.props.authenticated && (
                  <li>
                    <Link to="plans" smooth={true} offset={-70} duration={500}>
                      Sign Up
                    </Link>
                  </li>
                )}
                {this.props.authenticated && (
                  <li>
                    <a
                      onClick={() =>
                        this.props.history.push(urls.EXERCISE_LANDING)
                      }
                      href="javascript:void(0);"
                    >
                      {" "}
                      <Icon name="user circle" color="teal" /> {first}{" "}
                    </a>
                  </li>
                )}
              </ul>
              <a className="mobile-nav-icon js--nav-icon">
                <i className="ion-navicon-round" />
              </a>
            </div>
          </nav>
          <div className="row">
            <h1>
              <br />
              <br />
              No more tears. <br />
              Enjoy learning math!
            </h1>
            <div className="btn btn-full">
              <Link to="plans" smooth={true} offset={-70} duration={500}>
                Subscribe
              </Link>
            </div>
            <div className="btn btn-ghost">
              {!this.props.authenticated && (
                <a onClick={() => this.props.history.push(urls.SIGNIN)}>
                  Start Learning
                </a>
              )}
              {this.props.authenticated && (
                <a
                  onClick={() => this.props.history.push(urls.EXERCISE_LANDING)}
                >
                  Start Learning
                </a>
              )}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];
  return {
    userData: userData || {},
    authenticated: state.auth.authenticated
  };
}

export default connect(
  mapStateToProps,
  { setHeight }
)(withRouter(Header));
