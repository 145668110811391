import React, { Component } from "react";

export default class Preview extends Component {
  render() {
    return (
      <div>
        <section className="section-meals">
          <ul className="meals-showcase clearfix">
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/11.png")} alt="" />
              </figure>
            </li>
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/2.png")} alt="" />
              </figure>
            </li>
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/7.png")} alt="" />
              </figure>
            </li>
            <li>
              <figure className="meal-photo">
                <img
                  src={require("../../assets/img/robot-800-600.png")}
                  alt=""
                />
              </figure>
            </li>
          </ul>
          <ul className="meals-showcase clearfix">
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/5.png")} alt="" />
              </figure>
            </li>
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/13.png")} alt="" />
              </figure>
            </li>
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/3.png")} alt="" />
              </figure>
            </li>
            <li>
              <figure className="meal-photo">
                <img src={require("../../assets/img/8.png")} alt="" />
              </figure>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}
