import axios from "axios";

import apiUrls from "../config/apiUrls";
import {
  GAMIFICATION_SCORE_LOAD,
  GAMIFICATION_SCORE_SET,
  GAMIFICATION_SET_STORY_INDEX,
  GAMIFICATION_ERROR
} from "./types";

export const gamificationSetStoryIndex = (
  storyIndex,
  callback
) => async dispatch => {
  try {
    dispatch({ type: GAMIFICATION_SET_STORY_INDEX, payload: storyIndex });
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: GAMIFICATION_ERROR, payload: {} });
    console.log("Error:", e);
  }
};

export const gamificationScoreLoad = (
  searchParams,
  callback
) => async dispatch => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.ANALYTICS_URL}/points-per-user`,
      searchParams,
      config
    );

    dispatch({
      type: GAMIFICATION_SCORE_LOAD,
      payload: response.data["0"].correct
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: GAMIFICATION_ERROR, payload: {} });
    console.log("Error:", e);
  }
};

export const gamificationScoreSet = (points, callback) => async dispatch => {
  try {
    dispatch({ type: GAMIFICATION_SCORE_SET, payload: points });
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: GAMIFICATION_ERROR, payload: {} });
    console.log("Error:", e);
  }
};
