import React, { Component } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { Header, Icon, Button, Segment } from "semantic-ui-react";

class SignupPlan extends Component {
  state = { disableButton: false };

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="child" />
        Select a Plan
      </Header>
    );
  }

  onSubmit = (formProps, actions) => {
    this.setState({ disableButton: true });
    // on submit (validate that there is a value), update progress and update state of signup wizard. use get helper for the next step when actually makign the api call
    console.log(formProps);
    this.props.updateSubPlan(formProps);
    this.props.updateProgress();
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      plan: Yup.string().required("Please Select a Plan")
    });

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <div
        style={{
          minWidth: "450px",
          maxWidth: "40%",
          margin: "auto",
          padding: "70px 0"
        }}
      >
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <Header as="h3">Monthly Plans</Header>
            <Segment vertical>
              <Field
                component="input"
                type="radio"
                name="plan"
                value={"1 1 4.99"}
              />
              {" 1 Child: $4.99/month"}
            </Segment>
            <Segment vertical>
              <Field
                component="input"
                type="radio"
                name="plan"
                value={"1 2 7.99"}
              />
              {" 2 Children: $7.99/month"}
            </Segment>
            <Segment vertical>
              <Field
                component="input"
                type="radio"
                name="plan"
                value={"1 10 9.99"}
              />
              {" Family: $9.99/month"}
            </Segment>
            <Header as="h3">Annual Plans</Header>
            <Segment vertical>
              <Field
                component="input"
                type="radio"
                name="plan"
                value={"3 1 49.00"}
              />
              {" 1 Child: $49.00/year"}
            </Segment>
            <Segment vertical>
              <Field
                component="input"
                type="radio"
                name="plan"
                value={"3 2 79.00"}
              />
              {" 2 Children: $79.00/year"}
            </Segment>
            <Segment vertical>
              <Field
                component="input"
                type="radio"
                name="plan"
                value={"3 10 99.00"}
              />
              {" Family: $99.00/year"}
            </Segment>
            <ErrorMessage name="plan" component={this.renderError} />

            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Next
            </Button>
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          initialValues={{
            plan: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

export default SignupPlan;
