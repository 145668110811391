import { SUB_CREATE, SUB_ERROR } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case SUB_CREATE:
      return { ...action.payload };
    case SUB_ERROR:
      return state;
    default:
      return state;
  }
};
