import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import {
  Button,
  Icon,
  Table,
  Menu,
  Progress,
  Header,
  Divider
} from "semantic-ui-react";

import url from "../../utils/routeUrls";
import {
  skillFind,
  exerciseFind,
  exerciseCreate,
  exerciseActiveFind,
  exerciseReset,
  exerciseActiveIndex
} from "../../actions";
import ExerciseFilter from "./ExerciseFilter";
import { formatSkillType, formatLevel } from "../skill/skillUtils";
import { CustomMessage } from "../common/messageComponents";
import requireAuth from "../auth/requireAuth";

import { Container } from "../../styles";

const headerNames = ["Skill", "Type", "Level", "Questions", "Progress", ""];

class ExerciseList extends Component {
  state = {
    disableButtons: false
  };

  componentDidMount() {
    const searchParams = { user_id: this.props.userId };

    if (this.props.skill_type) {
      searchParams.skill_type = this.props.skill_type;
    } else if (this.props.preferences && this.props.preferences.skill_type) {
      searchParams.skill_type = this.props.preferences.skill_type;
    }
    if (this.props.level) {
      searchParams.level = this.props.level;
    } else if (this.props.preferences && this.props.preferences.level) {
      searchParams.level = this.props.preferences.level;
    }
    console.log("props.preferences: ", this.props.preferences);
    console.log("searchParams: ", searchParams);
    this.props.exerciseFind(searchParams, true);
  }

  componentWillUnmount() {
    this.props.exerciseReset();
  }

  generateTableHeader = () => {
    return headerNames.map(name => {
      return (
        <Table.HeaderCell key={name} width={name === "Progress" ? 3 : null}>
          {name}
        </Table.HeaderCell>
      );
    });
  };

  beginButtonText(exercise) {
    // console.log(Number(exercise.count_questions), Number(exercise.count_correct) + Number(exercise.count_wrong));
    if (
      (exercise.count_questions ||
        exercise.count_correct ||
        exercise.count_wrong) &&
      Number(exercise.count_questions) ===
        Number(exercise.count_correct) + Number(exercise.count_wrong)
    ) {
      return "Restart";
    } else {
      return !exercise.exercise_id ? "Begin" : "Continue";
    }
  }

  progressPercent(exercise) {
    if (
      exercise.count_questions ||
      exercise.count_correct ||
      exercise.count_wrong
    ) {
      return Math.floor(
        ((Number(exercise.count_correct) + Number(exercise.count_wrong)) /
          Number(exercise.count_questions)) *
          100
      );
    }
    return 0;
  }

  beginExerciseButton(exercise) {
    // sends user to the specified skill's question list page when clicked
    return (
      <Button
        disabled={this.state.disableButtons}
        loading={this.state.disableButtons}
        onClick={() => {
          if (!exercise.exercise_id && !exercise.user_id)
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseCreate(
                { skill_id: exercise.skill_id, user_id: this.props.userId },
                () => this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          else if (
            Number(exercise.count_questions) ===
            Number(exercise.count_correct) + Number(exercise.count_wrong)
          ) {
            console.log("Restarting");
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseCreate(
                { skill_id: exercise.skill_id, user_id: this.props.userId },
                () => this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          } else if (exercise.user_id === this.props.userId) {
            this.props.exerciseActiveIndex(
              Number(exercise.count_correct) + Number(exercise.count_wrong)
            );
            this.setState({ disableButtons: true }, () =>
              this.props.exerciseActiveFind(exercise.exercise_id, () =>
                this.props.history.push(url.EXERCISE_QUESTIONS)
              )
            );
          } else
            console.log(
              "Critical error: Localstorage UserId did not match UserId for exercise."
            );
        }}
      >
        <Icon name="play" /> {this.beginButtonText(exercise)}
      </Button>
    );
  }

  messageWarning(str, obj) {
    // displays error message str given an object to check if empty or not
    if (_.isEmpty(obj)) {
      return (
        <CustomMessage style={{ marginBottom: "5px" }} message={str} warning />
      );
    }
    return null;
  }

  renderExercises() {
    // renders all the exercises sorted into categories and subcategories.
    return this.generateCatHeaders(this.sortExercisesFound());
  }

  generateCatHeaders = renderObj => {
    // given an object with format { categoryNumber: { subcategoryNumber: [ exerciseObject ] } }
    // renders the sorted exercises under their respective category and subcategory names
    return _.map(renderObj, (catObj, catName) => {
      return (
        <div key={catName} style={Container}>
          <Header as="h2" style={{ marginTop: "15px" }}>
            {catName === "0" ? "Uncategorized" : catName}
          </Header>
          {this.generateSubCatHeaders(catObj)}
        </div>
      );
    });
  };

  formatCountQuestions = countQuestions => {
    if (!countQuestions) return 0;
    else return countQuestions;
  };

  generateSubCatHeaders = catObj => {
    let key = 0;
    return _.map(catObj, (subCat, subCatName) => {
      return (
        <div key={key++}>
          <Divider />
          <Header as="h4">
            {subCatName === "0" ? "Uncategorized" : subCatName}
          </Header>
          {this.generateExerciseTables(subCat)}
        </div>
      );
    });
  };

  generateExerciseTables = newStateSubCat => {
    const rows = _.map(newStateSubCat, exercise => {
      return (
        <Table.Row key={exercise.exercise_id || exercise.skill_id}>
          <Table.Cell>{exercise.name}</Table.Cell>
          <Table.Cell>{formatSkillType(exercise.skill_type)}</Table.Cell>
          <Table.Cell>{formatLevel(exercise.level)}</Table.Cell>
          <Table.Cell>
            {this.formatCountQuestions(exercise.count_questions)}
          </Table.Cell>
          <Table.Cell>
            <Progress
              size="small"
              percent={this.progressPercent(exercise)}
              color="olive"
              progress
            />
          </Table.Cell>
          <Table.Cell>{this.beginExerciseButton(exercise)}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table striped fixed>
        <Table.Header>
          <Table.Row>{this.generateTableHeader()}</Table.Row>
        </Table.Header>

        <Table.Body>{rows}</Table.Body>
      </Table>
    );
  };

  sortExercisesFound = () => {
    // sorts the exercises found from redux state into an object corresponding to subcategories and categories.
    // returns the object.

    const renderObj = {};
    const { exercisesFound } = this.props;

    // first, generate an object with category keys (represented as numbers).
    // Their vals are empty objects
    // key "0" is for any skill that lacks a category, just in case.

    _.forEach(exercisesFound, exercise => {
      if (exercise.category_name) renderObj[exercise.category_name] = {};
      else renderObj["0"] = {};
    });

    // second, generate all subcategory keys (represented as numbers) one layer down its parent category.
    // Their vals are arrays.

    _.forEach(exercisesFound, exercise => {
      if (exercise.category_name)
        // if an exercise has a valid category, assume it also has a valid subcategory
        renderObj[exercise.category_name][exercise.sub_category_name] = [];
      else renderObj["0"]["0"] = []; // the second ["0"] is to simulate an undefined subcategory to simplify render logic later
    });

    // now, assign all exercises to their relevant arrays.
    // Note: each array is never mutated.

    _.forEach(exercisesFound, exercise => {
      if (exercise.category_name)
        renderObj[exercise.category_name][
          exercise.sub_category_name
        ] = renderObj[exercise.category_name][
          exercise.sub_category_name
        ].concat([exercise]);
      else renderObj["0"]["0"] = renderObj["0"]["0"].concat([exercise]);
    });

    return renderObj;
  };

  generateTable() {
    // using the skills fetched from the server, generates a table.
    return _.map(this.props.exercisesFound, exercise => {
      return (
        <Table.Row key={exercise.exercise_id || exercise.skill_id}>
          <Table.Cell>{exercise.name}</Table.Cell>
          <Table.Cell>{formatSkillType(exercise.skill_type)}</Table.Cell>
          <Table.Cell>{formatLevel(exercise.level)}</Table.Cell>
          <Table.Cell>
            <Progress
              size="small"
              percent={this.progressPercent(exercise)}
              color="olive"
              progress
            />
          </Table.Cell>
          <Table.Cell>{this.beginExerciseButton(exercise)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    // console.log(this.props.exercisesFound);
    return (
      <div>
        <Menu>
          <Menu.Item header>Exercises</Menu.Item>
        </Menu>
        <div style={Container}>
          <ExerciseFilter />
        </div>
        {this.renderExercises()}
        <div style={Container}>
          {this.messageWarning(
            "No exercises found with current filters!",
            this.props.exercisesFound
          )}
          <Divider />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const persistedForm = state.formik["exercisefilter"];
  return {
    exercisesFound: state.exercise,
    userId: state.auth.userId,
    preferences: state.auth.preferences,

    skill_type: persistedForm ? persistedForm.skill_type : null,
    level: persistedForm ? persistedForm.level : null
  };
}

export default connect(
  mapStateToProps,
  {
    skillFind,
    exerciseFind,
    exerciseCreate,
    exerciseActiveFind,
    exerciseActiveIndex,
    exerciseReset
  }
)(requireAuth(ExerciseList));
