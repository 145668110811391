import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Header,
  Icon,
  Button,
  Segment,
  Message,
  Form as SemForm
} from "semantic-ui-react";
import SemField from "../../helpers/SemField";

import { signup, clearAuthErrorMsg, userProfileFind } from "../../../actions";

import { CustomMessage } from "../../common/messageComponents";

import url from "../../../utils/routeUrls";

class SignupCredentials extends Component {
  state = { disableButton: false, errorMsg: "" };

  componentDidUpdate() {
    if (this.props.authError) {
      this.setState({ errorMsg: "Email is already in use" });
    }

    if (this.state.disableButton && this.props.authError) {
      this.setState({ disableButton: false });
      this.props.clearAuthErrorMsg();
    }
  }

  componentWillUnmount() {
    this.props.clearAuthErrorMsg();
  }

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Sign Up
      </Header>
    );
  }

  onSubmit = (formProps, actions) => {
    this.setState({ disableButton: true });
    this.props.signup(formProps, null, ({ user_id }) =>
      this.props.userProfileFind({ user_id }, () => this.props.updateProgress())
    );
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Email is invalid.")
        .required("Email required."),
      affiliate: Yup.string().required("Affiliate code required"),
      password: Yup.string()
        .min(4, "Too short. Must be at least 4 characters.")
        .required("Password Required"),
      passwordConfirm: Yup.string().required("Password Confirmation Required")
    });

  validateForm = values => {
    const errors = {};

    if (values.affiliate && values.affiliate !== "nepumuk") {
      errors.affiliate = "Affiliate code invalid.";
    }
    if (values.passwordConfirm !== values.password) {
      errors.passwordConfirm = "Password confirmation does not match.";
    }
    return errors;
  };

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitted }) => {
    return (
      <div
        style={{
          minWidth: "450px",
          maxWidth: "40%",
          margin: "auto",
          padding: "70px 0"
        }}
      >
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <label>Email</label>
            <SemField
              name="email"
              type="text"
              component={SemForm.Input}
              fluid
              placeholder="johndoe@email.com"
              icon="user"
              iconPosition="left"
            />
            <ErrorMessage name="email" component={this.renderError} />
            <label>Affiliate</label>
            <SemField
              name="affiliate"
              type="text"
              component={SemForm.Input}
              fluid
              placeholder="affiliate code"
              icon="money"
              iconPosition="left"
            />
            <ErrorMessage name="affiliate" component={this.renderError} />
            <label>Password</label>
            <SemField
              name="password"
              type="password"
              component={SemForm.Input}
              fluid
              placeholder="*******"
              icon="lock"
              iconPosition="left"
            />
            <ErrorMessage name="password" component={this.renderError} />
            <label>Confirm Password</label>
            <SemField
              name="passwordConfirm"
              type="password"
              component={SemForm.Input}
              fluid
              placeholder="*******"
              icon="lock"
              iconPosition="left"
            />
            <ErrorMessage name="passwordConfirm" component={this.renderError} />

            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitted || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Sign Up
            </Button>
            {this.state.errorMsg && (
              <CustomMessage
                style={{ marginTop: "10px" }}
                message={this.state.errorMsg}
                color="red"
              />
            )}
          </Form>
        </Segment>
        <Message>
          Already have an account? <Link to={url.SIGNIN}>Sign In</Link>.
        </Message>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          validate={this.validateForm}
          initialValues={{
            email: "",
            affiliate: "",
            password: "",
            passwordConfirm: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { authError: state.auth.errorMessage };
}

export default connect(
  mapStateToProps,
  { signup, clearAuthErrorMsg, userProfileFind }
)(SignupCredentials);
