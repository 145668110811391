import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { connect } from "react-redux";

class AnswerColumnGraph extends Component {
  formatDate(str) {
    // slices updatedAt and createdAt date strings to be year-month-day format
    if (!str) {
      return "N/A";
    }
    return str.slice(0, 10);
  }

  render() {
    const answerAnalytics = this.props.answersPerUserPerDay
      ? this.props.answersPerUserPerDay.concat().reverse()
      : [];

    const options = {
      chart: { type: "column" },
      title: { text: "Questions answered" },
      // subtitle: { text: 'Source: WorldClimate.com' },
      xAxis: {
        categories: this.props.answersPerUserPerDay
          ? answerAnalytics.map(x => this.formatDate(x.date))
          : [], // test to see if data syncs up correctly
        crosshair: true
      },
      yAxis: { min: 0, title: { text: "Questions Answered" } },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} Questions</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          name: "Correct",
          data: this.props.answersPerUserPerDay
            ? answerAnalytics.map(x => Number(x.correct))
            : []
        },
        {
          name: "Incorrect",
          data: this.props.answersPerUserPerDay
            ? answerAnalytics.map(x => Number(x.wrong))
            : []
        }
      ]
    };

    return (
      <div>
        {this.props.answersPerUserPerDay && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { answersPerUserPerDay: state.analytics.answersPerUserPerDay };
}

export default connect(mapStateToProps)(AnswerColumnGraph);
