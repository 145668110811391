import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import {
  Header,
  Icon,
  Button,
  Segment,
  Form as SemForm
} from "semantic-ui-react";

import { authForgotPass, clearAuthErrorMsg } from "../../actions";

import { CustomMessage } from "../common/messageComponents";
import SemField from "../helpers/SemField";

class ForgotPasswordForm extends Component {
  // this component requires callback prop on creation to work.

  state = { disableButton: false, errorMsg: "" };

  // componentWillUnmount() {
  //   this.props.clearAuthErrorMsg(); // clears this.props.authError
  // }

  componentDidUpdate() {
    if (this.props.authError) {
      this.setState({ errorMsg: this.props.authError });
    }

    if (this.state.disableButton && this.props.authError) {
      this.setState({ disableButton: false });
      this.props.clearAuthErrorMsg();
    }
  }

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Forgot Your Password?
      </Header>
    );
  }

  onSubmit = ({ email }, actions) => {
    this.setState({ disableButton: true });
    this.props.authForgotPass(email, this.props.callback);
    actions.setSubmitting(false);
  };

  validateSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .email("Email is invalid.")
        .required("Email required.")
    });

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  renderForm = ({ isSubmitting }) => {
    return (
      <div style={{ width: "40%", margin: "auto", padding: "70px 0" }}>
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <SemField
              name="email"
              type="text"
              component={SemForm.Input}
              placeholder="johndoe@email.com"
              icon="user"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="email" component={this.renderError} />
            <Button
              style={{ marginTop: "20px" }}
              loading={this.state.disableButton}
              disabled={isSubmitting || this.state.disableButton}
              className="teal"
              fluid
              size="large"
            >
              Send Password Reset Email
            </Button>
            {this.state.errorMsg && (
              <CustomMessage
                style={{ marginTop: "10px" }}
                message={this.state.errorMsg}
                color="red"
              />
            )}
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          initialValues={{
            email: ""
          }}
          onSubmit={this.onSubmit}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { authError: state.auth.errorMessage };
}

export default 
  connect(
    mapStateToProps,
    { authForgotPass, clearAuthErrorMsg }
  )
  (ForgotPasswordForm);
