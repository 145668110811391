import React, { Component } from "react";

import "../../vendors/css/grid.css";
import "../../vendors/css/animate.css";
import "../../vendors/css/normalize.css";
import "../../vendors/css/ionicons.min.css";
import "../../css/style.css";
import "../../css/queries.css";

export default class Testimonials extends Component {
  render() {
    return (
      <div>        
        <section className="section-testimonials">
            <div className="row">
                <h2>Our kids, parents, and teachers can't live without us</h2>
            </div>
            <div className="row">
                <div className="col span-1-of-3">
                    <blockquote>
                        Smilestein is just awesome! I just launched a startup which leaves me with no time to help my kids, so Smilestein is a life-saver. Now that I got used to it, I couldn't live without Smilestein teaching my kids!
                        <cite><img src={ require('../../assets/img/customer-1.jpg') } alt=""/>Alberto Duncan (parent)</cite>
                    </blockquote>
                </div>
                <div className="col span-1-of-3">
                    <blockquote>
                       Inexpensive, healthy and great-tasting math, delivered right to our kids. We have lots of education delivery here in Lisbon, but no one comes even close to Smilestein. Our school districts and parents are so in love!
                        <cite><img src={ require('../../assets/img/customer-2.jpg') } alt=""/>Joana Silva (teacher)</cite>
                    </blockquote>
                </div>
                <div className="col span-1-of-3">
                    <blockquote>
                        I was looking for a quick and easy way to learn Math in San Franciso. I tried a lot of ways to get the stuff in my head and ended up with Smilestein. Best math learning site in the Bay Area. Keep up the great work!
                      <cite><img src={ require('../../assets/img/customer-3.jpg') } alt=""/>Milton Chapman (9 years)</cite>
                    </blockquote>
                </div>
            </div>
        </section>
      </div>
    );
  }
}