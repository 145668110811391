// import _ from "lodash";

import { LEAD_FIND, LEAD_ERROR, LEAD_RESET } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case LEAD_FIND:
      return { ...action.payload };

    case LEAD_RESET:
      return {};

    case LEAD_ERROR:
      return state;
    default:
      return state;
  }
};
