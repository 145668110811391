import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { Button, Form, Table, Icon, Input } from "semantic-ui-react";
import _ from "lodash";

import { InputForm, SelectForm, TextAreaForm } from "../common/formComponents";
import { CustomMessage } from "../common/messageComponents";
import requireAuth from "../auth/requireAuth";
import url from "../../utils/routeUrls";
import { questionFind, questionUpdate, skillFind } from "../../actions";
import { questionTypes } from "./questionUtils";

const allFormFields = [
  "question_type",
  "text",
  "source",
  "image_url"
];

const requiredFields = [
  "question_type", 
  "text", 
  "source"
];

class QuestionEdit extends Component {

  state = { 
    disableButton: false, 
    optionArray: [], 
    answerArray: [], 
    optionInputValue: "",
    answerInputValue: "",
    initialLoad: true
  };

  componentWillMount() {
    // check if skillId was included as a parameter in the URL.
    const urlSkillId = this.props.match.params.skillId;
    const urlQuestionId = this.props.match.params.questionId;
    if (!urlSkillId || !urlQuestionId) {
      // If skillId or questionId is not provided in the URL, redirect user back to skill list.
      this.props.history.push(url.SKILL_LIST);
    } else {
      // if it is provided, fetch the question based on questionId, and update a form with the new values

      // perform a skill fetch and question find to ensure both params exist in the database
      this.props.skillFind(
        { skill_id: urlSkillId }, 
        null, 
        () => this.props.history.push(url.SKILL_LIST),
        (response) => response.length === 0 ? this.props.history.push(url.SKILL_LIST) : null // skill doesn't exist
      );
      this.props.questionFind(
        { question_id: urlQuestionId }, 
        null, 
        () => this.props.history.push(url.SKILL_LIST),
        (response) => response.length === 0 ? this.props.history.push(url.SKILL_LIST) : null // question doesn't exist
      );
    }
  }

  componentDidUpdate() {
    // on page load, load in options and correct answer arrays into local state
    if (this.state.initialLoad && this.props.question && this.props.question["0"]) {
      this.setState({ 
        optionArray: this.props.question["0"].options, 
        answerArray: this.props.question["0"].correct_answers, 
        initialLoad: false});
    }

  }

  deleteOptionButton(opt) {
    // deletes the option from the optionArray state
    return (
      <Button icon type="button" onClick={
        () => {
          const newOptionArray = _.filter(this.state.optionArray, optionToCheck => {
            return optionToCheck !== opt;
          });
          this.setState({ optionArray: newOptionArray });
        }
      }>
        <Icon name="delete" />
      </Button>
    );
  }

  generateOptionTable() {
    let key = 0;
    return this.state.optionArray.map(opt => {
      return (
        <Table.Row key={key++} >
          <Table.Cell>{this.deleteOptionButton(opt)}</Table.Cell>
          <Table.Cell>{opt}</Table.Cell>
        </Table.Row>
      );
    });
  }

  deleteAnswerButton(ans) {
    // deletes the answer from the answerArray state
    return (
      <Button icon type="button" onClick={
        () => {
          const newAnswerArray = _.filter(this.state.answerArray, answerToCheck => {
            return answerToCheck !== ans;
          });
          this.setState({ answerArray: newAnswerArray });
        }
      }>
        <Icon name="delete" />
      </Button>
    );
  }

  generateAnswerTable() {
    let key = 0;
    return this.state.answerArray.map(ans => {
      return (
        <Table.Row key={key++} >
          <Table.Cell>{this.deleteAnswerButton(ans)}</Table.Cell>
          <Table.Cell>{ans}</Table.Cell>
        </Table.Row>
      );
    });
  }

  emptyArrayWarning = (str, arr) => {
    // displays error message if an array is empty
    if (arr.length === 0) {
      return (
        <CustomMessage 
          style={{ marginBottom: "5px" }} 
          message={str}
          warning
        />
      );
    }
    return null;
  }

  optionOnKeyPress = (event) => {
    // when enter is pressed on the input field for adding options, submits data and updates state. Prevents dups
    if (event.key === "Enter" && this.state.optionInputValue) {
      if (!this.state.optionArray.includes(this.state.optionInputValue))
        this.setState({ optionArray: this.state.optionArray.concat([this.state.optionInputValue]), optionInputValue: "" });
      else
        this.setState({ optionInputValue: "" });
    }
  }

  answerOnKeyPress = (event) => {
    if (event.key === "Enter" && this.state.answerInputValue) {
      if (!this.state.answerArray.includes(this.state.answerInputValue))
        this.setState({ answerArray: this.state.answerArray.concat([this.state.answerInputValue]), answerInputValue: "" });
      else
        this.setState({ answerInputValue: "" });
    }
  }

  optionOnClick = () => {
    // this function is called when the button to add an option is clicked. It checks for duplicates, and if none, adds it to array.
    if (this.state.optionInputValue) {
      if (!this.state.optionArray.includes(this.state.optionInputValue))
        this.setState({ optionArray: this.state.optionArray.concat([this.state.optionInputValue]), optionInputValue: "" });
      else
        this.setState({ optionInputValue: "" });
    }
  }
  
  answerOnClick = () => {
    if (this.state.answerInputValue) {
      if (!this.state.answerArray.includes(this.state.answerInputValue))
        this.setState({ answerArray: this.state.answerArray.concat([this.state.answerInputValue]), answerInputValue: "" });
      else
        this.setState({ answerInputValue: "" });
    }
  }

  onSubmit = (values) => {
    
    this.setState({ disableButton: true }); // prevents multiple submissions of a new question

    // note: currently will not support changing skills. would require fetching all skills
    // and creating a dropdown containing a value of the skill id.
    
    values.author = this.props.userId;
    values.skill_id = this.props.match.params.skillId;
    values.question_id = this.props.match.params.questionId;
    values.options = this.state.optionArray;
    values.correct_answers = this.state.answerArray;

    console.log(values);

    this.props.questionUpdate(
      values, 
      this.props.match.params.skillId,
      () => this.props.history.push(`${url.QUESTION_LIST}/${this.props.match.params.skillId}`)
    );
  }

  render() {
    return (
      <div style={{ width: "70%", margin: "auto" }}>
        <Form 
          warning 
          onSubmit={this.props.handleSubmit(this.onSubmit)} 
          onKeyPress={e => e.key === "Enter" ? e.preventDefault() : null }
        >
          <Field 
            component={SelectForm} 
            label="Question Type" 
            name="question_type" 
            placeholder="Select type..."
            options={questionTypes}
          />
          <Field 
            component={TextAreaForm} 
            type="text" 
            label="Enter the Question" 
            name="text" 
            placeholder="eg. What is 2+2?"
          />
          
          <label>Add an option </label>
          <Input 
            placeholder="Enter option here" 
            value={this.state.optionInputValue}
            onKeyPress={this.optionOnKeyPress}
            onChange={e => this.setState({ optionInputValue: e.target.value })} 
          />{" "}
          <Button 
            type="button" 
            disabled={!this.state.optionInputValue}
            onClick={this.optionOnClick}>
            Add Option
          </Button>
          
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Delete</Table.HeaderCell>
                <Table.HeaderCell>Answer Options</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.generateOptionTable()}
            </Table.Body>
          </Table>

          <label>Add an answer </label>
          <Input 
            placeholder="Enter answer here" 
            value={this.state.answerInputValue}
            onKeyPress={this.answerOnKeyPress}
            onChange={e => this.setState({ answerInputValue: e.target.value })} 
          />{" "}
          <Button 
            type="button" 
            disabled={!this.state.answerInputValue}
            onClick={this.answerOnClick}>
            Add Answer
          </Button>
          
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Delete</Table.HeaderCell>
                <Table.HeaderCell>Question Options</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.generateAnswerTable()}
            </Table.Body>
          </Table>
          {this.emptyArrayWarning("At least one correct answer is required.", this.state.answerArray )}

          <Field 
            component={InputForm} 
            type="text" 
            label="Source" 
            name="source" 
            placeholder="eg. SmileStein"
          />
          <Field 
            component={InputForm} 
            type="text" 
            label="Image URL" 
            name="image_url" 
            placeholder="eg. image.png"
          />
          <Button 
            loading={this.state.disableButton} 
            disabled={this.state.disableButton || this.state.answerArray.length === 0} 
            floated="right" 
            type="submit" 
            positive
          >
            Confirm Question Edit
          </Button>
        </Form>
        <Button onClick={() => this.props.history.push(`${url.QUESTION_LIST}/${this.props.match.params.skillId}`)}>
          Cancel
        </Button>
      </div>
    ); 
  }
}

function validate(values) {
  const errors = {};

  requiredFields.forEach(fieldname => {
    if (!values[fieldname] && values[fieldname] !== 0) {
      errors[fieldname] = "This field is required.";
    };
    return;
  })

  return errors;
}

function mapStateToProps(state) {
  const questionToEdit = state.question["0"];
  
  const initialValues = {};

  allFormFields.forEach(entry => {
    if (questionToEdit && (questionToEdit[entry] || questionToEdit[entry] === 0)) {
      initialValues[entry] = questionToEdit[entry];
    } else {
      initialValues[entry] = "";
    }
  });
  return { initialValues, userId: state.auth.userId, question: state.question };
}

export default compose(
  connect(mapStateToProps, { questionFind, questionUpdate, skillFind }),
  reduxForm({ validate, enableReinitialize: true, form: "questionedit" })
)(requireAuth(QuestionEdit));