import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";
import { formValueSelector } from "redux-form";
import { analyticsAnswersPerUserPerDay } from "../../actions";
import { CustomMessage } from "../common/messageComponents";
import requireAuth from "../auth/requireAuth";

import { Container } from "../../styles";

const headerNames = [
  "Date", 
  "Count", 
  "Correct", 
  "Wrong",
  "Time Total",
  "Time Avg."
];

class AnswerList extends Component {

  state = { errorMsg: "" };

  componentDidMount() {
    // When mounting the component, loads page with given search params, 
    const searchParams = {};
    if (this.props.userId) searchParams.user_id = this.props.userId;
    if (this.props.preset_date !== -1 && this.props.preset_date !== -2) {
      searchParams.start_date = this.props.preset_date;
      searchParams.end_date = new Date().toISOString().slice(0,10);
    } else if (this.props.preset_date === -1) {
      searchParams.start_date = this.props.start_date;
      searchParams.end_date = this.props.end_date;
    }
   // if (this.props.start_date) searchParams.start_date = this.props.start_date;
   // console.log("componentDidMount search params",searchParams);
    this.props.analyticsAnswersPerUserPerDay(searchParams);
  }

  formatDate(str) {
    // slices updatedAt and createdAt date strings to be year-month-day format
    if (!str) {
      return "N/A";
    }
    return str.slice(0, 10);
  }

  convertMS(ms) {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    var ss = s > 9 ? s : '0' + s;
    h = Math.floor(m / 60);
    m = m % 60;
    var mm = m > 9 ? m : '0' + m;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return h + ':' + mm + ':' + ss;
}

  generateTableHeader() {
    return headerNames.map((name, index) => {
      return <Table.HeaderCell key={index}>{name}</Table.HeaderCell>
    });
  }


  analyticsMessageWarning(str, obj) {
    // displays error message str given an object to check if empty or not
    if (_.isEmpty(obj)) {
      return (
        <CustomMessage 
          style={{ marginBottom: "5px" }} 
          message={str}
          warning
        />
      );
    }
    return null;
  }


  generateTable() {
    //console.log("this.props.userId",this.props.userId);
    //console.log("this.props.answersPerUserPerDay",this.props.answersPerUserPerDay);
    return _.map(this.props.answersPerUserPerDay, (answers) => {
      return (
        <Table.Row key={answers.date} >
          <Table.Cell>{this.formatDate(answers.date)}</Table.Cell>
          <Table.Cell>{answers.count}</Table.Cell>
          <Table.Cell>{answers.correct}</Table.Cell>
          <Table.Cell>{answers.wrong}</Table.Cell>
          <Table.Cell>{this.convertMS(answers.total_timing)}</Table.Cell>
          <Table.Cell>{(answers.average_timing/1000).toFixed(2)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render () {
    // console.log("render",this.props.answersPerUserPerDay);
    return (
      <div>
        <Table striped style={Container}>
          <Table.Header>
            <Table.Row>
              {this.generateTableHeader()}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.generateTable()}
          </Table.Body>
        </Table>
        <div style={Container}>
          {this.analyticsMessageWarning("No Answers Analytics Found",this.props.answersPerUserPerDay)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("answerfilter");
  return { 
      answersPerUserPerDay: state.analytics.answersPerUserPerDay, 
      end_date: selector(state, "end_date"),
      start_date: selector(state, "start_date"),
      preset_date: selector(state, "preset_date"),
      userId: state.auth.userId };
}

export default connect(mapStateToProps, { 
  analyticsAnswersPerUserPerDay 
})(requireAuth(AnswerList));