import React, { Component } from "react";

export default class Team extends Component {
  render() {
    return (
      <div>
        <section className="section-team">
            <div className="row">
                <h2>Your competent development partner listens</h2>
            </div>
            <div className="row">
                <div className="col span-1-of-3">
                    <blockquote>
                        The Smilestein team is awesome! We decided on a custom solution that best fits our kids, parents, and teachers. The Smilestein team listened, understood, and implemented it with passion. Thank you for being a perfect partner!!
                        <cite><img src={ require('../../assets/img/customer-1.jpg') } alt=""/>Alberto Duncan</cite>
                    </blockquote>
                </div>
                <div className="col span-1-of-3">
                    <blockquote>
                        Smilestein rocks! We needed an innovative partner to implement our vision in pushing the edge with innovative educational technology. Smilestein soughed the opportunity and we all pulled in the same direction. We could not have wished for a better partner!
                        <cite><img src={ require('../../assets/img/customer-2.jpg') } alt=""/>Joana Silva</cite>
                    </blockquote>
                </div>
                <div className="col span-1-of-3">
                    <blockquote>
                        Passion for the mission! The Smilestein team listened and constantly brought new solutions to the table. We received much more than a great product. Together, we build a whole new mission. We hope to join forces for a long time! 
                        <cite><img src={ require('../../assets/img/customer-3.jpg') } alt=""/>Milton Chapman</cite>
                    </blockquote>
                </div>
            </div>
        </section>
      </div>
    );
  }
}