import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import url from "../../utils/routeUrls";
import {
  goFullScreen,
  userProfileFind,
  userProfileUpdate,
  authUpdate
} from "../../actions";
import requireAuth from "../auth/requireAuth";
import Fullscreen from "react-full-screen";

class RobotSelect extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  robotSelected(robot) {
    this.props.preferences.robot = robot;
    this.props.userProfileUpdate(
      { user_id: this.props.userId, preferences: this.props.preferences },
      () =>
        this.props.authUpdate({ preferences: this.props.preferences }, () =>
          this.props.history.push(url.ROBOT_NAME)
        )
    );
  }

  render() {
    const first =
      this.props.userProfile.first_name || this.props.userProfile.email;
    return (
      <Fullscreen enabled={this.props.fullScreen}>
        <div className="section-robotstory">
          <div className="robotstory-frame robotstory-einstein-smile">
            <Row className="question-header">
              <Col className="question-preferences" />
              <Col xs={6} className="question-category">
                Select your new friend:
              </Col>
              <Col className="question-exit">
                <ul className="question-right-nav">
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="question-header">
              <Col
                onClick={() => this.robotSelected("Robot1")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot1.png")} />
              </Col>
              <Col
                onClick={() => this.robotSelected("Robot2")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot2.png")} />
              </Col>
              <Col className="robotSelectionSpacer" />
              <Col
                onClick={() => this.robotSelected("Robot3")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot3.png")} />
              </Col>
              <Col
                onClick={() => this.robotSelected("Robot4")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot4.png")} />
              </Col>
            </Row>
            <Row>
              <Col
                onClick={() => this.robotSelected("Robot5")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot5.png")} />
              </Col>
              <Col
                onClick={() => this.robotSelected("Robot6")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot6.png")} />
              </Col>
              <Col className="robotSelectionSpacer" />
              <Col
                onClick={() => this.robotSelected("Robot7")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot7.png")} />
              </Col>
              <Col
                onClick={() => this.robotSelected("Robot8")}
                className="robotSelection"
              >
                <img src={require("../../assets/img/Robot8.png")} />
              </Col>
            </Row>
            <Row>
              <Col
                className="storyPrev"
                style={{ marginTop: "0px" }}
                onClick={() => this.props.history.push(url.ROBOT_STORY)}
              >
                {this.props.preferences.robot && (
                  <i className="ion-android-arrow-dropleft storyNavIcon" />
                )}
              </Col>
              <Col>
                <div className="storyBubble" style={{ marginTop: "0px" }}>
                  <p>
                    <span>"Select your new friend"</span>
                  </p>
                </div>
              </Col>
              <Col
                className="storyNext"
                style={{ marginTop: "0px" }}
                onClick={() => this.props.history.push(url.ROBOT_NAME)}
              >
                {this.props.preferences.robot && (
                  <i className="ion-android-arrow-dropright storyNavIcon" />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];
  return {
    userProfile: userData || {}, // no user logged in, userdata is empty object
    fullScreen: state.userProfile.fullScreen,
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    authenticated: state.auth.authenticated
  };
}

export default connect(
  mapStateToProps,
  {
    goFullScreen,
    userProfileFind,
    userProfileUpdate,
    authUpdate
  }
)(requireAuth(RobotSelect));
