/* eslint-disable jsx-a11y/anchor-is-valid*/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import urls from "../../utils/routeUrls";
import { connect } from "react-redux";
import { userProfileFind } from "../../actions/";

class Footer extends Component {
  render() {
    return (
      <div>
        <div>
          <footer>
            <div className="row">
              <div className="col span-1-of-2">
                <ul className="footer-nav">
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a onClick={() => this.props.history.push(urls.BLOG_HOME)}>
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="#">Press</a>
                  </li>
                  <li>
                    <a
                      onClick={() => this.props.history.push(urls.PRIVACY_HOME)}
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col span-1-of-2">
                <ul className="social-links">
                  <li>
                    <a href="https://www.facebook.com/MoreInnovationInEducation">
                      <i className="ion-social-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-googleplus" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ion-social-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <p>
                This webpage was created for the Smilestein project with
                students at the University of California in Irvine. Smilestein
                is a Smilefish company.
              </p>
              <p>
                Build with <i className="ion-ios-heart" /> while traveling to
                Germany.
              </p>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    auth: state.auth
  };
}
export default connect(mapStateToProps)(withRouter(Footer));
