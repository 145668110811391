import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Image,
  Segment,
  Divider,
  Header,
  Grid,
  Menu,
  Button,
  Icon,
  Container
} from "semantic-ui-react";

import { userProfileFind } from "../../actions";
import requireAuth from "../auth/requireAuth";
import Sidebar from "./Sidebar";
import UserProfileEdit from "./UserProfileEdit";

class UserProfile extends Component {
  state = { toggleEdit: false };

  componentDidMount() {
    this.props.userProfileFind({ user_id: this.props.userId });
  }

  profileImage() {
    if (
      this.props.userProfile &&
      this.props.userProfile["0"] &&
      this.props.userProfile["0"].image_url
    )
      return <Image src={this.props.userProfile["0"].image_url} centered />;
    return null;
  }

  profileViewOrEdit() {
    // shows the profile view or profile edit depending on current state
    if (this.state.toggleEdit) {
      // edit
      return (
        <Segment>
          <UserProfileEdit
            toggleEdit={() => this.setState({ toggleEdit: false })}
          />
        </Segment>
      );
    } else {
      return (
        <Segment>
          <Header content="Avatar" />
          {this.profileImage() || "You don't have a profile image set."}
          <Divider />
          <Header content="Email" />
          {this.props.userProfile &&
            this.props.userProfile["0"] &&
            this.props.userProfile["0"].email}
          <Divider />
          <Header content="Name" />
          {this.props.userProfile &&
            this.props.userProfile["0"] &&
            `${this.props.userProfile["0"].first_name} ${
              this.props.userProfile["0"].last_name
            }`}
          <Divider />
        </Segment>
      );
    }
  }

  render() {
    console.log(this.props.userProfile);
    return (
      <Container>
        <Grid stackable doubling>
          <Grid.Column width={4}>
            <Sidebar />
          </Grid.Column>
          <Grid.Column stretched width={12}>
            <Menu>
              <Menu.Item position="right">
                <Button
                  icon
                  disabled={this.state.toggleEdit}
                  onClick={() => this.setState({ toggleEdit: true })}
                >
                  <Icon name="edit" />
                </Button>
              </Menu.Item>
            </Menu>
            {this.profileViewOrEdit()}
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return { userProfile: state.userProfile, userId: state.auth.userId };
}

export default connect(
  mapStateToProps,
  { userProfileFind }
)(requireAuth(UserProfile));
