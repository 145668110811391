import {
  GAMIFICATION_SCORE_LOAD,
  GAMIFICATION_SCORE_SET,
  GAMIFICATION_ERROR,
  GAMIFICATION_SET_STORY_INDEX
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GAMIFICATION_SCORE_LOAD:
      return { ["points"]: action.payload };
    case GAMIFICATION_SCORE_SET:
      return { ["points"]: action.payload };
    case GAMIFICATION_SET_STORY_INDEX:
      return { ["storyIndex"]: action.payload };
    case GAMIFICATION_ERROR:
      return state;
    default:
      return state;
  }
};
