import {
  EXERCISE_ACTIVE_FIND,
  EXERCISE_ACTIVE_INDEX,
  EXERCISE_ACTIVE_RESET,
  EXERCISE_ACTIVE_ERROR,
  EXERCISE_ACTIVE_WORLDY
} from "../actions/types";

const INITIAL_STATE = {
  activeExercise: {},
  activeIndex: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EXERCISE_ACTIVE_FIND:
      return { ...state, activeExercise: action.payload };
    case EXERCISE_ACTIVE_INDEX:
      return { ...state, activeIndex: action.payload };
    case EXERCISE_ACTIVE_WORLDY:
      return { ...state, activeWorld: action.payload };
    case EXERCISE_ACTIVE_RESET:
      return INITIAL_STATE;
    case EXERCISE_ACTIVE_ERROR:
      return INITIAL_STATE;
    default:
      return state;
  }
};
