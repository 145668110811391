// import 'materialize-css/dist/css/materialize.min.css';

import "semantic-ui-css/semantic.min.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./vendors/css/grid.css";
import "./vendors/css/animate.css";
import "./vendors/css/ionicons.min.css";
import "./vendors/css/normalize.css";
import "./css/style.css";
import "./css/HomePage.css";
import "./css/robot.css";
import "./css/question.css";
import "./css/exercise.css";
import "./css/queries.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter, Route } from "react-router-dom";
import reduxThunk from "redux-thunk";

import App from "./components/App";
import Home from "./components/home/Home";

import Signin from "./components/auth/Signin";
// import Signup from "./components/auth/Signup";
import Signout from "./components/auth/Signout";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";

import SkillList from "./components/skill/SkillList";
import SkillCreate from "./components/skill/SkillCreate";
import SkillEdit from "./components/skill/SkillEdit";

import QuestionList from "./components/question/QuestionList";
import QuestionCreate from "./components/question/QuestionCreate";
import QuestionEdit from "./components/question/QuestionEdit";

import ExerciseQuestions from "./components/exercise/ExerciseQuestions";

import ExerciseList from "./components/exercise/ExerciseList";
import ExerciseWorlds from "./components/exercise/ExerciseWorlds";
import ExerciseWorld from "./components/exercise/ExerciseWorld";
import ExerciseLanding from "./components/exercise/ExerciseLanding";

import RobotStory from "./components/robot/RobotStory";
import RobotSelect from "./components/robot/RobotSelect";
import RobotName from "./components/robot/RobotName";

import UserProfile from "./components/user/UserProfile";
import UserPreferences from "./components/user/UserPreferences";
import UserChangePass from "./components/user/UserChangePass";

import reducers from "./reducers";
import url from "./utils/routeUrls";

import SignupWizard from "./components/auth/signup/SignupWizard";

import Dashboard from "./components/analytics/Dashboard";
import PrivacyHome from "./components/privacy/PrivacyHome";
import BlogHome from "./components/blog/BlogHome";

// Development only axios helpers!
import axios from "axios";
window.axios = axios;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  {
    auth: {
      authenticated: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
      // firstName: localStorage.getItem("firstName"),
      // lastName: localStorage.getItem("lastName"),
      // email: localStorage.getItem("email"),
      roles: JSON.parse(localStorage.getItem("roles")),
      preferences: JSON.parse(localStorage.getItem("preferences"))
    } // Loads JWT and userId from local storage when app starts, so login state is preserved
  },
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <Route path={url.HOME} exact component={Home} />
        <Route path={url.SIGNUP} exact component={SignupWizard} />
        <Route path={url.SIGNOUT} exact component={Signout} />
        <Route path={url.SIGNIN} exact component={Signin} />
        <Route
          path={`${url.RESET_PASSWORD}/:hash?`}
          exact
          component={ResetPassword}
        />
        <Route path={url.FORGOT_PASSWORD} exact component={ForgotPassword} />
        <Route path={url.SKILL_LIST} exact component={SkillList} />
        <Route path={url.SKILL_CREATE} exact component={SkillCreate} />
        <Route
          path={`${url.SKILL_EDIT}/:skillId?`}
          exact
          component={SkillEdit}
        />
        <Route
          path={`${url.QUESTION_LIST}/:skillId?`}
          exact
          component={QuestionList}
        />
        <Route
          path={`${url.QUESTION_CREATE}/:skillId?`}
          exact
          component={QuestionCreate}
        />
        <Route
          path={`${url.QUESTION_EDIT}/:skillId?/:questionId?`}
          exact
          component={QuestionEdit}
        />
        <Route
          path={url.EXERCISE_QUESTIONS}
          exact
          component={ExerciseQuestions}
        />
        <Route path={url.EXERCISE_LIST} exact component={ExerciseList} />
        <Route path={url.EXERCISE_WORLDS} exact component={ExerciseWorlds} />
        <Route path={url.EXERCISE_WORLD} exact component={ExerciseWorld} />
        <Route path={url.EXERCISE_LANDING} exact component={ExerciseLanding} />
        <Route path={url.USER_PROFILE} exact component={UserProfile} />
        <Route path={url.USER_PREFERENCES} exact component={UserPreferences} />
        <Route
          path={url.USER_CHANGE_PASSWORD}
          exact
          component={UserChangePass}
        />
        <Route path={url.ANALYTICS_DASHBOARD} exact component={Dashboard} />
        <Route path={url.PRIVACY_HOME} exact component={PrivacyHome} />
        <Route path={url.BLOG_HOME} exact component={BlogHome} />
        <Route path={url.ROBOT_STORY} exact component={RobotStory} />
        <Route path={url.ROBOT_SELECT} exact component={RobotSelect} />
        <Route path={url.ROBOT_NAME} exact component={RobotName} />
      </App>
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);
