import axios from "axios";

import apiUrls from "../config/apiUrls";
import {
  USERPROFILE_FIND,
  USERPROFILE_ERROR,
  USERPROFILE_UPDATE,
  USERPROFILE_FULLSCREEN
} from "./types";

export const userProfileFind = (searchParams, callback) => async dispatch => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.USER_PROFILE_URL}/find`,
      searchParams,
      config
    );
    dispatch({ type: USERPROFILE_FIND, payload: response.data });
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({ type: USERPROFILE_ERROR, payload: {} });
    console.log("Error:", e);
  }
};

export const userProfileUpdate = (updateParams, callback) => async dispatch => {
  // given user_id, email, first_name, last_name, image_url, roles, updates that user's data
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.USER_PROFILE_URL}/update`,
      updateParams,
      config
    );
    dispatch({ type: USERPROFILE_UPDATE, payload: response.data });
    if (callback) callback();
  } catch (e) {
    dispatch({ type: USERPROFILE_ERROR, payload: {} });
    console.log("Error:", e);
  }
};

export const goFullScreen = fullScreen => async dispatch => {
  dispatch({ type: USERPROFILE_FULLSCREEN, payload: fullScreen });
};
