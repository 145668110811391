import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Filler from "./Filler"
import requireAuth from "../auth/requireAuth";
import { Icon } from 'semantic-ui-react'

class ProgressBar extends Component {

    progressPercent(exercise) {
        if (exercise.count_questions || exercise.count_correct || exercise.count_wrong) {
          return Math.floor((Number(exercise.count_correct) + Number(exercise.count_wrong)) / Number(exercise.count_questions) * 100);
        }
        return 0;
    }

    render() {
        var progress = this.progressPercent(this.props.exerciseData);
        var star1 = "grey"
        var star2 = "grey"
        var star3 = "grey"
        if (progress > 33.33 && progress < 66.66){
          star1 = "yellow"
        }
        else if (progress > 66.66 && progress < 99.99){
          star1 = "yellow"
          star2 = "yellow"
        }
        else if (progress > 99.99){
          star1 = "yellow"
          star2 = "yellow"
          star3 = "yellow"
        }
        if (_.isEmpty(this.props.exerciseData)) {
          return (
            <div>Loading...</div>
          );
        }

        return (
            <div className = "lighthouse">
              <img className="lighthouse-image" src={ require('../../assets/img/lighthouse.png') } />
              <div className = "stars">
                  <Icon name = 'star' color = {star1}/>
                  <Icon name = 'star' color = {star2}/>
                  <Icon name = 'star' color = {star3}/>
              </div>
              <div className="vertical-progress-bar">
                <Filler percentage = {progress}/>
              </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const active = state.exerciseActive;
    console.log(state);
    if (_.isEmpty(active.activeExercise)) 
      return { questionsToDo: [], exerciseData: {} };
  
    return { 
      exerciseData: active.activeExercise,
      activeIndex: active.activeIndex, 
    };
  }
  
  export default connect(mapStateToProps)(requireAuth(ProgressBar));