import React, { Component } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Button, Form, Checkbox } from "semantic-ui-react";
import _ from "lodash";

import { InputForm, SelectForm, TextAreaForm } from "../common/formComponents";
import requireAuth from "../auth/requireAuth";
import url from "../../utils/routeUrls";
import { skillCreate } from "../../actions";
import { skillLevels, skillTypes } from "./skillUtils";
import { skillCatFind, skillSubCatFind } from "../../utils/api";

const requiredFields = ["name", "description", "source", "skill_type", "level", "category", "sub_category"];

class SkillCreate extends Component {

  state = { 
    disableButton: false, 
    pendingCatResolve: false, 
    pendingSubCatResolve: false, 
    createAnother: false, 
    skillCreateText: "Add Skill",
    prevCat: null, prevLevel: null, prevSkillType: null,
    categories: [],
    subcategories: [] 
  };

  checkCatValid = () => {
    // ensures that the category exists for a given level and skill_type. If not, resets field to null
    const checkValid = _.filter(this.state.categories, cat => cat.category === this.props.category).length;
    if (checkValid === 0) {
      this.props.change("category", null);
    }
  }

  checkSubCatValid = () => {
    // ensures that subcategory exists for a given category. If not, resets field to null
    const checkValid = _.filter(this.state.subcategories, subCat => subCat.sub_category === this.props.sub_category).length;
    if (checkValid === 0) {
      this.props.change("sub_category", null);
    }
  }

  updateAllCategories = () => {
    // fetches category and subcategory and updates local state. 
    // Should fetch every time skill_type, level, or category changes in componentDidUpdate().

    // To prevent early submission of form (eg. when connection is slow) the flags pendingCatResolve and pendingSubCatResolve are used.
    // Before the request is sent, the pending states are set to true, and on complete, becomes false.
    // When the pending state is true, the submit button is disabled.

    const { skill_type, level, category } = this.props;
    const { prevLevel, prevSkillType, prevCat, pendingCatResolve, pendingSubCatResolve } = this.state;

    if ((skill_type || skill_type === 0) && (level || level === 0) && !pendingCatResolve && (prevLevel !== level || prevSkillType !== skill_type)) {
      
      this.setState({ pendingCatResolve: true }, 
      () => skillCatFind(
        { skill_type, level },
        () => this.setState({ categories: [], prevCat: category, prevLevel: level, prevSkillType: skill_type, pendingCatResolve: false }, () => this.checkCatValid()), 
        (res) => {
          this.setState({ categories: res.data, prevCat: category, prevLevel: level, prevSkillType: skill_type, pendingCatResolve: false }, () => this.checkCatValid());
          
        }
      ));
    }
    if ((skill_type || skill_type === 0) && (level || level === 0) && category && !pendingSubCatResolve && (prevLevel !== level || prevSkillType !== skill_type || prevCat !== category)) {
      
      this.setState({ pendingSubCatResolve: true },
      () => skillSubCatFind(
        { skill_type, level, category },
        () => this.setState({ subcategories: [], prevCat: category, prevLevel: level, prevSkillType: skill_type, pendingSubCatResolve: false }, () => this.checkSubCatValid()), 
        (res) => {
          this.setState({ subcategories: res.data, prevCat: category, prevLevel: level, prevSkillType: skill_type, pendingSubCatResolve: false }, () => this.checkSubCatValid());
          
        }
      ));
    } 
  }

  componentDidUpdate() {
    this.updateAllCategories();
  }

  generateCatOptions() {
    return this.state.categories.map(cat => ({ value: cat.category, text: cat.category_name }));
  }

  generateSubCatOptions() {
    // using the local state with all subcategory options, generates an array of objects to be used for the subcategory dropdown
    return this.state.subcategories.map(subcat => ({ value: subcat.sub_category, text: subcat.sub_category_name }));
  }

  onChange = () => {
    // when the slider is toggled, disables redirect when skill is created
    this.setState({ createAnother: !this.state.createAnother });
  }

  onSubmit = (values) => {
    if (!values.image_url) {
      values.image_url = "";
    }
    values.author = this.props.userId;
    console.log(values);
    this.setState({ disableButton: true }); // prevents multiple submissions of a new skill

    if (!this.state.createAnother) {
      this.props.skillCreate(values, () => this.props.history.push(url.SKILL_LIST));
    } else if (this.state.createAnother) {
      this.props.skillCreate(values, () => this.setState({ disableButton: false, skillCreateText: "Added. Add Another" }));
    }
  }

  render() {
    console.log(this.state);
    return (
      <div style={{ width: "80%", margin: "auto" }}>
        <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field 
            component={InputForm}
            type="text" 
            label="Skill Name" 
            name="name" 
            placeholder="eg. Addition"
          />
          <Field 
            component={TextAreaForm} 
            type="text" 
            label="Description" 
            name="description" 
            placeholder="eg. Taught in 3rd grade."
          />
          <Field 
            component={InputForm} 
            type="text" 
            label="Source" 
            name="source" 
            placeholder="eg. SmileStein"
          />
          <Field 
            component={SelectForm} 
            label="Skill Level" 
            name="level" 
            placeholder="Select level..."
            options={skillLevels}
          />
          <Field 
            component={SelectForm} 
            label="Skill Type" 
            name="skill_type" 
            placeholder="Select type..."
            options={skillTypes}
          />
          <Field 
            component={SelectForm} 
            label="Category (Must select type and level first)"
            name="category" 
            placeholder="Select Category..."
            disableComponent={this.state.categories.length === 0}
            options={this.generateCatOptions()}
          />
          <Field 
            component={SelectForm} 
            label="Subcategory (Must select category first)"
            name="sub_category" 
            placeholder="Select Subcategory..."
            disableComponent={this.state.subcategories.length === 0}
            options={this.generateSubCatOptions()}
          />
          <Field 
            component={InputForm} 
            type="text" 
            label="Image URL" 
            name="image_url" 
            placeholder="eg. image.png"
          />
          <div style={{ float: "right", margin: "5px 0em 10px 0.25em", clear: "both" }}>
            <Checkbox toggle label="Create Another Skill?" onChange={this.onChange} />
          </div>
          <div style={{ clear: "both" }}>
            <Button 
              loading={this.state.disableButton || this.state.pendingCatResolve || this.state.pendingSubCatResolve} 
              disabled={this.state.disableButton || this.state.pendingCatResolve || this.state.pendingSubCatResolve} 
              floated="right" 
              type="submit" 
              positive
            >
              {this.state.skillCreateText}
            </Button>
            <Button type="button" onClick={() => this.props.history.push(url.SKILL_LIST)}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  requiredFields.forEach(fieldname => {
    if (!values[fieldname] && values[fieldname] !== 0) {
      errors[fieldname] = "This field is required.";
    }
    return;
  });

  return errors;
}

function mapStateToProps(state) {
  const selector = formValueSelector("skillcreate");
  return { 
    userId: state.auth.userId,
    skill_type: selector(state, "skill_type"),
    level: selector(state, "level"),
    category: selector(state, "category"),
    sub_category: selector(state, "sub_category")
  };
}

export default compose(
  connect(mapStateToProps, { skillCreate }),
  reduxForm({ validate, form: "skillcreate" })
)(requireAuth(SkillCreate));