import _ from "lodash";

import {
  QUESTION_FIND,
  QUESTION_CREATE,
  QUESTION_ERROR,
  QUESTION_DELETE
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case QUESTION_FIND:
      return { ...action.payload };
    case QUESTION_CREATE:
      const key = Object.values(state).length;
      return { ...state, [key]: action.payload };
    case QUESTION_DELETE:
      const deletedQuestionIdArr = action.payload.map(question => {
        return question.question_id;
      });
      const newState = _.filter(state, question => {
        return !deletedQuestionIdArr.includes(question.question_id);
      });
      return { ...newState };
    case QUESTION_ERROR:
      return state;
    default:
      return state;
  }
};
