import {
  USERPROFILE_FIND,
  USERPROFILE_ERROR,
  USERPROFILE_UPDATE,
  USERPROFILE_CLEAR,
  USERPROFILE_FULLSCREEN
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case USERPROFILE_FIND:
      // { 0: { userobject }, 1: { userobject } }
      // if looking for user_id, always use key ["0"] on state to get relevant user
      return { ...action.payload };
    case USERPROFILE_UPDATE:
      return { ...action.payload };
    case USERPROFILE_CLEAR:
      return {};
    case USERPROFILE_ERROR:
      return state;
    case USERPROFILE_FULLSCREEN:
      return { ...state, fullScreen: !action.payload };
    default:
      return state;
  }
};
