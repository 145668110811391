import React from "react";
import PropTypes, { object } from "prop-types";
import { Dropdown, Form, Input, Label, Checkbox } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";

export const InputForm = ({ input, label, type, placeholder, icon, iconPosition, disableFluid, disableField, meta: { error, touched } }) => {
  return (
    <div>
      <label>{label}</label>
      <Input 
        {...input} type={type || "text"} 
        placeholder={ placeholder || "Enter..."} 
        style={{ marginBottom: '5px' }}
        fluid={disableFluid ? false : true}
        disabled={disableField ? true : false}
        icon={icon || null}
        iconPosition={iconPosition || null}
      />
      {touched && error ? <Label style={{ marginBottom: "5px" }} pointing>{error}</Label> : null}
    </div>
  );
};

export const TextAreaForm = ({ input, label, type, placeholder, meta: { error, touched } }) => {
  return (
    <div>
      <label>{label}</label>
      <Form.TextArea
        {...input} type={type || "text"} 
        placeholder={ placeholder || "Enter..."} 
        style={{ marginBottom: '5px' }}
      />
      {touched && error ? <Label pointing>{error}</Label> : null}
    </div>
  );
};

export const SelectForm = ({ input, label, placeholder, options, disableFluid, disableComponent, styleSelect, meta: { error, touched } }) => {
  // disableFluid, if provided and value is True, removes the fluid property from the dropdown.
  // styleSelect overrides the default styling for Dropdown.
  return (
    <div>
      <label>{label}</label>
      <Form.Field>
        <Dropdown 
          selection {...input}
          value={input.value}
          onChange={(param,data) => input.onChange(data.value)}
          placeholder={placeholder}
          options={options}
          style={styleSelect || { marginBottom: "5px" }}
          fluid={disableFluid ? false : true}
          disabled={disableComponent ? true : false}
        />
        {touched && error ? <Label pointing>{error}</Label> : null}
      </Form.Field>
    </div>
  );
}

// export const DatePickerForm = ({ input, disabled }) => {
//   return (
//     <Datepicker
//       {...input}
//       selected={input.value ? moment(input.value, 'YYYY-MM-DD') : null}
//       disabled={disabled || null}
//     />
//   );
// }

export class DatePickerForm extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.bool,
    }),
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    placeholder: ''
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (date) {
    this.props.input.onChange(moment(date).format('YYYY-MM-DD'))
  }

  render () {
    const {
      input, placeholder,
      // meta: {touched, error}
    } = this.props

    return (
      <DatePicker
        {...input}
        placeholder={placeholder}
        dateFormat="YYYY-MM-DD"
        selected={input.value ? moment(input.value, 'YYYY-MM-DD') : null}
        onChange={this.handleChange}
      />
    )
  }
}


// function SemanticReduxFormField ({ input, meta: { touched, error, warning }, as: As = Input, ...props }) {
//   function handleChange (e, { value }) {
//     return input.onChange(value)
//   }
//   return (
//     <div>
//       <As {...input} value={input.value} {...props} onChange={handleChange} error={touched && error} />
//       {touched && (warning && <span>{warning}</span>)}
//     </div>
//   )
// }

// SemanticReduxFormField.propTypes = {
//   as: PropTypes.any,
//   input: PropTypes.any,
//   label: PropTypes.any,
//   meta: PropTypes.any
// }

const CheckboxForm = ({ input: { value, onChange, ...input }, meta: { touched, error }, ...rest }) => (
  <div>
    <Checkbox {...input} {...rest} defaultChecked={!!value}
      onChange={(e, data) => onChange(data.checked)}
      type="checkbox"
    />
    {touched && error && <Label pointing>{error}</Label>}
  </div>
);

CheckboxForm.propTypes = {
  input: object.isRequired,
  meta: object.isRequired
};

CheckboxForm.defaultProps = {
  input: null,
  meta: null
};

// const RadioForm = ({ input: { value, onChange, ...input }, meta: { touched, error }, ...rest }) => (
//   <div>
//     <Form.Radio {...input} {...rest} defaultChecked={!!value}
//       onChange={(e, data) => onChange(data.checked)}
//       type="checkbox"
//     />
//     {touched && error && <Label pointing>{error}</Label>}
//   </div>
// );

// RadioForm.propTypes = {
//   input: object.isRequired,
//   meta: object.isRequired
// };

// RadioForm.defaultProps = {
//   input: null,
//   meta: null
// };

// export { CheckboxForm, RadioForm, SemanticReduxFormField };
export { CheckboxForm };

