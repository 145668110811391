import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { Button, Icon, Table, Menu, Dimmer } from "semantic-ui-react";

import url from "../../utils/routeUrls";
import { skillFind, skillDelete, questionFind } from "../../actions";
import { formatLevel, formatSkillType } from "./skillUtils";
import SkillListFilter from "./SkillListFilter";
import { CustomMessage } from "../common/messageComponents";
import requireAuth from "../auth/requireAuth";

import { Container } from "../../styles";

const headerNames = [
  "Name", 
  "Type", 
  "Level", 
  "Description",  
  "Source", 
  "Category",
  "Subcategory",
  "Created", 
  "Updated", 
  "", 
  "",
  ""
];

class SkillList extends Component {

  state = { active: false, skillIdToDelete: "" };

  componentDidMount() {
    // When mounting the component, loads page with given search params, 
    // then finds all subcategories and sets it to the local state as an array of subcat objects.
    // When generating the table, the state.subcategories array is filtered to find the matching subcat name to its parent category.
    const searchParams = {};
    if (this.props.name) searchParams.name = this.props.name;
    if (this.props.skill_type) searchParams.skill_type = this.props.skill_type;
    if (this.props.level) searchParams.level = this.props.level;
    if (this.props.category) searchParams.category = this.props.category;
    if (this.props.sub_category) searchParams.sub_category = this.props.sub_category;
    this.props.skillFind(searchParams);
  }

  confirmOverlay() {
    return (
      <Dimmer active={this.state.active} page inverted>
        <div style={{ margin: "auto", textAlign: "center" }}>
          <h1 style={{ color: "black", fontWeight: 150 }}>Are you sure you want to delete this skill?</h1>
          <Button.Group>
          <Button onClick={() => this.setState({ active: false })}>Cancel</Button>
          <Button.Or />
          <Button negative onClick={() => {
              this.props.skillDelete({ skill_id: this.state.skillIdToDelete });
              this.setState({ active: false });
            }}
          >
            Delete
          </Button>
          </Button.Group>
        </div>
      </Dimmer>
    );
  }

  formatDate(str) {
    // slices updatedAt and createdAt date strings to be year-month-day format
    if (!str) {
      return "N/A";
    }
    return str.slice(0, 10);
  }

  generateTableHeader() {
    return headerNames.map((name, index) => {
      return <Table.HeaderCell key={index}>{name}</Table.HeaderCell>
    });
  }

  deleteButton({ skill_id }) {
    // deletes a skill when clicked. Requires a skill object.
    return (
      <Button icon onClick={() => this.setState({ active: true, skillIdToDelete: skill_id })}>
        <Icon name="delete" />
      </Button>
    );
  }

  editButton({ skill_id }) {
    // sends user to skill edit form when clicked
    return (
      <Button icon onClick={() => {
          this.props.skillFind({ skill_id }, ()=>this.props.history.push(`${url.SKILL_EDIT}/${skill_id}`));
        }}
      >
        <Icon name="edit" />
      </Button>
    );
  }

  moreButton({ skill_id }) {
    // sends user to the specified skill's question list page when clicked
    return (
      <Button icon onClick={() => {
          // updates global state of skill id to properly render skill information on its questions list page
          this.props.skillFind({ skill_id });
          // fetches questions to render on the question list page
          this.props.questionFind({ skill_id }, () => this.props.history.push(`${url.QUESTION_LIST}/${skill_id}`));
        }}
      >
        <Icon name="ellipsis horizontal" />
      </Button>
    );
  }

  skillMessageWarning(str, obj) {
    // displays error message str given an object to check if empty or not
    if (_.isEmpty(obj)) {
      return (
        <CustomMessage 
          style={{ marginBottom: "5px" }} 
          message={str}
          warning
        />
      );
    }
    return null;
  }

  generateTable() {
    // using the skills fetched from the server, generates a table.
    // console.log(this.state);
    return _.map(this.props.skills, (skill) => {
      // skillSubCategoryName({ skill_type: skill.skill_type, level: skill.level, category: skill.category, sub_category: skill.sub_category });
      return (
        <Table.Row key={skill.skill_id} >
          <Table.Cell>{skill.name}</Table.Cell>
          <Table.Cell>{formatSkillType(skill.skill_type)}</Table.Cell>
          <Table.Cell>{formatLevel(skill.level)}</Table.Cell>
          <Table.Cell>{skill.description}</Table.Cell>
          <Table.Cell>{skill.source}</Table.Cell>
          <Table.Cell>{skill.category_name || "Uncategorized"}</Table.Cell>
          <Table.Cell>{skill.sub_category_name|| "Uncategorized"}</Table.Cell>
          <Table.Cell>{this.formatDate(skill.date_created)}</Table.Cell>
          <Table.Cell>{this.formatDate(skill.date_modified)}</Table.Cell>
          <Table.Cell>{this.deleteButton(skill)}</Table.Cell>
          <Table.Cell>{this.editButton(skill)}</Table.Cell>
          <Table.Cell>{this.moreButton(skill)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render () {
    // console.log(this.props.skills);
    return (
      <div>
        {this.confirmOverlay()}
        <Menu>
          <Menu.Item header>Skill Dashboard</Menu.Item>
          <Menu.Item position="right">
            <Button onClick={() => this.props.history.push(url.SKILL_CREATE)}>Create Skill</Button>
          </Menu.Item>
        </Menu>
        <div style={Container}>
          <SkillListFilter />
        </div>
        <Table striped style={Container}>
          <Table.Header>
            <Table.Row>
              {this.generateTableHeader()}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.generateTable()}
          </Table.Body>
        </Table>
        <div style={Container}>
          {this.skillMessageWarning("No skills found with current filters!", this.props.skills)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("skillListfilter");
  return { 
    skills: state.skill, 
    name: selector(state, "name"),
    skill_type: selector(state, "skill_type"), 
    level: selector(state, "level"),
    category: selector(state, "category"),
    sub_category: selector(state, "sub_category")
  };
}

export default connect(mapStateToProps, { 
  skillFind, 
  skillDelete,  
  questionFind 
})(requireAuth(SkillList));