import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import url from "../../utils/routeUrls";
import {
  goFullScreen,
  userProfileFind,
  gamificationSetStoryIndex
} from "../../actions";
import requireAuth from "../auth/requireAuth";
import Fullscreen from "react-full-screen";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class RobotStory extends Component {
  constructor(props) {
    super(props);
    this.backgroundRef = React.createRef();
  }

  state = { storyIndex: false };

  images = [
    "robotstory-frame robotstory-einstein-smile",
    "robotstory-frame robotstory-einstein-think",
    "robotstory-frame robotstory-einstein-idea",
    "robotstory-frame robotstory-einstein-work",
    "robotstory-frame robotstory-einstein-work",
    "robotstory-frame robotstory-einstein-smile"
  ];
  phrases = [
    "This is Einstein. He is a scientist.",
    "I want to travel to new places and see new things but I would like to have a companion to travel with.",
    "...Oh! I have an idea!",
    "Einstein begins to put different metal parts together in his lab. It looks like he is creating a machine!",
    "The machine is coming alive and Einstein says: 'Hello?'",
    "You're up and working! My name is Einstein. Let's go explore new places!"
  ];

  boxes = [
    "storyBox",
    "storyBubble",
    "storyBubble",
    "storyBox",
    "storyBubble",
    "storyBubble"
  ];

  componentDidMount() {}

  componentWillUnmount() {
    this.props.gamificationSetStoryIndex(0);
  }

  nextStory() {
    if (this.props.storyIndex < 5) {
      this.props.gamificationSetStoryIndex(this.props.storyIndex + 1);
    } else {
      this.props.history.push(url.ROBOT_SELECT);
    }
  }

  previousStory() {
    if (this.props.storyIndex > 0) {
      this.props.gamificationSetStoryIndex(this.props.storyIndex - 1);
    } else {
      // TBD hide the back button
    }
  }

  render() {
    const first =
      this.props.userProfile.first_name || this.props.userProfile.email;
    return (
      <Fullscreen enabled={this.props.fullScreen}>
        <div className="section-robotstory">
          <div className={this.images[this.props.storyIndex]}>
            <Row className="question-header">
              <Col className="question-preferences">
                <div> </div>
              </Col>
              <Col xs={8} className="question-category">
                Welcome to Math Labs
              </Col>
              <Col className="question-exit">
                <ul className="question-right-nav">
                  <li>
                    <div
                      onClick={() =>
                        this.props.goFullScreen(this.props.fullScreen)
                      }
                    >
                      <img
                        className="question-navigation-icons"
                        src={require("../../assets/img/full.png")}
                      />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className="storyNavigation">
              <Col className="storyPrev" onClick={() => this.previousStory()}>
                {this.props.storyIndex > 0 && (
                  <i className="ion-android-arrow-dropleft storyNavIcon" />
                )}
              </Col>
              <Col xs={10} className={this.boxes[this.props.storyIndex]}>
                <p>
                  <span>{this.phrases[this.props.storyIndex]}</span>
                </p>
              </Col>
              <Col className="storyNext" onClick={() => this.nextStory()}>
                <i className="ion-android-arrow-dropright storyNavIcon" />
              </Col>
            </Row>
          </div>
        </div>
      </Fullscreen>
    );
  }
}

function mapStateToProps(state) {
  const userData = state.userProfile["0"];
  let storyIndex = 0;
  if (
    state.gamification != undefined &&
    state.gamification.storyIndex != undefined
  ) {
    storyIndex = state.gamification.storyIndex;
  }
  console.log(
    "state index",
    state.gamification.storyIndex,
    "story index",
    storyIndex
  );
  return {
    userProfile: userData || {}, // no user logged in, userdata is empty object
    fullScreen: state.userProfile.fullScreen,
    userId: state.auth.userId,
    preferences: state.auth.preferences,
    authenticated: state.auth.authenticated,
    storyIndex: storyIndex
  };
}

export default connect(
  mapStateToProps,
  {
    goFullScreen,
    userProfileFind,
    gamificationSetStoryIndex
  }
)(requireAuth(RobotStory));
