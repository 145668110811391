import React, { Component } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Header,
  Icon,
  Button,
  Segment,
  Table,
  Form as SemForm
} from "semantic-ui-react";
import _ from "lodash";

import SemField from "../../helpers/SemField";
import { skillLevels, formatLevel } from "../../skill/skillUtils";
import url from "../../../utils/routeUrls";
import { addChild, userProfileDelete } from "../../../utils/api";

// create api support for adding children
class SignupChildren extends Component {
  state = {
    disableButton: false,
    maxChildrenAdded: this.props.getSubPlan().subscription_type,
    curChildrenAdded: 0,
    childrenId: 0,
    addedChildren: [] // should be an array of objects. Object should contain a key identifier when adding/removing
  };

  renderTitle() {
    return (
      <Header as="h2" color="teal" icon>
        <Icon name="user circle" />
        Add Children to Your Plan
      </Header>
    );
  }

  validateSchema = () =>
    Yup.object().shape({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      skill_level: Yup.string().required("Required")
    });

  renderError = props => {
    return <div style={{ color: "red" }}>{props.children}</div>;
  };

  addChildButton = (formProps, actions) => {
    // this.setState({ disableButton: true });

    // this.props.subCreate(formProps, () => {
    //   this.props.updateProgress(); // passed in as a prop
    // });

    formProps.id = this.state.childrenId;

    console.log(formProps);

    const childInfoProps = {
      parent_user_id: this.props.userId,
      first_name: formProps.first_name,
      last_name: formProps.last_name,
      preferences: { skill_type: 1, level: formProps.skill_level } // skill type hardcoded for now
    };

    addChild(childInfoProps, childUserId =>
      this.setState({
        curChildrenAdded: this.state.curChildrenAdded + 1,
        addedChildren: this.state.addedChildren.concat([
          { ...formProps, childUserId }
        ]),
        childrenId: this.state.childrenId + 1
      })
    );
    actions.resetForm({
      first_name: "",
      last_name: "",
      skill_level: ""
    });
    actions.setSubmitting(false);
  };

  deleteChildButton(childRemove) {
    return (
      <Button
        icon
        type="button"
        onClick={() => {
          const newChildArray = _.filter(this.state.addedChildren, child => {
            if (child.id === childRemove.id) {
              // console.log(child.childUserId);
              userProfileDelete(child.childUserId);
            }
            return child.id !== childRemove.id;
          });
          this.setState({
            addedChildren: newChildArray,
            curChildrenAdded: this.state.curChildrenAdded - 1
          });
        }}
      >
        <Icon name="delete" />
      </Button>
    );
  }

  generateChildrenTable() {
    let key = 0;
    return this.state.addedChildren.map(child => {
      return (
        <Table.Row key={key++}>
          <Table.Cell>{this.deleteChildButton(child)}</Table.Cell>
          <Table.Cell>{child.first_name + " " + child.last_name}</Table.Cell>
          <Table.Cell>{formatLevel(child.skill_level)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  renderForm = ({ isSubmitting }) => {
    return (
      <div
        style={{
          minWidth: "450px",
          maxWidth: "40%",
          margin: "auto",
          padding: "70px 0"
        }}
      >
        <Segment stacked padded="very">
          <div style={{ textAlign: "center" }}>{this.renderTitle()}</div>
          <Form>
            <label>Child First Name</label>
            <SemField
              name="first_name"
              type="text"
              component={SemForm.Input}
              disabled={
                this.state.maxChildrenAdded === this.state.curChildrenAdded
              }
              icon="child"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="first_name" component={this.renderError} />
            <label>Child Last Name</label>
            <SemField
              name="last_name"
              type="text"
              component={SemForm.Input}
              disabled={
                this.state.maxChildrenAdded === this.state.curChildrenAdded
              }
              icon="child"
              iconPosition="left"
              fluid
            />
            <ErrorMessage name="last_name" component={this.renderError} />
            <SemField
              component={SemForm.Select}
              name="skill_level"
              disabled={
                this.state.maxChildrenAdded === this.state.curChildrenAdded
              }
              placeholder="Select Child Skill Level"
              options={skillLevels}
              fluid
            />
            <ErrorMessage name="skill_level" component={this.renderError} />

            <Button
              style={{ marginTop: "20px" }}
              disabled={
                this.state.maxChildrenAdded === this.state.curChildrenAdded
              }
              className="teal"
              fluid
              size="large"
            >
              Add Child
            </Button>
          </Form>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{""}</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Grade</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.generateChildrenTable()}</Table.Body>
          </Table>
          <Button
            style={{ marginTop: "20px" }}
            loading={this.state.disableButton}
            disabled={isSubmitting || this.state.disableButton}
            className="teal"
            fluid
            size="large"
            onClick={() => this.props.history.push(url.EXERCISE_LANDING)}
          >
            Save And Complete
          </Button>
        </Segment>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          validationSchema={this.validateSchema()}
          validate={this.validateForm}
          initialValues={{
            first_name: "",
            last_name: "",
            skill_level: ""
          }}
          onSubmit={this.addChildButton}
          render={this.renderForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { userId: state.auth.userId };
}

export default connect(mapStateToProps)(withRouter(SignupChildren));
