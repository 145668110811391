import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Button, Icon, Table, Menu, Dimmer } from "semantic-ui-react";

import url from "../../utils/routeUrls";
import { skillFind, questionFind, questionDelete } from "../../actions";
import requireAuth from "../auth/requireAuth";
import { formatLevel } from "../skill/skillUtils";
import { formatQuestionType } from "./questionUtils";


const headerNames = [
  "Type", 
  "Text",
  "Source",
  "Created",
  "Updated",
  "Del",
  "Edit"
];

class QuestionList extends Component {

  state = { active: false, questionIdToDelete: "" };

  componentWillMount() {
    
    const urlSkillId = this.props.match.params.skillId;
    if (!urlSkillId) {
      // If skillId is not provided in the URL, redirect user back to skill list.
      this.props.history.push(url.SKILL_LIST);
    } else {
      // if it is provided, fetch the skill based on skillId, and find all questions related to it.
      this.props.skillFind(
        { skill_id: urlSkillId }, 
        null, 
        () => this.props.history.push(url.SKILL_LIST), // error callback for invalid syntax
        (response) => response.length === 0 ? this.props.history.push(url.SKILL_LIST) : null // skill doesn't exist
      );
      this.props.questionFind({ skill_id: urlSkillId });
    }
    
  }

  confirmOverlay() {
    return (
      <Dimmer active={this.state.active} page inverted>
        <div style={{ margin: "auto", textAlign: "center" }}>
          <h1 style={{ color: "black", fontWeight: 150 }}>Are you sure you want to delete this question?</h1>
          <Button.Group>
          <Button onClick={() => this.setState({ active: false })}>Cancel</Button>
          <Button.Or />
          <Button negative onClick={() => {
              this.props.questionDelete({ question_id: this.state.questionIdToDelete });
              this.setState({ active: false });
            }}
          >
            Delete
          </Button>
          </Button.Group>
        </div>
      </Dimmer>
    );
  }

  formatDate(str) {
    // slices updatedAt and createdAt date strings to be year-month-day format
    if (!str) {
      return "N/A";
    }
    return str.slice(0, 10);
  }

  generateTableHeader() {
    return headerNames.map(name => {
      return <Table.HeaderCell key={name}>{name}</Table.HeaderCell>
    });
  }

  deleteButton({ question_id }) {
    return (
      <Button icon onClick={() => this.setState({ active: true, questionIdToDelete: question_id })}>
        <Icon name="delete" />
      </Button>
    );
  }

  editButton({ question_id }) {
    return (
      <Button icon onClick={() => {
          this.props.questionFind(
            { question_id }, 
            () => this.props.history.push(
              `${url.QUESTION_EDIT}/${this.props.match.params.skillId}/${question_id}`
            ));
        }}
      >
        <Icon name="edit" />
      </Button>
    );
  }

  generateTable() {
    return _.map(this.props.question, (question) => {
      return (
        <Table.Row key={question.question_id} >
          <Table.Cell>{formatQuestionType(question.question_type)}</Table.Cell>
          <Table.Cell>{question.text}</Table.Cell>
          <Table.Cell>{question.source}</Table.Cell>
          <Table.Cell>{this.formatDate(question.date_created)}</Table.Cell>
          <Table.Cell>{this.formatDate(question.date_modified)}</Table.Cell>
          <Table.Cell>{this.deleteButton(question)}</Table.Cell>
          <Table.Cell>{this.editButton(question)}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    const selectedSkill = this.props.skill["0"];
    const skillName = selectedSkill ? selectedSkill.name : "";
    const skillLvl = selectedSkill ? formatLevel(selectedSkill.level) : "";
    return (
      <div>
        {this.confirmOverlay()}
        <Menu stackable>
          <Menu.Item>
            <Button onClick={() => this.props.history.push(url.SKILL_LIST)}>Back to Skills Dashboard</Button>
          </Menu.Item>
          <Menu.Item header>Questions for {skillName}</Menu.Item>
          <Menu.Item>ID: {this.props.match.params.skillId}</Menu.Item>
          <Menu.Item>Level: {skillLvl}</Menu.Item>
          <Menu.Item position="right">
            <Button color="teal" onClick={() => this.props.history.push(`${url.QUESTION_CREATE}/${this.props.match.params.skillId}`) }>
              Create Question
            </Button>
          </Menu.Item>
        </Menu>
        <Table striped style={{ width: "80%", margin: "auto" }}>
          <Table.Header>
            <Table.Row>
              {this.generateTableHeader()}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.generateTable()}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    question: state.question, 
    skill: state.skill
   };
}

export default connect(mapStateToProps, { 
  skillFind, 
  questionFind, 
  questionDelete
})(requireAuth(QuestionList));