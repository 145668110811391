import React, { Component } from "react";
import PrivacyHeader from "./PrivacyHeader";
import PrivacyList from "./PrivacyList";


export default class PrivacyHome extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  
  render() {
    return (
      <div>
        <PrivacyHeader />
        <PrivacyList />
      </div>
    );
  }
}