import axios from "axios";

import apiUrls from "../config/apiUrls";
import {
  EXERCISE_FIND,
  EXERCISE_ERROR,
  EXERCISE_ACTIVE_FIND,
  EXERCISE_RESET
} from "./types";

export const exerciseFind = (
  searchProps,
  findSkills,
  callback,
  callbackError,
  callbackRes
) => async dispatch => {
  // with an object searchProps containing the required params for the search, sends all
  // the exercises found as a payload. payload is an array of objects.
  // findSkills param is a boolean. If true, will find exercises AND skills, not just exercises.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };

    let response;

    if (findSkills)
      response = await axios.post(
        `${apiUrls.EXERCISE_URL}/find-exercises-or-skills`,
        searchProps,
        config
      );
    else
      response = await axios.post(
        `${apiUrls.EXERCISE_URL}/find`,
        searchProps,
        config
      );
    dispatch({ type: EXERCISE_FIND, payload: response.data });
    if (callback) {
      // optional callback for redirects
      callback();
    }
    if (callbackRes) {
      // initiates a callback depending on the response from the API
      callbackRes(response.data);
    }
  } catch (e) {
    dispatch({ type: EXERCISE_ERROR, payload: {} });
    console.log("Error:", e);
    if (callbackError) {
      callbackError();
    }
  }
};

export const exerciseCreate = (
  { skill_id, user_id },
  callback
) => async dispatch => {
  // given an object with a user_id and skill_id, creates a new exercise.
  // payload is an exercise object with a skill_id, user_id, exercise_id, and array of question objects
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const createResponse = await axios.post(
      `${apiUrls.EXERCISE_URL}/create`,
      { skill_id, user_id },
      config
    );
    console.log({ exercise_id: createResponse.data.exercise_id });
    const response = await axios.post(
      `${apiUrls.EXERCISE_URL}/retrieve-exercise-details`,
      { exercise_id: createResponse.data.exercise_id },
      config
    );
    // dispatch({ type: EXERCISE_CREATE, payload: response.data });
    dispatch({ type: EXERCISE_ACTIVE_FIND, payload: response.data["0"] });
    if (callback) {
      // optional callback for redirects
      callback();
    }
  } catch (e) {
    dispatch({ type: EXERCISE_ERROR, payload: {} });
    console.log("Error:", e);
  }
};

export const exerciseReset = () => {
  // resets the exercise state to its initial state. Used for componentWillUnmount lifecycle, primarily.
  return { type: EXERCISE_RESET, payload: {} };
};
