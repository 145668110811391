import axios from "axios";

import apiUrls from "../config/apiUrls";

export const answerCreate = async (answerProps, callback, callbackParam) => {
  // given an answerProps object containing
  // question_id, exercise_id, and selected_options (an array of strings)
  // response returns an object with the same props as above, including is_correct (bool), answer_id, and date_created
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.ANSWER_URL}/create`,
      answerProps,
      config
    );

    if (callback && callbackParam) callback(response.data, callbackParam);
    else if (callback && !callbackParam) callback(response.data);
  } catch (e) {
    console.log("Error:", e);
  }
};

export const skillSubCatFind = async (subProps, callbackErr, callbackRes) => {
  // { skill_type: int, level: int, category: int, sub_category: int }
  // given an object with these attributes (vals are ints), gets the relevant subcategories.
  // or, pass in an empty object {} to find EVERY subcategory.
  // returns an array of objects.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.SKILL_URL}/find-sub-categories`,
      subProps,
      config
    );
    console.log(response);

    if (callbackRes) {
      callbackRes(response);
    }

    return response.data;
  } catch (e) {
    console.log("Error:", e);
    if (callbackErr) callbackErr();
  }
};

export const skillCatFind = async (catProps, callbackErr, callbackRes) => {
  // { skill_type: int, level: int }
  // given an object with these attributes (vals are ints), gets the relevant categories.
  // or, pass in an empty object {} to find EVERY category.
  // returns an array of objects.
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.SKILL_URL}/find-categories`,
      catProps,
      config
    );
    console.log(response);

    if (callbackRes) {
      callbackRes(response);
    }

    return response.data;
  } catch (e) {
    console.log("Error:", e);
    if (callbackErr) callbackErr();
  }
};

export const addChild = async (formProps, callback) => {
  /* 
  Adds a child under the parent user id.
  { "parent_user_id": user_id, first_name, last_name, email, password, image_url, "preferences": {"skill_type":1, "level":2}}
  */

  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.AUTH_URL}/add-child`,
      formProps,
      config
    );
    console.log(response);

    if (callback) callback(response.data.user_id);
  } catch (e) {
    console.log("Error:", e);
  }
};

export const userProfileDelete = async (deleteId, callback) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    const response = await axios.post(
      `${apiUrls.USER_PROFILE_URL}/delete`,
      { user_id: deleteId },
      config
    );
    console.log(response);

    if (callback) callback();
  } catch (e) {
    console.log("Error:", e);
  }
};

export const sendLead = async (formProps, callback) => {
  try {
    const config = {
      headers: { Authorization: localStorage.getItem("token") }
    };
    await axios.post(
      `${apiUrls.LEADS_URL}/leads/create`,
      { ...formProps, opt_out: !formProps.news },
      config
    );

    if (callback) callback();
  } catch (e) {
    console.log("Error:", e);
  }
};
